import Vue, { provide } from 'vue';
import PortalVue from 'portal-vue';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import PrimeVue from 'primevue/config';

import onClickOutside from '@/directives/click-outside';
import ApiPlugin from '@/plugins/apiPlugin';
import Utils from '@/plugins/utils';

import initApi from '@/api/index';
import initTsApi from '@/module/common/tsApi/tsApi';
import initLocalisationRegistry from '@/module/common/utils/localisation/initLocalisationRegistry';
import IVueInstance from '@/module/common/models/IVueInstance';
import initLocalisation from '@/i18n/index';
import Vuex from 'vuex';
import serviceContainerPlugin, { getContainerInstance } from '@/plugins/serviceContainer';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import MerchantModeService from '@/module/common/service/MerchantMode/MerchantModeService';
import PortalModeService from '@/module/common/service/PortalMode/PortalModeService';
import TTSApi from '@/module/common/tsApi/TTSApi';
import UtilsService from '@/module/common/service/UtilsService/UtilsService';
import NotificationService from '@/module/common/service/NotificationService/NotificationService';
import ProductModule from '@/module/product/service/ProductModule';
import NumberFormatService from '@/module/common/service/NumberFormatService/NumberFormatService';
import FulfillmentTestRequestApi from '@/module/common/service/API/FulfillmentTestRequestApi';
import LocalStorageService from '@/module/common/service/LocalStorage/LocalStorageService';
import AuthorizeService from '@/module/common/service/AuthorizeService/AuthorizeService';
import AdminAuthorizeService from '@/module/common/service/AuthorizeService/AdminAuthorizeService';
import App from './App.vue';
import initRouter from './router/index';
import initStore from './store';
import './main.scss';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

Vue.config.productionTip = false;
Vue.use(PrimeVue);
Vue.use(PerfectScrollbar);
Vue.use(PortalVue);
Vue.directive('click-outside', onClickOutside);

const serviceContainer = getContainerInstance();

const merchantModeService = new MerchantModeService(serviceContainer);
const portalModeService = new PortalModeService(serviceContainer);
const productModule = new ProductModule(serviceContainer);

serviceContainer.register(EServiceContainerInstances.PORTAL_MODE_SERVICE, portalModeService);
serviceContainer.register(EServiceContainerInstances.MERCHANT_MODE_SERVICE, merchantModeService);
serviceContainer.register(EServiceContainerInstances.MODULE_PRODUCT, productModule);

Vue.use(serviceContainerPlugin, { serviceContainer });

const localStorageService = new LocalStorageService(serviceContainer);
serviceContainer.register(EServiceContainerInstances.LOCAL_STORAGE_SERVICE, localStorageService);

const authService = new AuthorizeService(serviceContainer);
serviceContainer.register(EServiceContainerInstances.AUTHORIZE_SERVICE, authService);

const adminAuthService = new AdminAuthorizeService(serviceContainer);
serviceContainer.register(EServiceContainerInstances.ADMIN_AUTHORIZE_SERVICE, adminAuthService);

const store = initStore(Vue, Vuex, serviceContainer);
serviceContainer.register(EServiceContainerInstances.STORE, store);

const numberFormatService = new NumberFormatService(serviceContainer);
serviceContainer.register(EServiceContainerInstances.NUMBER_FORMAT_SERVICE, numberFormatService);

const router = initRouter(Vue, serviceContainer);
serviceContainer.register(EServiceContainerInstances.ROUTER, router);

const api = initApi(serviceContainer.axiosClientService.instance);

serviceContainer.register(EServiceContainerInstances.API, api);

Vue.use(ApiPlugin, { api });

const tsApi = initTsApi(serviceContainer.axiosClientService.instance);
serviceContainer.register(EServiceContainerInstances.TS_API, tsApi);

const utilsService = new UtilsService(serviceContainer);
serviceContainer.register(EServiceContainerInstances.UTILS_SERVICE, utilsService);

const notificationService = new NotificationService(serviceContainer);
serviceContainer.register(EServiceContainerInstances.NOTIFICATION_SERVICE, notificationService);

const fulfillmentTestRequestApi = new FulfillmentTestRequestApi(
  serviceContainer.axiosClientService.instance,
);
serviceContainer.register(EServiceContainerInstances.TEMP_FULFILLMENT_TEST_REQUEST_API, fulfillmentTestRequestApi);

initLocalisation(Vue, serviceContainer).then((i18n) => {
  const options = {
    router,
    store,
    i18n,
    created() {
      serviceContainer.register<IVueInstance>(EServiceContainerInstances.VUE_INSTANCE, this as unknown as IVueInstance);

      Vue.use(Utils, this);
      provide('api', api);
      provide<TTSApi>('tsApi', tsApi);
      provide<IServiceContainer>('serviceContainer', serviceContainer);

      provide<IVueInstance>('vueInstance', this as unknown as IVueInstance);

      initLocalisationRegistry(serviceContainer, store);
      serviceContainer.register(EServiceContainerInstances.TRANSLATE, (this as unknown as IVueInstance).$t);
      serviceContainer.register(EServiceContainerInstances.I18N, (this as unknown as IVueInstance).$i18n);
      serviceContainer.register(EServiceContainerInstances.UTILS, (this as unknown as IVueInstance).$utils);
    },
    render: (h) => h(App),
  };

  (new Vue(options))
    .$mount('#app');
});

import ICamelCaseLocalisationAccountActivatePage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationAccountActivatePage';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import { EConditionType } from '@/module/common/helpers/checkCondition';
import buildFieldName from '@/module/common/components/FormComponent/buildFieldName';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ICamelCaseLocalisationCommon
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationCommon';
import ICurrency from '@/module/merchant/models/ICurrency';
import ECurrency from '@/module/merchant/models/ECurrency';
import IAccountActivatePageValidateHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/IValidatorHandler';
import EPageTab from '@/module/merchant/view/AccountActivatePage/models/EPageTab';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';
import ETaxCalculation from '@/module/merchant/view/AccountActivatePage/models/ETaxCalculation';
import EIndividualKey from '@/module/merchant/view/AccountActivatePage/models/EIndividualKey';
import TTypeOFBusiness from '@/module/merchant/view/AccountActivatePage/models/TTypeOFBusiness';
import TTaxCalculationList from '@/module/merchant/view/AccountActivatePage/models/TTaxCalculationList';
import TIndividual from '@/module/merchant/view/AccountActivatePage/models/TIndividual';
import IFormConfigHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormConfigHandler';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import ETypeOfBusiness from '@/module/merchant/models/BuisnessType/ETypeOfBusiness';
import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import ITab from '@/module/merchant/view/AccountActivatePage/models/ITab';
import TFieldStatuses
  from '@/module/merchant/view/AccountActivatePage/models/TFieldStatuses';
import IBusinessType from '@/module/merchant/models/BuisnessType/IBusinessType';
import EIndividual from '@/module/merchant/models/BuisnessType/EIndividual';
import IFormConfig from '@/module/merchant/view/AccountActivatePage/models/IFormConfig';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';

class FormConfigHandler implements IFormConfigHandler {
  private readonly state: IPageState;

  private readonly localisationRegistry: ILocalisationRegistry;

  private readonly validateHandler: IAccountActivatePageValidateHandler;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    state: IPageState,
    localisationRegistry: ILocalisationRegistry,
    validateHandler: IAccountActivatePageValidateHandler,
    serviceContainer: IServiceContainer,
  ) {
    this.state = state;
    this.localisationRegistry = localisationRegistry;
    this.validateHandler = validateHandler;
    this.serviceContainer = serviceContainer;
  }

  getTabListConfig = (): IFormConfig[] => {
    const businessInfo = this.localisationRegistry.accountActivate.businessInfo;
    const maxDateOfBirthDate = this.getMaxDateOfBirth();
    const businessTypes = this.getBusinessTypes(this.localisationRegistry.accountActivate);
    const currencyButtonList = this.getCurrencies(this.localisationRegistry.accountActivate);
    const taxCalculationList = this.getTaxCalculationList(this.localisationRegistry.accountActivate);

    const formMap: IFormConfig[] = [
      {
        id: EPageTab.BUSINESS_INFO,
        title: businessInfo.tab,
        formConfig: [
          {
            header: businessInfo.businessStructure.title,
            subHeader: businessInfo.businessStructure.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.FILTERED_SELECT,
                select: {
                  inputDelay: 0,
                  symbolsForSearch: 1,
                  idField: 'code',
                  titleField: 'name',
                  autoSelectSingleValue: true,
                  list: this.filterCountry(
                    EFieldBlock.BUSINESS_STRUCTURE,
                    EFieldName.COUNTRY,
                  ),
                },
                default: {},
                fieldName: EFieldName.COUNTRY,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.country.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.country.placeholder,
                description: {
                  default: this.getBusinessStructureCountryDescription(
                    this.localisationRegistry.accountActivate,
                  ),
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.COUNTRY,
                ),
              },
              {
                id: 3,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.ADDRESS_LINE_1,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.addressLine1.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.addressLine1.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.ADDRESS_LINE_1,
                ),
              },
              {
                id: 4,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.ADDRESS_LINE_2,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: this.initOptionalFieldText(
                  businessInfo.businessStructure.addressLine2.title,
                  this.localisationRegistry.common,
                ),
                summary: {
                  show: true,
                  label: businessInfo.businessStructure.addressLine2.title,
                },
                placeholder: businessInfo.businessStructure.addressLine2.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.ADDRESS_LINE_2,
                ),
              },
              {
                id: 5,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.TOWN_OR_CITY,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.townOrCity.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.townOrCity.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.TOWN_OR_CITY,
                ),
              },
              {
                id: 6,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.POSTAL_CODE,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.postalCode.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.postalCode.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.POSTAL_CODE,
                ),
              },
              {
                id: 7,
                type: EFormFieldType.BUTTON_GROUP,
                fieldName: EFieldName.BUSINESS_TYPE,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.typeOfBusiness.noventiq.title,
                summary: {
                  show: true,
                },
                description: {
                  default: businessInfo.businessStructure.typeOfBusiness.noventiq.description,
                },
                variant: 'spaced',
                buttonList: businessTypes.filter((businessType) => [
                  EBusinessType.COMPANY,
                  EBusinessType.INDIVIDUAL,
                ].includes(businessType.id)),
                default: businessTypes.find(
                  (businessType) => businessType.typeOfBusiness.value === ETypeOfBusiness.COMPANY,
                ),
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.BUSINESS_TYPE,
                ),
              },
            ],
          },
          {
            header: businessInfo.businessRepresentative.title,
            subHeader: businessInfo.businessRepresentative.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.FIRST_NAME,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.firstName.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.firstName.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.FIRST_NAME,
                ),
              },
              {
                id: 2,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.LAST_NAME,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.lastName.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.lastName.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.LAST_NAME,
                ),
              },
              {
                id: 3,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.IDENTIFICATION_NUMBER,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: this.initOptionalFieldText(
                  businessInfo.businessRepresentative.identificationNumber.title,
                  this.localisationRegistry.common,
                ),
                summary: {
                  show: true,
                  label: businessInfo.businessRepresentative.identificationNumber.title,
                },
                conditionsList: [
                  {
                    type: EConditionType.NOT_EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.COMPANY,
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.IDENTIFICATION_NUMBER,
                ),
              },
              {
                id: 4,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.JOB_TITLE,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.jobTitle.title,
                summary: {
                  show: true,
                },
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.COMPANY,
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.JOB_TITLE,
                ),
              },
              {
                id: 5,
                type: EFormFieldType.INPUT,
                inputType: 'email',
                fieldName: EFieldName.EMAIL,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.email.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.email.placeholder.noventiq,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.EMAIL,
                ),
              },
              {
                id: 6,
                type: EFormFieldType.DATE,
                fieldName: EFieldName.DATE_OF_BIRTH,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                maxDate: maxDateOfBirthDate,
                default: maxDateOfBirthDate,
                label: businessInfo.businessRepresentative.dateOfBirth.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.dateOfBirth.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.DATE_OF_BIRTH,
                ),
              },
              {
                id: 7,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '+000000000000',
                },
                inputType: 'phone',
                fieldName: EFieldName.PHONE_NUMBER,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.phoneNumber.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.phoneNumber.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.PHONE_NUMBER,
                ),
              },
            ],
          },
          {
            header: businessInfo.businessDetails.title,
            subHeader: businessInfo.businessDetails.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.BUTTON_GROUP,
                fieldName: EFieldName.INDIVIDUAL,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.individual.title,
                summary: {
                  show: true,
                },
                variant: 'spaced',
                buttonList: businessTypes.filter(
                  (businessType) => businessType.typeOfBusiness.value === ETypeOfBusiness.INDIVIDUAL,
                ),
                default: businessTypes.find(
                  (businessType) => businessType.id === EBusinessType.INDIVIDUAL,
                ),
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'typeOfBusiness.value',
                    ),
                    value: ETypeOfBusiness.INDIVIDUAL,
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.INDIVIDUAL,
                ),
              },
              {
                id: 2,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.LEGAL_BUSINESS_NAME,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.legalBusinessName.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.legalBusinessName.placeholder,
                description: {
                  default: businessInfo.businessDetails.legalBusinessName.description,
                },
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'typeOfBusiness.value',
                    ),
                    value: ETypeOfBusiness.COMPANY,
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.LEGAL_BUSINESS_NAME,
                ),
              },
              {
                id: 3,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.TAX_IDENTIFICATION_CODE,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.taxIdentificationCode.title,
                summary: {
                  show: true,
                },
                description: {
                  default: businessInfo.businessDetails.taxIdentificationCode.description,
                },
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'typeOfBusiness.value',
                    ),
                    value: ETypeOfBusiness.COMPANY,
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.TAX_IDENTIFICATION_CODE,
                ),
              },
              {
                id: 4,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.DOING_BUSINESS_AS,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.doingBusinessAs.title,
                summary: {
                  show: true,
                },
                description: {
                  default: businessInfo.businessDetails.doingBusinessAs.description,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.DOING_BUSINESS_AS,
                ),
              },
              {
                id: 5,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.BUSINESS_WEBSITE,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.businessWebsite.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.businessWebsite.placeholder,
                description: {
                  default: businessInfo.businessDetails.businessWebsite.description,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.BUSINESS_WEBSITE,
                ),
              },
              {
                id: 6,
                type: EFormFieldType.TEXT_AREA,
                fieldName: EFieldName.PRODUCT_DESCRIPTION,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: this.initOptionalFieldText(
                  businessInfo.businessDetails.productDescription.title,
                  this.localisationRegistry.common,
                ),
                summary: {
                  show: true,
                  label: businessInfo.businessDetails.productDescription.title,
                },
                description: {
                  default: businessInfo.businessDetails.productDescription.description,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.PRODUCT_DESCRIPTION,
                ),
              },
            ],
          },
        ],
      },
      {
        id: EPageTab.SUPPORT_DETAILS,
        title: this.localisationRegistry.accountActivate.support.tab,
        formConfig: [
          {
            header: this.localisationRegistry.accountActivate.support.title,
            subHeader: this.localisationRegistry.accountActivate.support.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '+000000000000',
                },
                inputType: 'phone',
                fieldName: EFieldName.PHONE_NUMBER,
                fieldBlock: EFieldBlock.SUPPORT_DETAILS,
                label: this.initOptionalFieldText(
                  this.localisationRegistry.accountActivate.support.phone.title,
                  this.localisationRegistry.common,
                ),
                summary: {
                  show: true,
                  label: this.localisationRegistry.accountActivate.support.phone.title,
                },
                placeholder: this.localisationRegistry.accountActivate.support.phone.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.SUPPORT_DETAILS,
                  EFieldName.PHONE_NUMBER,
                ),
              },
              {
                id: 2,
                type: EFormFieldType.INPUT,
                inputType: 'email',
                fieldName: EFieldName.EMAIL,
                fieldBlock: EFieldBlock.SUPPORT_DETAILS,
                label: this.localisationRegistry.accountActivate.support.email.title,
                summary: {
                  show: true,
                },
                placeholder: this.localisationRegistry.accountActivate.support.email.placeholder.noventiq,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.SUPPORT_DETAILS,
                  EFieldName.EMAIL,
                ),
              },
            ],
          },
        ],
      },
      {
        id: EPageTab.ACCOUNT_FOR_PAYOUTS,
        title: this.localisationRegistry.accountActivate.bank.tab,
        formConfig: [
          {
            header: this.localisationRegistry.accountActivate.bank.title,
            subHeader: this.localisationRegistry.accountActivate.bank.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.BUTTON_GROUP,
                fieldName: EFieldName.CURRENCY,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.currency.title,
                summary: {
                  show: true,
                },
                variant: 'spaced',
                description: {
                  default: this.localisationRegistry.accountActivate.bank.currency.description.noventiq,
                },
                buttonList: currencyButtonList,
                default: currencyButtonList[0],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.CURRENCY,
                ),
              },
              {
                id: 2,
                type: EFormFieldType.FILTERED_SELECT,
                select: {
                  inputDelay: 0,
                  symbolsForSearch: 1,
                  idField: 'code',
                  titleField: 'name',
                  list: this.filterCountry(
                    EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                    EFieldName.COUNTRY,
                  ),
                },
                default: {},
                fieldName: EFieldName.COUNTRY,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.country.title,
                summary: {
                  show: true,
                },
                placeholder: this.localisationRegistry.accountActivate.bank.country.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.COUNTRY,
                ),
              },
              {
                id: 3,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.BANK_NAME,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.bankName.title,
                summary: {
                  show: true,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.BANK_NAME,
                ),
              },
              {
                id: 4,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.IBAN,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.iban.title,
                summary: {
                  show: true,
                },
                placeholder: this.localisationRegistry.accountActivate.bank.iban.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.IBAN,
                ),
              },
              {
                id: 5,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.IBAN_CONFIRM,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.ibanConfirm.title,
                summary: {
                  show: true,
                },
                placeholder: this.localisationRegistry.accountActivate.bank.ibanConfirm.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.IBAN_CONFIRM,
                ),
              },
              {
                id: 6,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.VAT_NUMBER,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.vatNumber.title,
                summary: {
                  show: true,
                },
                placeholder: this.localisationRegistry.accountActivate.bank.vatNumber.placeholder,
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.COMPANY,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.INDIVIDUAL,
                          EFieldBlock.BUSINESS_DETAILS,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.VAT_NUMBER,
                ),
              },
              {
                id: 7,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.SWIFT,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.initOptionalFieldText(
                  this.localisationRegistry.accountActivate.bank.swift.title,
                  this.localisationRegistry.common,
                ),
                summary: {
                  show: true,
                  label: this.localisationRegistry.accountActivate.bank.swift.title,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.SWIFT,
                ),
              },
            ],
          },
        ],
      },
      {
        id: EPageTab.TAX_CALCULATION,
        title: this.localisationRegistry.accountActivate.taxCalculation.tab,
        formConfig: [
          {
            header: this.localisationRegistry.accountActivate.taxCalculation.title,
            items: [
              {
                id: 1,
                type: EFormFieldType.BUTTON_GROUP,
                fieldName: EFieldName.TAX_CALCULATION,
                fieldBlock: EFieldBlock.TAX_CALCULATION,
                label: this.localisationRegistry.accountActivate.taxCalculation.taxCalculation.title,
                summary: {
                  show: true,
                },
                description: {
                  default: this
                    .localisationRegistry.accountActivate.taxCalculation.taxCalculation.description.noventiq,
                },
                buttonList: Object.values(taxCalculationList),
                default: taxCalculationList.inclusive,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.TAX_CALCULATION,
                  EFieldName.TAX_CALCULATION,
                ),
              },
            ],
          },
        ],
      },
      {
        id: EPageTab.SUMMARY,
        title: this.localisationRegistry.accountActivate.summary.tab,
        formConfig: [],
      },
    ];

    const validators = this.validateHandler.getValidators();

    formMap.forEach((tab) => {
      tab.formConfig.forEach((formConfig) => {
        formConfig.items.forEach((item) => {
          if (validators[item.fieldBlock]?.[item.fieldName]) {
            item.validator = validators[item.fieldBlock][item.fieldName];
          }
        });
      });
    });

    return formMap;
  };

  getFormConfig = (tab: ITab): IFormConfig => this.getTabListConfig()
    .find((tabConfig) => tab.id === tabConfig.id) as IFormConfig;

  // eslint-disable-next-line class-methods-use-this
  getFieldStatus = (
    formFieldsStatuses: TFieldStatuses,
    fieldBlock: EFieldBlock,
    fieldName: EFieldName,
  ): IFieldStatus => {
    if (!formFieldsStatuses[fieldBlock]) {
      return {
        code: EValidationFieldStatusCode.DEFAULT,
      };
    }

    return formFieldsStatuses[fieldBlock][fieldName] ?? {
      code: EValidationFieldStatusCode.DEFAULT,
    };
  };

  getBusinessStructureCountryDescription = (
    localisation: ICamelCaseLocalisationAccountActivatePage,
  ): string => {
    const utilsService = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    return utilsService.email.replaceSupportEmail(
      localisation.businessInfo.businessStructure.country.description,
      false,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  getMaxDateOfBirth = (): Date => {
    const maxDateOfBirthDate = new Date();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxDateOfBirthDate.setYear(maxDateOfBirthDate.getFullYear() - 18);
    return maxDateOfBirthDate;
  };

  // eslint-disable-next-line class-methods-use-this
  initOptionalFieldText = (
    field: string,
    commonLocalisation: ICamelCaseLocalisationCommon,
  ) => `${field} (${commonLocalisation.optional})`;

  // eslint-disable-next-line class-methods-use-this
  getBusinessTypes = (
    localisation: ICamelCaseLocalisationAccountActivatePage,
  ): IBusinessType[] => {
    const typeOfBusinessList = this.getTypeOfBusinessList(localisation);
    const individualList = this.getIndividualList(localisation);

    const businessStructure = localisation.businessInfo.businessStructure;
    const businessDetails = localisation.businessInfo.businessDetails;

    return [
      {
        id: EBusinessType.COMPANY,
        title: businessStructure.typeOfBusiness.noventiq.buttons.company,
        typeOfBusiness: typeOfBusinessList[ETypeOfBusiness.COMPANY],
      },
      {
        id: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
        title: businessDetails.individual.buttons.individualEntrepreneur,
        typeOfBusiness: typeOfBusinessList[EIndividualKey.INDIVIDUAL],
        individual: individualList[EIndividualKey.INDIVIDUAL_ENTREPRENEUR],
      },
      {
        id: EBusinessType.INDIVIDUAL,
        title: businessStructure.typeOfBusiness.noventiq.buttons.individual,
        typeOfBusiness: typeOfBusinessList[EIndividualKey.INDIVIDUAL],
        individual: individualList[EIndividualKey.INDIVIDUAL],
      },
      {
        id: EBusinessType.SELF_EMPLOYED,
        title: businessDetails.individual.buttons.selfEmployed,
        typeOfBusiness: typeOfBusinessList[EIndividualKey.INDIVIDUAL],
        individual: individualList[EIndividualKey.SELF_EMPLOYED],
      },
    ];
  };

  // eslint-disable-next-line class-methods-use-this
  getTypeOfBusinessList = (
    localisation: ICamelCaseLocalisationAccountActivatePage,
  ): TTypeOFBusiness => ({
    [ETypeOfBusiness.COMPANY]: {
      id: 1,
      title: localisation.businessInfo.businessStructure.typeOfBusiness.noventiq.buttons.company,
      value: ETypeOfBusiness.COMPANY,
    },
    [ETypeOfBusiness.INDIVIDUAL]: {
      id: 2,
      title: localisation.businessInfo.businessStructure.typeOfBusiness.noventiq.buttons.individual,
      value: ETypeOfBusiness.INDIVIDUAL,
    },
  });

  // eslint-disable-next-line class-methods-use-this
  getIndividualList = (localisation: ICamelCaseLocalisationAccountActivatePage): TIndividual => ({
    [EIndividualKey.INDIVIDUAL]: {
      id: 1,
      title: localisation.businessInfo.businessDetails.individual.buttons[EIndividualKey.INDIVIDUAL],
      value: EIndividual.INDIVIDUAL,
    },
    [EIndividualKey.SELF_EMPLOYED]: {
      id: 2,
      title: localisation.businessInfo.businessDetails.individual.buttons[EIndividualKey.SELF_EMPLOYED],
      value: EIndividual.SELF_EMPLOYED,
    },
    [EIndividualKey.INDIVIDUAL_ENTREPRENEUR]: {
      id: 3,
      title: localisation.businessInfo.businessDetails.individual.buttons[EIndividualKey.INDIVIDUAL_ENTREPRENEUR],
      value: EIndividual.INDIVIDUAL_ENTREPRENEUR,
    },
  });

  // eslint-disable-next-line class-methods-use-this
  getCurrencies = (
    localisation: ICamelCaseLocalisationAccountActivatePage,
  ): ICurrency[] => [
    {
      id: 1,
      title: localisation.bank.currency.buttons.eur,
      value: ECurrency.EUR,
    },
    {
      id: 2,
      title: localisation.bank.currency.buttons.usd,
      value: ECurrency.USD,
    },
  ];

  // eslint-disable-next-line class-methods-use-this
  getTaxCalculationList = (localisation: ICamelCaseLocalisationAccountActivatePage): TTaxCalculationList => ({
    [ETaxCalculation.INCLUSIVE]: {
      id: 1,
      title: localisation.taxCalculation.taxCalculation.buttons[ETaxCalculation.INCLUSIVE],
      value: ETaxCalculation.INCLUSIVE,
    },
    [ETaxCalculation.EXCLUSIVE]: {
      id: 2,
      title: localisation.taxCalculation.taxCalculation.buttons[ETaxCalculation.EXCLUSIVE],
      value: ETaxCalculation.EXCLUSIVE,
    },
  });

  filterCountry = (
    fieldBlock: EFieldBlock,
    fieldName: EFieldName,
  ) => this.state.lists.country.filter((country) => {
    if (!this.state.filteredSelectRegistry[fieldBlock]?.[fieldName]) {
      return true;
    }

    return country.name
      .toLowerCase()
      .includes(this.state.filteredSelectRegistry[fieldBlock][fieldName].toLowerCase());
  });
}

export default FormConfigHandler;

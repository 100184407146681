<template>
  <div
    class="c-form"
  >
    <template
      v-for="block in formConfig"
    >
      <CFieldList
        v-if="checkCondition(block, currentFormData)"
        :key="block.id"
        :subtitle="block.subHeader ? block.subHeader : ''"
        :title="block.header"
        class="c-form__block"
      >
        <template
          v-if="block.type === 'delimiter'"
        >
          <hr
            class="c-form__delimiter"
          >
        </template>
        <template
          v-else
          v-for="item in block.items"
        >
          <div
            v-if="checkCondition(item, currentFormData)"
            :id="getElementId(item)"
            :key="`${item.fieldBlock}${item.fieldName}${item.id}`"
            class="c-form__field"
          >
            <template
              v-if="item.type === 'input'"
            >
              <CInput
                :description="getDescription(item)"
                :label="item.label"
                :placeholder="item.placeholder"
                :tooltip="item.tooltip"
                :status="item.status"
                :value="getValue(item)"
                :type="getType(item)"
                @blur="handleBlur(item, $event)"
                @input="handleInput(item, $event)"
              />
            </template>
            <template
              v-if="item.type === 'maskedInput'"
            >
              <CMaskedInput
                :description="getDescription(item)"
                :label="item.label"
                :placeholder="item.placeholder"
                :mask-options="item.maskOptions"
                :tooltip="item.tooltip"
                :status="item.status"
                :value="getValue(item)"
                :type="getType(item)"
                @blur="handleBlur(item, $event)"
                @input="handleInput(item, $event)"
              />
            </template>
            <template
              v-if="item.type === 'textarea'"
            >
              <CTextarea
                :description="getDescription(item)"
                :label="item.label"
                :placeholder="item.placeholder"
                :tooltip="item.tooltip"
                :status="item.status"
                :max-length="readConfig(item, 'maxLength')"
                :min-rows-count="readConfig(item, 'minRowsCount')"
                :max-rows-count="readConfig(item, 'maxRowsCount')"
                :max-chars-in-row="readConfig(item, 'maxCharsInRow')"
                :value="getValue(item)"
                @blur="handleBlur(item, $event)"
                @input="handleInput(item, $event)"
              />
            </template>
            <template
              v-else-if="item.type === 'select'"
            >
              <CSelect
                :description="getDescription(item)"
                :label="item.label"
                :placeholder="item.placeholder"
                :list="item.select.list"
                :id-field="item.select.idField"
                :title-field="item.select.titleField"
                :value="getValue(item)"
                :tooltip="item.tooltip"
                :status="item.status"
                :offset="item.select.offset"
                @blur="handleBlur(item, $event)"
                @scroll-y-reach-end="handleScrollToBottom($event, item)"
              >
                <template
                  #before-list
                >
                  <slot
                    name="form-select-before"
                    :item="item"
                  />
                </template>
                <template
                  #after-list
                >
                  <slot
                    name="form-select-after"
                    :item="item"
                  />
                </template>
                <template
                  v-if="isSelectListItemSlot"
                  #list-item="{ option }"
                >
                  <slot
                    name="select-list-item"
                    :item="item"
                    :option="option"
                  />
                </template>
              </CSelect>
            </template>
            <template
              v-else-if="item.type === 'filteredSelect'"
            >
              <CFilteredSelect
                :description="getDescription(item)"
                :label="item.label"
                :placeholder="item.placeholder"
                :list="item.select.list"
                :id-field="item.select.idField"
                :title-field="item.select.titleField"
                :value="getValue(item)"
                :tooltip="item.tooltip"
                :status="item.status"
                :input-delay="item.select.inputDelay"
                :offset="item.select.offset"
                :symbols-for-search="item.select.symbolsForSearch"
                :auto-select-single-value="item.select.autoSelectSingleValue"
                @blur="handleBlur(item, $event)"
                @input="handleInput(item, $event)"
                @filtered-select-search-input="handleFilteredSelectSearchFieldInput($event, item)"
                @scroll-y-reach-end="handleScrollToBottom($event, item)"
              >
                <template
                  #before-list
                >
                  <slot
                    name="filtered-select-before-list"
                    :item="item"
                  />
                </template>
                <template
                  v-if="isFilteredSelectListItemSlot"
                  #list-item="{ option }"
                >
                  <slot
                    name="filtered-select-list-item"
                    :item="item"
                    :option="option"
                  />
                </template>
                <template
                  #after-list
                >
                  <slot
                    name="filtered-select-after-list"
                    :item="item"
                  />
                </template>
              </CFilteredSelect>
            </template>
            <template
              v-else-if="item.type === 'buttonGroup'"
            >
              <CButtonGroup
                v-if="checkCondition(item, currentFormData)"
                :button-list="item.buttonList"
                :description="getDescription(item)"
                :label="item.label"
                :tooltip="item.tooltip"
                :value="getValue(item)"
                :variant="item.variant"
                @input="handleInput(item, $event)"
                @blur="handleBlur(item, $event)"
              />
            </template>
            <template
              v-else-if="item.type === 'date'"
            >
              <CLabel
                :text="item.label"
              />
              <DatePicker
                :max-date="item.maxDate"
                :short-date="true"
                :value="getValue(item)"
                class="c-form__date-picker"
                @update:value="handleBlur(item, $event)"
              />
            </template>
            <template
              v-else-if="item.type === 'expandableList'"
            >
              <CExpandableList
                :title="item.label"
                :add-button-text="item.expandableList.addButtonText"
                :select-placeholder="item.placeholder"
                :item-list="item.expandableList.list"
                :id-field="item.expandableList.idField"
                :title-field="item.expandableList.titleField"
                :selected-item-list="item.expandableList.selectedItemList"
                :status="item.status"
                @add-item="handleExpandableListAddItem($event, item)"
                @remove-item="handleExpandableListRemoveItem($event, item)"
                @scroll-y-reach-end="handleScrollToBottom($event, item)"
                @filtered-select-search-input="handleExpandableListSearchFieldInput($event, item)"
              >
                <template
                  #list-item="slotProps"
                >
                  <slot
                    name="expandable-list-list-item"
                    :option="item"
                    :item="slotProps.item"
                  />
                </template>
                <template
                  #select-before-list
                >
                  <slot
                    name="expandable-list-select-before-list"
                    :item="item"
                  />
                </template>

                <template
                  #select-item="slotProps"
                >
                  <slot
                    name="expandable-list-select-item"
                    :option="slotProps.option"
                    :item="item"
                  />
                </template>

                <template
                  #select-after-list
                >
                  <slot
                    name="expandable-list-select-after-list"
                    :item="item"
                  />
                </template>
              </CExpandableList>
            </template>
            <template
              v-else-if="item.type === 'checkbox'"
            >
              <CCheckbox
                :title="item.label"
                :tooltip="item.tooltip"
                :value="getValue(item)"
                @input="handleBlur(item, $event);"
              />
            </template>
            <template
              v-else-if="item.type === 'switcher'"
            >
              <CSwitcher
                :label="item.label"
                :value="getValue(item)"
                :tooltip="item.tooltip"
                :revert="item.switcher.revert"
                :description="getDescription(item)"
                color="primary"
                @change="handleBlur(item, $event);"
              >
                <template
                  v-if="item.switcher && item.switcher.withHeader"
                  #switcher-label="{ label }"
                >
                  <div
                    class="c-form__switcher-header"
                  >
                    <DSHeader
                      :text="label"
                      size="3"
                    />
                    <DSTooltip
                      v-if="item.tooltip"
                      :text="item.tooltip"
                    >
                      <template
                        #tooltip-icon
                      >
                        <div>
                          <DSIcon
                            icon="info"
                            size="m"
                            color="disabled"
                          />
                        </div>
                      </template>
                    </DSTooltip>
                  </div>
                </template>
              </CSwitcher>
            </template>
            <template
              v-else-if="item.type === 'inputList'"
            >
              <CInputList
                :value="getValue(item)"
                :add-button-text="item.inputList.addButtonText"
                :max-items="item.inputList.maxItems"
                :statuses="item.inputList.statuses"
                :placeholder="item.inputList.placeholder"
                :input-delay="item.inputList.inputDelay"
                @input="handleBlur(item, $event);"
                @blur="handleBlur(item, $event);"
              >
                <template
                  #before="slotProps"
                >
                  <slot
                    name="input-list-item-before"
                    :index="slotProps.index"
                    :item="slotProps.item"
                    :status="slotProps.status"
                  />
                </template>
                <template
                  #after="slotProps"
                >
                  <slot
                    name="input-list-item-after"
                    :index="slotProps.index"
                    :item="slotProps.item"
                    :status="slotProps.status"
                  />
                </template>
              </CInputList>
            </template>
            <template
              v-else-if="item.type === 'checkboxGroup'"
            >
              <CCheckboxGroup
                :list="item.checkboxGroup.list"
                :label="item.label"
                :value="getValue(item)"
                :tooltip="item.tooltip"
                :status="item.status"
                @input="handleBlur(item, $event);"
              />
            </template>
            <template
              v-else-if="item.type === 'fileUpload'"
            >
              <CFileUploadArea
                :label="item.label"
                :description="getDescription(item)"
                :options="item.fileUpload.options"
                :files="getValue(item)"
                :tooltip="item.tooltip"
                :status="item.status"
                @input="handleBlur(item, $event);"
                @delete-file="handleFileUploadDeleteFile($event, item)"
                @download-file="handleFileUploadDownloadFile($event, item)"
              />
            </template>
            <template
              v-else-if="item.type === 'inputWithSelectList'"
            >
              <CInputWithSelectList
                :button-config="item.config.buttonConfig"
                :icon-config="item.config.iconConfig"
                :select-config="item.config.selectConfig"
                :input-config="item.config.inputConfig"
                :item-label="item.config.itemLabel"
                :max-items="item.config.maxItems"
                :value="getValue(item)"
                @add-item="handleBlur(item, $event.list)"
                @delete-item="handleBlur(item, $event.list)"
                @input-blur="handleBlur(item, $event.list)"
                @select-blur="handleBlur(item, $event.list)"
              />
            </template>
            <template
              v-else-if="item.type === 'infoBlock'"
            >
              <DSInlineAlert
                :text="item.config.text"
                :variant="item.config.variant"
                :disable-icon="item.config.disableIcon"
                :allow-html="item.config.allowHtml"
              />
            </template>
            <template
              v-else-if="item.type === 'inputWithSelect'"
            >
              <CInputWithSelect
                :icon-config="item.config.iconConfig"
                :select-config="item.config.selectConfig"
                :input-config="item.config.inputConfig"
                :label="item.label"
                :tooltip="item.tooltip"
                :value="getValue(item)"
                :mode="item.config.mode"
                @input-blur="handleBlur(item, $event)"
                @select-blur="handleBlur(item, $event)"
              />
            </template>
            <template
              v-else-if="item.type === 'pricingTable'"
            >
              <PricingTable
                :edit="!item.config.renewal"
                :value="getValue(item)"
                @blur="handleBlur(item, $event)"
              />
            </template>
            <template
              v-else-if="item.type === 'slot'"
            >
              <slot
                :name="item.slotName"
              />
            </template>
            <slot
              name="after-item"
              :item="item"
            />
          </div>
        </template>
      </CFieldList>
    </template>
  </div>
</template>

<script>
import CButtonGroup from '@/module/common/components/UI/CButtonGroup.vue';
import CCheckbox from '@/module/common/components/UI/CCheckbox.vue';
import CExpandableList from '@/module/common/components/UI/ExpandableList/CExpandableList.vue';
import CFieldList from '@/module/common/components/CFieldList.vue';
import CFilteredSelect from '@/module/common/components/UI/CFilteredSelect.vue';
import CInput from '@/module/common/components/UI/CInput.vue';
import CInputList from '@/module/common/components/UI/CInputList.vue';
import CLabel from '@/module/common/components/UI/CLabel.vue';
import CMaskedInput from '@/module/common/components/UI/MaskedInput/CMaskedInput.vue';
import CSelect from '@/module/common/components/UI/CSelect.vue';
import CSwitcher from '@/module/common/components/UI/CSwitcher.vue';
import CTextarea from '@/module/common/components/UI/CTextarea.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import CCheckboxGroup from '@/module/common/components/UI/CCheckboxGroup.vue';
import checkCondition from '@/module/common/helpers/checkCondition';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import CFileUploadArea from '@/module/common/components/UI/CFileUpload/CFileUploadArea.vue';
import CInputWithSelectList from '@/module/common/components/UI/CInputWithSelectList.vue';
import DSInlineAlert from '@/module/design-system/components/UI/DSInlineAlert.vue';
import { nextTick } from 'vue';
import CInputWithSelect from '@/module/common/components/UI/CInputWithSelect.vue';
import PricingTable from '@/module/product/components/PricingTable.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';

export default {
  name: 'CFormComponent',
  components: {
    DSTooltip,
    DSIcon,
    CInputWithSelect,
    DSInlineAlert,
    CInputWithSelectList,
    CFileUploadArea,
    CButtonGroup,
    CCheckbox,
    CExpandableList,
    CFieldList,
    CFilteredSelect,
    CInput,
    CInputList,
    CLabel,
    CMaskedInput,
    CSelect,
    CSwitcher,
    CTextarea,
    DatePicker,
    DSHeader,
    CCheckboxGroup,
    PricingTable,
  },
  props: {
    formConfig: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
    validators: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentFormData: {},
    };
  },
  computed: {
    isFilteredSelectListItemSlot() {
      return !!this.$scopedSlots['filtered-select-list-item'];
    },
    isSelectListItemSlot() {
      return !!this.$scopedSlots['select-list-item'];
    },
  },
  watch: {
    formData() {
      this.currentFormData = cloneImmutable(this.formData);
    },
  },
  mounted() {
    this.currentFormData = cloneImmutable(this.formData);
  },
  methods: {
    readConfig(item, key) {
      if (!item.config) {
        return undefined;
      }

      return item.config[key] ?? undefined;
    },
    getValue(item, formData = null) {
      const localFormData = formData ? cloneImmutable(formData) : cloneImmutable(this.currentFormData);
      if (!item.fieldBlock) {
        const currentFormDatum = localFormData[item.fieldName];

        return this.isEmpty(currentFormDatum) ? item.default : currentFormDatum;
      }
      if (!localFormData[item.fieldBlock]) {
        return item.default;
      }
      const currentFormDatumElement = localFormData[item.fieldBlock][item.fieldName];

      const value = this.isEmpty(currentFormDatumElement) ? item.default : currentFormDatumElement;
      return !value && item.type === 'input' ? '' : value;
    },
    getType(item) {
      return item.inputType ?? 'text';
    },
    getDescription(item) {
      if (!item.description) {
        return '';
      }

      if (item.description.variants) {
        const filteredVariants = item.description.variants
          .filter((variant) => checkCondition.resolveAndCondition(variant.conditionsList, this.currentFormData));

        return filteredVariants[0]?.value ?? item.description.default;
      }

      return item.description.default;
    },
    isEmpty(value) {
      if (value === null) {
        return false;
      }

      if (Array.isArray(value)) {
        return value.length === 0;
      }

      if (typeof value === 'object' && value.constructor.name === 'Object') {
        return Object.values(value).length === 0;
      }

      return !value;
    },
    setValue(item, value) {
      const formData = cloneImmutable(this.currentFormData);
      if (item.fieldBlock) {
        if (!formData[item.fieldBlock]) {
          formData[item.fieldBlock] = {};
        }
        formData[item.fieldBlock][item.fieldName] = value;
      } else {
        formData[item.fieldName] = value;
      }

      this.currentFormData = cloneImmutable(formData);
    },
    async handleBlur(item, value) {
      await nextTick();
      const prevValue = this.getValue(item, this.formData);

      this.setValue(item, value);

      this.$emit('field-blur', {
        field: item,
        value,
        prevValue,
        formData: cloneImmutable(this.currentFormData),
      });
      this.$emit('blur', this.currentFormData);
    },
    handleInput(item, value) {
      const prevValue = this.getValue(item, this.formData);

      this.setValue(item, value);

      this.$emit('field-input', {
        field: item,
        value,
        prevValue,
        formData: cloneImmutable(this.currentFormData),
      });
      this.$emit('input', this.currentFormData);
    },
    checkCondition(item, data) {
      if (!item.conditionsList) {
        return true;
      }

      return checkCondition.resolveAndCondition(item.conditionsList, data);
    },
    getElementId(item) {
      let id = false;
      if (item.fieldBlock) {
        id = item.fieldBlock;
      }
      if (item.fieldName) {
        id = [id, item.fieldName].filter((part) => part)
          .join('.');
      }

      return id;
    },
    handleExpandableListAddItem(event, formConfig) {
      this.$emit('expandable-list-add-item', {
        data: event,
        formConfig,
      });
    },
    handleExpandableListRemoveItem(event, formConfig) {
      this.$emit('expandable-list-remove-item', {
        data: event,
        formConfig,
      });
    },
    handleScrollToBottom(event, formConfig) {
      this.$emit('list-scroll-y-reach-end', {
        data: event,
        formConfig,
      });
    },
    handleFilteredSelectSearchFieldInput(event, formConfig) {
      this.$emit('filtered-select-search-field-input', {
        data: event,
        formConfig,
      });
    },
    handleExpandableListSearchFieldInput(event, formConfig) {
      this.$emit('expandable-list-search-field-input', {
        data: event,
        formConfig,
      });
    },
    handleFileUploadDropError(event, formConfig) {
      this.$emit('file-upload-drop-error', {
        file: event,
        formData: this.currentFormData,
        formConfig,
      });
    },
    handleFileUploadDeleteFile(event, formConfig) {
      this.$emit('file-upload-delete-file', {
        file: event,
        formData: this.currentFormData,
        formConfig,
      });
    },
    handleFileUploadDownloadFile(event, formConfig) {
      this.$emit('file-upload-download-file', {
        file: event,
        formData: this.currentFormData,
        formConfig,
      });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.c-form {
  &__switcher-header {
    display: flex;
    gap: 8px;
  }

  &__block {
    max-width: 540px;
    margin-bottom: 44px;
  }

  &__date-picker {
    width: 100%;
  }

  &__delimiter {
    width: 100%;
    margin: 0;
    border: none;
    border-top: 1px solid $surface-stroke-color;
  }
}
</style>

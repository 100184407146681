enum ERouteName {
  ACCOUNT_ACTIVATE = 'AccountActivate',
  ADMIN = 'Admin',
  ADMIN_LOGIN = 'AdminLogin',
  API_KEYS = 'ApiKeys',
  BRANDING = 'Branding',
  CHECKOUT_LINK_FORM = 'CheckoutLinkForm',
  CHECKOUT_LINK_FORM_EDIT = 'CheckoutLinkFormEdit',
  CHECKOUT_LINK_LIST = 'CheckoutLink',
  COMPONENT_DEMO_DEFAULT = 'ComponentDemoDefault',
  COMPONENT_DEMO_ROOT = 'ComponentDemoRoot',
  COOKIE_POLICY = 'CookiePolicy',
  DASHBOARD = 'Home',
  DATA_EXPORTS = 'DataExports',
  DS_COLOR_SCHEME = 'DsColorScheme',
  DS_DEFAULT = 'DsDefault',
  DS_ROOT = 'DsRoot',
  EMPTY_PAGE = 'EmptyPage',
  FORM_COMPONENT_DEMO = 'FormComponentDemo',
  FULFILLMENT_FORM = 'FulfillmentForm',
  FULFILLMENT_FORM_EDIT = 'FulfillmentFormEdit',
  LOGIN = 'login',
  PAYOUTS = 'Payouts',
  PRIVACY_NOTICE = 'PrivacyNotice',
  PRODUCT_CARD = 'ProductCard',
  PRODUCT_FORM = 'CreateProduct',
  PRODUCT_FORM_EDIT = 'EditProduct',
  PRODUCT_LIST = 'ProductsOverview',
  PROFILE = 'Profile',
  PROMOTION_CARD = 'PromotionCard',
  PROMOTION_FORM = 'CreatePromotion',
  PROMOTION_FORM_EDIT = 'EditPromotion',
  PROMOTION_LIST = 'PromotionsOverview',
  REPORTS_OVERVIEW = 'ReportsOverview',
  SSO_AUTH = 'SsoAuth',
  WEBHOOK_CARD = 'WebhookCard',
  WEBHOOK_FORM = 'CreateWebhook',
  WEBHOOK_FORM_EDIT = 'EditWebhook',
  WEBHOOK_LIST = 'Webhooks',
}

export default ERouteName;

import IFormDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ICInfoBlock from '@/module/common/models/ICInfoBlock/ICInfoBlock';
import ICInfoBlockItem from '@/module/common/models/ICInfoBlock/ICInfoBlockItem';
import checkCondition from '@/module/common/helpers/checkCondition';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import IValidatorHandler from '@/module/merchant/view/AccountActivatePage/Validator/IValidatorHandler';
import EPageTab from '@/module/merchant/view/AccountActivatePage/models/EPageTab';
import IFormDataHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormDataHandler';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import IFormDraftHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormDraftHandler';
import IFormConfigHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormConfigHandler';
import EBusinessRepresentative from '@/module/merchant/view/AccountActivatePage/models/EBusinessRepresentative';
import IFormConfig from '@/module/merchant/view/AccountActivatePage/models/IFormConfig';
import ISummaryDataBlock from '@/module/merchant/view/AccountActivatePage/models/ISummaryDataBlock';
import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import ETypeOfBusiness from '@/module/merchant/models/BuisnessType/ETypeOfBusiness';
import TFormData
  from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';
import THandleFieldBlurParams from '@/module/merchant/view/AccountActivatePage/models/THandleFieldBlurParams';
import ITab from '@/module/merchant/view/AccountActivatePage/models/ITab';
import IFilteredSelectHandler from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFilteredSelectHandler';
import EIndividual from '@/module/merchant/models/BuisnessType/EIndividual';

class FormDataHandler implements IFormDataHandler {
  private readonly state: IPageState;

  private readonly localisationRegistry: ILocalisationRegistry;

  private readonly serviceContainer: IServiceContainer;

  private readonly emptyFieldsValidatorHandler: IValidatorHandler;

  private readonly draftHandler: IFormDraftHandler;

  private readonly formConfigHandler: IFormConfigHandler;

  private readonly fromDraftMapper: IFormDataMapper;

  private readonly toSummaryMapper: IFormDataMapper;

  private readonly toSaveMapper: IFormDataMapper;

  private readonly filteredSelectHandler: IFilteredSelectHandler;

  constructor(
    state: IPageState,
    localisationRegistry: ILocalisationRegistry,
    serviceContainer: IServiceContainer,
    emptyFieldsValidatorHandler: IValidatorHandler,
    draftHandler: IFormDraftHandler,
    formConfigHandler: IFormConfigHandler,
    fromDraftMapper: IFormDataMapper,
    toSummaryMapper: IFormDataMapper,
    toSaveMapper: IFormDataMapper,
    filteredSelectHandler: IFilteredSelectHandler,
  ) {
    this.state = state;
    this.localisationRegistry = localisationRegistry;
    this.serviceContainer = serviceContainer;
    this.emptyFieldsValidatorHandler = emptyFieldsValidatorHandler;
    this.draftHandler = draftHandler;
    this.formConfigHandler = formConfigHandler;
    this.fromDraftMapper = fromDraftMapper;
    this.toSummaryMapper = toSummaryMapper;
    this.toSaveMapper = toSaveMapper;
    this.filteredSelectHandler = filteredSelectHandler;
  }

  // eslint-disable-next-line class-methods-use-this
  initFromData(
    formData?: TFormData,
  ): TFormData {
    const tabListConfig = this.formConfigHandler.getTabListConfig();

    const currentFormData = tabListConfig
      .reduce((data, formConfigItem) => formConfigItem.formConfig
        .reduce((formConfigData, config) => config.items
          .reduce((fieldData, field) => {
            const { fieldBlock, fieldName } = field;

            if (!fieldData[fieldBlock]) {
              fieldData[fieldBlock] = {};
            }

            fieldData[fieldBlock][fieldName] = formData?.[fieldBlock]?.[fieldName] ?? field.default;

            fieldData = this.fromDraftMapper
              .mapFieldValue(field, fieldData as TFormData);

            return fieldData;
          }, formConfigData), data), {}) as TFormData;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const representative = currentFormData?.businessRepresentative?.representative?.id as EBusinessRepresentative;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const jobTitle = currentFormData?.businessRepresentative?.jobTitle;
    if (representative === EBusinessRepresentative.HEADER && !jobTitle) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentFormData.businessRepresentative.jobTitle = currentFormData.businessRepresentative.representative.title;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return currentFormData;
  }

  // eslint-disable-next-line class-methods-use-this
  handleFieldBlur = (item: THandleFieldBlurParams) => {
    if (item.field.type === EFormFieldType.FILTERED_SELECT) {
      this.filteredSelectHandler.dropFilter(item);
    }

    this.defaultBlurScenario(item);

    const tabListConfig = this.formConfigHandler.getTabListConfig();

    this.state.tabListConfig = tabListConfig;
    this.state.activeTabConfig = tabListConfig
      .find((tabConfig) => tabConfig.id === this.state.activeTab.id) as IFormConfig;
  };

  // eslint-disable-next-line class-methods-use-this
  defaultBlurScenario = (item: THandleFieldBlurParams) => {
    const utilsService = this.serviceContainer.utilsService;

    this.state.formData = utilsService.data.cloneImmutable(item.formData);

    const localFormFieldStatusList = utilsService.data.cloneImmutable(this.state.formFieldStatusList);
    this.state.formFieldStatusList = this.emptyFieldsValidatorHandler
      .validateField(item.field, this.state.formData, localFormFieldStatusList);

    const tabListConfig = this.formConfigHandler.getTabListConfig();
    this.state.formDraft = this.draftHandler.updateDraft(tabListConfig, this.state.formDraft);
    this.draftHandler.saveDraft(this.state.formDraft);
  };

  // eslint-disable-next-line class-methods-use-this
  getSummaryData = (
    formData: TFormData,
    tabListConfig: IFormConfig[],
  ): ISummaryDataBlock[] => tabListConfig
    .filter((tabConfigItem) => tabConfigItem.id !== EPageTab.SUMMARY)
    .reduce((summaryBlockList, formConfigItem) => {
      const data = [] as ICInfoBlockItem[];

      formConfigItem.formConfig.forEach((config) => {
        config.items
          .filter(
            (field) => {
              if (!field.conditionsList) {
                return true;
              }

              return checkCondition.resolveAndCondition(field.conditionsList, formData);
            },
          )
          .forEach((field) => {
            const item = this.toSummaryMapper.mapFieldValue(field, formData) as ICInfoBlockItem;
            data.push(item);
          });
      });

      const infoBlock: ICInfoBlock = {
        title: formConfigItem.title,
        data,
      };

      const summaryDataBlock: ISummaryDataBlock = {
        tabConfig: formConfigItem,
        ...infoBlock,
        tab: this.state.tabs
          .find((tabItem) => tabItem.id === formConfigItem.id) as ITab,
      };
      summaryBlockList.push(summaryDataBlock);

      return summaryBlockList;
    }, [] as ISummaryDataBlock[]);

  saveData = async () => {
    const api = this.serviceContainer.resolve(EServiceContainerInstances.API);

    const apiCallsMap = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [EBusinessType.COMPANY]: async (formData) => api.account.activateCompanyAccount({ formData }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [EBusinessType.INDIVIDUAL_ENTREPRENEUR]: async (formData) => api.account
        .activateIndividualEntrepreneurAccount({ formData }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [EBusinessType.INDIVIDUAL]: async (formData) => api.account.activateIndividualAccount({ formData }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [EBusinessType.SELF_EMPLOYED]: async (formData) => api.account.activateSelfEmployedAccount({ formData }),
    };

    let data = {} as TFormData;

    this.formConfigHandler.getTabListConfig()
      .filter((tabConfig) => tabConfig.id !== EPageTab.SUMMARY)
      .forEach((tabConfig) => {
        tabConfig.formConfig.forEach((block) => {
          block.items
            .filter(
              (field) => !field.conditionsList || checkCondition.resolveAndCondition(
                field.conditionsList,
                this.state.formData,
              ),
            )
            .forEach((field) => {
              data = this.toSaveMapper.mapFieldValue(field, data);
            });
        });
      });

    // @todo сделать корректную модель данных для data
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    if (data.business_structure.type_of_business === ETypeOfBusiness.INDIVIDUAL
      // @ts-ignore
      && data.business_details.organizational_legal_form_of_business === EIndividual.INDIVIDUAL_ENTREPRENEUR) {
      // @ts-ignore
      data.business_structure.type_of_business = ETypeOfBusiness.COMPANY;
    }
    /* eslint-enable @typescript-eslint/ban-ts-comment */

    const apiVariant = this.getBusinessType();

    return apiCallsMap[apiVariant](data);
  };

  getBusinessType = (): EBusinessType => {
    if (this.state.formData.businessStructure.businessType.typeOfBusiness.value === ETypeOfBusiness.COMPANY) {
      return EBusinessType.COMPANY;
    }

    // TODO: разобраться с типами
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.state.formData.businessDetails.individual.id;
  };
}

export default FormDataHandler;

import ProductModel, {
  EAvailableFor,
  ELicenseType,
  PriceValue,
  TPrice,
} from '@/module/product/model/ProductModel';
import ECurrency from '@/module/merchant/models/ECurrency';
import EPriceType from '@/module/product/model/EPriceType';

const multiplePriceCurrencyList: ECurrency[] = [
  ECurrency.USD,
  ECurrency.AUD,
  ECurrency.CAD,
  ECurrency.EUR,
  ECurrency.GBP,
  ECurrency.NZD,
];

const onePriceCurrencyList: ECurrency[] = [
  ECurrency.USD,
  ECurrency.EUR,
];

const licenseTypeList: ELicenseType[] = [
  ELicenseType.NEW,
  ELicenseType.RENEW,
];

const availableForList: EAvailableFor[] = [
  EAvailableFor.ALL,
  EAvailableFor.PHYSICAL,
  EAvailableFor.JURIDICAL,
];

const priceTypeList: EPriceType[] = [
  EPriceType.ONE_PRICE,
  EPriceType.VOLUME_PRICE,
  EPriceType.ONE_PRICE_EACH_CURRENCY,
  EPriceType.VOLUME_PRICE_AND_OPTIONS,
];

const autoRenewVariants: boolean[] = [true, false];

const trialVariants: boolean[] = [true, false];

function getTitle(
  priceType: EPriceType,
  availableFor: EAvailableFor,
  licenseType: ELicenseType,
  autoRenewVariant: boolean,
  trialVariant: boolean,
  currencies: ECurrency[],
): string {
  const parts = [
    currencies.join('_'),
    autoRenewVariant ? 'AR' : null,
    trialVariant ? 'TR' : null,
    licenseType.toUpperCase(),
    priceType,
    availableFor.toUpperCase(),
  ];

  return parts.filter((part) => !!part).join('__');
}

function getCurrencies(priceType: EPriceType): ECurrency[] {
  const prices: ECurrency[] = [];
  if ([EPriceType.VOLUME_PRICE, EPriceType.ONE_PRICE].includes(priceType)) {
    const index: number = Math.floor(Math.random() * onePriceCurrencyList.length);
    const eCurrency = onePriceCurrencyList[index];
    prices.push(eCurrency);
  } else if ([EPriceType.ONE_PRICE_EACH_CURRENCY, EPriceType.VOLUME_PRICE_AND_OPTIONS].includes(priceType)) {
    const indexStack: Set<number> = new Set();

    while (indexStack.size < 2) {
      const index: number = Math.floor(Math.random() * multiplePriceCurrencyList.length);
      indexStack.add(index);
    }

    Array.from(indexStack).forEach((currencyIndex) => prices.push(multiplePriceCurrencyList[currencyIndex]));
  }

  return prices;
}

function getPrice(priceType: EPriceType, currencies: ECurrency[]): TPrice[] {
  const prices: TPrice[] = [];
  if (priceType === EPriceType.ONE_PRICE) {
    const priceItem: TPrice = {
      sku: `${Math.floor((Math.random() + 1) * 1000)}`,
      price: {
        common: {
          currency: currencies[0],
          price: `${Math.floor((Math.random() + 1) * 1000)}`,
        },
      },
    };
    prices.push(priceItem);
  } else if (priceType === EPriceType.VOLUME_PRICE) {
    [
      { from: 1, to: 2 },
      { from: 3, to: 4 },
    ].forEach((period) => {
      const priceItem: TPrice = {
        ...period,
        sku: `${Math.floor((Math.random() + 1) * 1000)}`,
        price: {
          common: {
            currency: currencies[0],
            price: `${Math.floor((Math.random() + 1) * 1000)}`,
          },
        },
      };
      prices.push(priceItem);
    });
  } else if (priceType === EPriceType.ONE_PRICE_EACH_CURRENCY) {
    const price = currencies.reduce<{ [key in ECurrency]: PriceValue }>((mappedPrices, currency) => {
      mappedPrices[currency] = {
        currency,
        price: `${Math.floor((Math.random() + 1) * 1000)}`,
      };

      return mappedPrices;
    }, {} as { [key in ECurrency]: PriceValue });

    const priceItem: TPrice = {
      sku: `${Math.floor((Math.random() + 1) * 1000)}`,
      price,
    };

    prices.push(priceItem);
  } else if (priceType === EPriceType.VOLUME_PRICE_AND_OPTIONS) {
    [
      { from: 1, to: 2 },
      { from: 3, to: 4 },
    ].forEach((period) => {
      const price = currencies.reduce<{ [key in ECurrency]: PriceValue }>((mappedPrices, currency) => {
        mappedPrices[currency] = {
          currency,
          price: `${Math.floor((Math.random() + 1) * 1000)}`,
        };

        return mappedPrices;
      }, {} as { [key in ECurrency]: PriceValue });

      const priceItem: TPrice = {
        ...period,
        sku: `${Math.floor((Math.random() + 1) * 1000)}`,
        price,
      };

      prices.push(priceItem);
    });
  }

  return prices;
}

function getLicenseTerm(): string {
  const licenseTermVariants = [
    'P##D',
    'P##M',
    'P##Y',
  ];
  const licenseTermIndex: number = Math.floor(Math.random() * licenseTermVariants.length);
  const licenseTermValue = `${Math.floor(Math.random() * 30)}`;

  return licenseTermVariants[licenseTermIndex].replace('##', licenseTermValue);
}

function getProduct(
  priceType: EPriceType,
  availableFor: EAvailableFor,
  licenseType: ELicenseType,
  autoRenewVariant: boolean,
  trialVariant: boolean,
): ProductModel {
  const currencies = getCurrencies(priceType);
  const licenseTerm = getLicenseTerm();

  const price = getPrice(
    priceType,
    currencies,
  );

  const sku = `${Math.floor(Math.random() * 1000)}`;

  const title = getTitle(
    priceType,
    availableFor,
    licenseType,
    autoRenewVariant,
    trialVariant,
    currencies,
  );
  const subtitle = `${title}__subtitle`;
  const description = `${title}__description`;

  const product: ProductModel = {
    title,
    subtitle,
    description,
    licenseType,
    availableFor,
    licenseTerm,
    price,
    trialCheckbox: trialVariant,
    renewalCheckbox: autoRenewVariant,
  };

  if (trialVariant) {
    product.trialSku = sku;
    product.trialTerm = licenseTerm;
    product.trialTitle = title;
    product.trialSubtitle = subtitle;
  }

  if (autoRenewVariant) {
    product.renewalTerm = licenseTerm;
    product.renewalTitle = title;
    product.renewalSubtitle = subtitle;
    product.renewalPrice = price;
  }

  return product;
}

export default function generateProductList(): ProductModel[] {
  const productList: ProductModel[] = [];

  priceTypeList.forEach((priceType) => {
    availableForList.forEach((availableFor) => {
      licenseTypeList.forEach((licenseType) => {
        autoRenewVariants.forEach((autoRenewVariant) => {
          trialVariants.forEach((trialVariant) => {
            const product = getProduct(
              priceType,
              availableFor,
              licenseType,
              autoRenewVariant,
              trialVariant,
            );

            productList.push(product);
          });
        });
      });
    });
  });

  return productList;
}

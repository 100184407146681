<template>
  <COverviewPageLayout>
    <template
      #header
    >
      <COverviewPageHeaderRow
        :page-title="$t('pages.dashboard.title')"
        :tooltip-text="$t('pages.dashboard.tooltip')"
      >
        <template
          #right-side
        >
          <DSButton
            v-if="!isEditModeActive"
            variant="tretiary"
            :text="$t('pages.dashboard.button.edit')"
            @click="handleEditButtonClick"
          >
            <template #icon-before>
              <DSIcon
                icon="gear"
                color="linked"
                size="m"
              />
            </template>
          </DSButton>
          <DSButton
            v-if="isEditModeActive"
            variant="primary"
            :text="$t('pages.dashboard.button.save')"
            @click="handleSave"
          />
          <DSButton
            v-if="isEditModeActive"
            variant="tretiary"
            :text="$t('pages.dashboard.button.cancel')"
            @click="handleCancel"
          />
        </template>
      </COverviewPageHeaderRow>
      <InlineAlert
        v-if="merchantModeAlert.visible"
        class="dashboard-page__merchant-mode-alert"
        :variant="merchantModeAlert.variant"
        :title="merchantModeAlert.title"
        :subtitle="merchantModeAlert.subtitle"
      >
        <template
          #alert-controls
        >
          <button
            class="inline-alert__button"
            type="button"
            @click="handleClickCloseMerchantModeAlert"
          >
            <DSIcon
              icon="cross"
            />
          </button>
        </template>
      </InlineAlert>
    </template>
    <template
      #content
    >
      <DashboardWidgetList
        v-if="showedWidgets.length > 0 && !isEditModeActive"
        :filter="filter"
        :widgets="showedWidgets"
        :locale="locale"
        :dimension="dimension"
        @update-filter="handleUpdateFilter"
      />
      <DashboardWidgetListEdit
        v-else-if="isEditModeActive"
        :showed-widgets="showedWidgets"
        :hidden-widgets="hiddenWidgets"
        :locale="locale"
        :dimension="dimension"
        @update-widgets="handleUpdateWidgets"
      />
      <CStatusWindowComponent
        v-else
        :title="statusWindow.title"
        :subtitle="statusWindow.subtitle"
        size="m"
      >
        <template
          #buttons
        >
          <DSButton
            :text="statusWindow.buttons.edit.config.text"
            @click="statusWindow.buttons.edit.handler"
          />
          <DSButton
            :text="statusWindow.buttons.howItWorks.config.text"
            :variant="statusWindow.buttons.howItWorks.config.variant"
            @click="statusWindow.buttons.howItWorks.handler"
          >
            <template
              #icon-before
            >
              <DSIcon
                color="linked"
                icon="arrowInsideCircle"
              />
            </template>
          </DSButton>
        </template>
      </CStatusWindowComponent>
      <div
        v-if="showedWidgets.length > 0 && !isEditModeActive"
        class="dashboard-page__footer"
      >
        <DSTextElement
          color="secondary"
        >
          {{ dataHasBinUpdatedText }}
        </DSTextElement>
      </div>
    </template>
  </COverviewPageLayout>
</template>

<script>
import CStatusWindowComponent from '@/module/common/components/UI/CStatusWindowComponent.vue';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';

import {
  inject,
  computed,
} from 'vue';
import {
  mapActions,
  mapGetters,
} from 'vuex';

import {
  getIsMerchantModeAlertVisible,
  setMerchantModeAlertStatus,
} from '@/module/common/utils/merchantModeAlert';
import {
  loadWidgetsConfig,
  loadWidgetsData,
  saveWidgets,
} from '@/module/dashboard/view/DashboardOverviewPage/DashboardOverviewPage.func';

import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import DashboardWidgetList from '@/module/dashboard/components/WidgetList/DashboardWidgetList.vue';
import DashboardWidgetListEdit from '@/module/dashboard/components/WidgetList/DashboardWidgetListEdit.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';

export default {
  name: 'DashboardOverviewPage',
  components: {
    COverviewPageHeaderRow,
    COverviewPageLayout,
    CStatusWindowComponent,
    DashboardWidgetList,
    DashboardWidgetListEdit,
    DSButton,
    DSIcon,
    DSTextElement,
    InlineAlert,
  },
  data() {
    return {
      dimensionOptionsList: [],
      isEditModeActive: false,
      statusWindow: {
        title: this.$t('pages.dashboard.error.title.all_hidden'),
        subtitle: this.$t('pages.dashboard.error.subtitle.all_hidden'),
        buttons: {
          edit: {
            config: {
              text: this.$t('pages.dashboard.button.edit'),
            },
            handler: () => this.handleEditButtonClick(),
          },
          howItWorks: {
            config: {
              text: this.$t('global_variables.how_it_works'),
              variant: 'secondary',
            },
            handler: () => this.handleHowItWorksButtonClick(),
          },
        },
      },
      updatedWidgets: [],
      merchantModeAlert: {
        visible: false,
        variant: 'accent',
        title: '',
        subtitle: '',
      },
    };
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const store = serviceContainer.resolve('store');

    const localisationRegistry = store.getters.getLocalisationRegistry;
    const localisation = computed(() => localisationRegistry.dashboard);

    const portalConfigService = serviceContainer.portalConfigService;
    const docsSiteUrl = portalConfigService.get().url.docs;

    const utils = serviceContainer.resolve('utils');

    const helpLink = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/merchant-portal/webhooks-overview`,
      store.getters.getLocale,
    );

    function handleHowItWorksButtonClick() {
      window.open(helpLink);
    }

    return {
      serviceContainer,
      localisation,
      handleHowItWorksButtonClick,
    };
  },
  computed: {
    ...mapGetters({
      date: 'dashboardStore/getDate',
      comparisonDate: 'dashboardStore/getComparisonDate',
      selectionMode: 'dashboardStore/getSelectionMode',
      comparisonSelectionMode: 'dashboardStore/getComparisonSelectionMode',
      dimension: 'dashboardStore/getDimension',
      widgets: 'dashboardStore/getWidgets',
      locale: 'getLocale',
      errors: 'commonStatusesStore/getErrors',
      dataUpdateDate: 'dashboardStore/getDataUpdateDate',
      merchantMode: 'authStore/getMerchantMode',
      localisationRegistry: 'getLocalisationRegistry',
    }),
    filter() {
      return {
        date: this.date,
        comparisonDate: this.comparisonDate,
        selectionMode: this.selectionMode,
        comparisonSelectionMode: this.comparisonSelectionMode,
        dimension: this.dimension,
      };
    },
    showedWidgets() {
      return this.widgets.filter((widget) => widget.config?.showOnPage);
    },
    hiddenWidgets() {
      return this.widgets.filter((widget) => !widget.config?.showOnPage);
    },
    dataHasBinUpdatedText() {
      const date = this.dataUpdateDate ?? this.$utils.date.now();
      const dates = {
        date: this.$utils.date.formatSkipCurrentYear(date),
        time: this.$utils.date.getFormat(date, 'HH:mm:ss'),
      };

      return this.$t('pages.dashboard.data_updated_at', dates);
    },
  },
  created() {
    this.initResizeListener();
  },
  async mounted() {
    this.merchantModeAlert.visible = getIsMerchantModeAlertVisible(
      'dashboard',
      this.merchantMode,
    );
    this.merchantModeAlert.title = this.$t(`pages.dashboard.merchant_mode_alert.${this.merchantMode}.title`);
    this.merchantModeAlert.subtitle = this.$t(`pages.dashboard.merchant_mode_alert.${this.merchantMode}.text`);

    await loadWidgetsConfig(this.localisation, this.serviceContainer);

    await loadWidgetsData(this.localisation, this.serviceContainer, this.filter);
  },
  destroyed() {
    window.removeEventListener('resize', this.initResizeListener);
  },
  methods: {
    ...mapActions({
      updateFilter: 'dashboardStore/updateFilter',
      updateIsCompareAllowed: 'dashboardStore/updateIsCompareAllowed',
      updateLoading: 'commonStatusesStore/updateLoading',
    }),
    handleEditButtonClick() {
      this.isEditModeActive = true;
    },
    async handleUpdateFilter(filter) {
      await this.updateFilter(filter);
      await loadWidgetsData(this.localisation, this.serviceContainer, filter);
    },
    initResizeListener() {
      this.updateIsCompareAllowed(window.innerWidth > 670);
    },
    async handleSave() {
      await saveWidgets(this.serviceContainer, this.widgets, this.updatedWidgets);

      this.updatedWidgets = [];
      this.isEditModeActive = false;

      await loadWidgetsConfig(this.localisation, this.serviceContainer);
      await loadWidgetsData(this.localisation, this.serviceContainer, this.filter);
    },
    handleCancel() {
      this.updatedWidgets = [];
      this.isEditModeActive = false;
    },
    handleUpdateWidgets(widgets) {
      this.updatedWidgets = cloneImmutable(widgets);
    },
    handleClickCloseMerchantModeAlert() {
      this.merchantModeAlert.visible = false;
      setMerchantModeAlertStatus('dashboard', 'hidden');
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
.dashboard-page {
  &__merchant-mode-alert {
    margin-top: 32px;
  }

  &__preloader {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
</style>

<template>
  <main class="page-wrapper error-page">
    <div class="error-page__header">
      <slot />
    </div>

    <slot
      name="after-title"
    />

    <section
      v-if="errorCustom && errorCustom.title"
      class="error-page__section"
    >
      <i class="error-page__icon Bag">
        <DSIcon
          icon="upload"
          size="xxl"
        />
      </i>

      <div class="error-page__title">
        <HeaderText
          size="3"
          :text="$t(errorCustom.title)"
        />
        <p class="secondary">
          {{ $t(errorCustom.subtitle) }}
        </p>
      </div>

      <ButtonComponent
        class="error-page__button"
        @click="handleClickErrorButton"
      >
        <template
          v-if="errorCustom.button.icon"
          #icon-before
        >
          <DSIcon
            icon="plus"
            color="primary-button"
          />
        </template>
        {{ $t(errorCustom.button.text) }}
      </ButtonComponent>
      <ButtonComponent
        v-if="errorCustom.buttonAdditional && errorCustom.buttonAdditional.text"
        class="error-page__button--additional"
        variant="secondary"
        @click="handleClickToDoc"
      >
        <template #icon-before>
          <DSIcon
            icon="arrowInsideCircle"
            color="linked"
          />
        </template>
        {{ $t(errorCustom.buttonAdditional.text) }}
      </ButtonComponent>
    </section>
    <section
      v-else-if="error.title"
      class="error-page__section"
    >
      <i class="error-page__icon">
        <DSIcon
          :icon="error.iconType"
          size="xxl"
        />
      </i>

      <div class="error-page__title">
        <HeaderText
          size="3"
          :text="$t(error.title)"
        />
        <p class="secondary">
          {{ $t(error.subtitle) }}
        </p>
      </div>

      <ButtonComponent
        class="error-page__button"
        @click="handleClickErrorButton"
      >
        <template
          v-if="error.button.icon"
          #icon-before
        >
          <DSIcon
            :icon="error.button.icon"
            color="primary-button"
          />
        </template>
        {{ $t(error.button.text) }}
      </ButtonComponent>
    </section>
  </main>
</template>

<script>
import HeaderText from '@/components/HeaderText.vue';
import ButtonComponent from '@/components/Button/ButtonComponent.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import {
  inject,
} from 'vue';

export default {
  name: 'ErrorPage',
  components: {
    HeaderText,
    ButtonComponent,
    DSIcon,
  },
  props: {
    status: {
      type: [
        Number,
        String,
      ],
      default: null,
    },
    errorCustom: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const portalConfigService = serviceContainer.portalConfigService;

    const docsSiteUrl = portalConfigService.get().url.docs;

    const utils = serviceContainer.resolve('utils');

    const store = serviceContainer.resolve('store');

    const helpLink = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/merchant-portal/webhooks-overview`,
      store.getters.getLocale,
    );

    return {
      helpLink,
    };
  },
  computed: {
    error() {
      let error;
      switch (this.status) {
        case 200:
          error = {
            title: 'popup_messages.no_products',
            subtitle: 'popup_messages.create_to_sell',
            iconType: 'upload',
            button: {
              text: 'popup_messages.create_button',
              icon: 'plus',
              path: '/products/new',
            },
          };
          break;
        case 404:
          error = {
            title: 'popup_messages.page_not_found',
            subtitle: 'popup_messages.cant_find_404',
            iconType: 'alert',
            button: {
              text: 'popup_messages.dashboard_button',
              icon: 'home',
              path: '/',
            },
          };
          break;
        case 666:
        default:
          error = {
            title: 'popup_messages.went_wrong2',
            subtitle: 'popup_messages.load_fail',
            iconType: 'alert',
            button: {
              text: 'popup_messages.refresh_button',
              icon: 'refresh',
              path: '',
            },
          };
          break;
      }

      return error;
    },
  },
  methods: {
    handleClickErrorButton() {
      if (this.errorCustom?.button?.path) {
        this.$router.push(this.errorCustom.button.path).catch(() => null);
      } else if (this.error?.button?.path) {
        this.$router.push(this.error.button.path).catch(() => null);
      } else {
        this.$router.go();
      }
    },

    handleClickToDoc() {
      window.open(this.helpLink, '_blank');
    },
  },
};
</script>

<style lang="scss">
.error-page {

  &__header {
    display: flex;
  }

  &__section {
    padding: 0 47px;
    width: 100%;
    height: 600px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 21px;
    background-color: $header-background;
    border-radius: 48px;
  }

  &__title {
    margin: 25px 0;

    h3, p {
      text-align: center;
    }
  }

  &__icon {
    padding-left: 10px;
    text-align: center;

    svg path {
      fill: #C7C7CD;
    }
  }

  &__button {
    justify-content: center;

    &--additional {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 840px) {
  .error-page {
    margin: 24px 0;

    &__section {
      min-height: 550px;
      height: 550px;
    }

    &__header {
      h1 {
        width: min-content;
      }
    }
  }
}
</style>

enum ELocalStorageKeys {
  REFRESH_TOKEN = 'refreshToken',
  AUTH_TOKEN = 'authToken',
  AUTH_REFRESH_TOKEN = 'authRefreshToken',
  ADMIN_AUTH_TOKEN = 'adminAuthToken',
  ADMIN_AUTH_REFRESH_TOKEN = 'adminAuthRefreshToken',
  ACCESS_TOKEN = 'accessToken',
  MERCHANT_MODE = 'merchantMode',
}

export default ELocalStorageKeys;

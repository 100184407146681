import {
  getBusinessRepresentativeList,
  getBusinessType,
  getFileType,
  getIndividualList,
  getTaxationSystemList,
  getTaxCalculationList,
  getTypeOfBusinessList,
  readSummaryFieldTitle,
} from '@/module/merchant/view/AccountActivatePage.func';
import isEmpty from '@/module/common/utils/data/isEmpty';
import moment from 'moment/moment';
import IVueInstance from '@/module/common/models/IVueInstance';
import getLocalisationVue2 from '@/module/common/helpers/getLocalisationVue2';
import ICountryLocalisation from '@/module/common/models/ICountryLocalisation';
import ICInfoBlock from '@/module/common/models/ICInfoBlock/ICInfoBlock';
import ICInfoBlockItem from '@/module/common/models/ICInfoBlock/ICInfoBlockItem';
import EFileStatus from '@/module/common/models/UI/FileUpload/EFileStatus';
import IFile from '@/module/common/models/UI/FileUpload/IFile';
import IFormField from '@/module/design-system/components/models/FormComponent/IFormField';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import ICurrency from '@/module/merchant/models/ICurrency';
import ECurrency from '@/module/merchant/models/ECurrency';
import EBusinessRepresentative from '@/module/merchant/view/AccountActivatePage/models/EBusinessRepresentative';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import IBusinessType from '@/module/merchant/models/BuisnessType/IBusinessType';
import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import EIndividual from '@/module/merchant/models/BuisnessType/EIndividual';
import ETypeOfBusiness from '@/module/merchant/models/BuisnessType/ETypeOfBusiness';

function initCountryLocalisation(vueInstance: IVueInstance): ICountryLocalisation {
  return getLocalisationVue2('country', vueInstance) ?? {} as ICountryLocalisation;
}

function getBlockConfig(
  localisationRegistry: ILocalisationRegistry,
  businessType: IBusinessType,
) {
  const localization = localisationRegistry.accountActivate;
  const typeOfBusiness = businessType.typeOfBusiness.value;
  const subtypeOfBusiness = businessType.individual?.value ?? 'none';

  const fieldMap = {
    [EFieldBlock.BUSINESS_STRUCTURE]: {
      [EFieldName.COUNTRY]: {
        fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
        fieldName: EFieldName.COUNTRY,
      },
      [EFieldName.ADDRESS_LINE_1]: {
        fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
        fieldName: EFieldName.ADDRESS_LINE_1,
      },
      [EFieldName.ADDRESS_LINE_2]: {
        fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
        fieldName: EFieldName.ADDRESS_LINE_2,
      },
      [EFieldName.TOWN_OR_CITY]: {
        fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
        fieldName: EFieldName.TOWN_OR_CITY,
      },
      [EFieldName.POSTAL_CODE]: {
        fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
        fieldName: EFieldName.POSTAL_CODE,
      },
      [EFieldName.TYPE_OF_BUSINESS]: {
        fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
        fieldName: EFieldName.TYPE_OF_BUSINESS,
      },
      [EFieldName.BUSINESS_TYPE]: {
        fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
        fieldName: EFieldName.BUSINESS_TYPE,
      },
      [EFieldName.TAXATION_SYSTEM]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.TAXATION_SYSTEM,
      },
      [EFieldName.LEGAL_ADDRESS]: {
        fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
        fieldName: EFieldName.LEGAL_ADDRESS,
      },
      [EFieldName.ACTUAL_ADDRESS]: {
        fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
        fieldName: EFieldName.ACTUAL_ADDRESS,
      },
    },
    [EFieldBlock.BUSINESS_REPRESENTATIVE]: {
      [EFieldName.FIRST_NAME]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.FIRST_NAME,
      },
      [EFieldName.LAST_NAME]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.LAST_NAME,
      },
      [EFieldName.MIDDLE_NAME]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.MIDDLE_NAME,
      },
      [EFieldName.IDENTIFICATION_NUMBER]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.IDENTIFICATION_NUMBER,
      },
      [EFieldName.JOB_TITLE]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.JOB_TITLE,
      },
      [EFieldName.REPRESENTATIVE]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.REPRESENTATIVE,
      },
      [EFieldName.EMAIL]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.EMAIL,
      },
      [EFieldName.DATE_OF_BIRTH]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.DATE_OF_BIRTH,
      },
      [EFieldName.PHONE_NUMBER]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.PHONE_NUMBER,
      },
      [EFieldName.REPRESENTATIVE]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.REPRESENTATIVE,
      },
      [EFieldName.DOCUMENT]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.DOCUMENT,
      },
      [EFieldName.DOCUMENT_COMPANY]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.DOCUMENT_COMPANY,
      },
      [EFieldName.DOCUMENT_INDIVIDUAL_ENTREPRENEUR]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.DOCUMENT_INDIVIDUAL_ENTREPRENEUR,
      },
      [EFieldName.DOCUMENT_SNILS]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.DOCUMENT_SNILS,
      },
      [EFieldName.DOCUMENT_PASSPORT]: {
        fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
        fieldName: EFieldName.DOCUMENT_PASSPORT,
      },
    },
    [EFieldBlock.BUSINESS_DETAILS]: {
      [EFieldName.INDIVIDUAL]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.INDIVIDUAL,
      },
      [EFieldName.LEGAL_BUSINESS_NAME]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.LEGAL_BUSINESS_NAME,
      },
      [EFieldName.TAX_IDENTIFICATION_CODE]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.TAX_IDENTIFICATION_CODE,
      },
      [EFieldName.DOING_BUSINESS_AS]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.DOING_BUSINESS_AS,
      },
      [EFieldName.BUSINESS_WEBSITE]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.BUSINESS_WEBSITE,
      },
      [EFieldName.PRODUCT_DESCRIPTION]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.PRODUCT_DESCRIPTION,
      },
      [EFieldName.TAXATION_SYSTEM]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.TAXATION_SYSTEM,
      },
      [EFieldName.KPP]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.KPP,
      },
      [EFieldName.OGRN]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.OGRN,
      },
      [EFieldName.DOCUMENT_OGRNIP]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.DOCUMENT_OGRNIP,
      },
      [EFieldName.PRODUCT_TAX_CATEGORY]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.PRODUCT_TAX_CATEGORY,
      },
      [EFieldName.PASSPORT]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.PASSPORT,
      },
      [EFieldName.PASSPORT]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.PASSPORT,
      },
      [EFieldName.PASSPORT_DATE]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.PASSPORT_DATE,
      },
      [EFieldName.PASSPORT_CODE]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.PASSPORT_CODE,
      },
      [EFieldName.SNILS]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.SNILS,
      },
      [EFieldName.SNILS]: {
        fieldBlock: EFieldBlock.BUSINESS_DETAILS,
        fieldName: EFieldName.SNILS,
      },
    },
    [EFieldBlock.SUPPORT_DETAILS]: {
      [EFieldName.PHONE_NUMBER]: {
        fieldBlock: EFieldBlock.SUPPORT_DETAILS,
        fieldName: EFieldName.PHONE_NUMBER,
      },
      [EFieldName.EMAIL]: {
        fieldBlock: EFieldBlock.SUPPORT_DETAILS,
        fieldName: EFieldName.EMAIL,
      },
    },
    [EFieldBlock.ACCOUNT_FOR_PAYOUTS]: {
      [EFieldName.CURRENCY]: {
        fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
        fieldName: EFieldName.CURRENCY,
      },
      [EFieldName.COUNTRY]: {
        fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
        fieldName: EFieldName.COUNTRY,
      },
      [EFieldName.BANK_NAME]: {
        fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
        fieldName: EFieldName.BANK_NAME,
      },
      [EFieldName.IBAN]: {
        fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
        fieldName: EFieldName.IBAN,
      },
      [EFieldName.VAT_NUMBER]: {
        fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
        fieldName: EFieldName.VAT_NUMBER,
      },
      [EFieldName.SWIFT]: {
        fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
        fieldName: EFieldName.SWIFT,
      },
      [EFieldName.BIK]: {
        fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
        fieldName: EFieldName.BIK,
      },
      [EFieldName.RS]: {
        fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
        fieldName: EFieldName.RS,
      },
      [EFieldName.KS]: {
        fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
        fieldName: EFieldName.KS,
      },
    },
    [EFieldBlock.TAX_CALCULATION]: {
      [EFieldName.TAX_CALCULATION]: {
        fieldBlock: EFieldBlock.TAX_CALCULATION,
        fieldName: EFieldName.TAX_CALCULATION,
      },
    },
  };

  const businessInfoTabConfig = {
    [EBusinessType.COMPANY]: {
      title: localization.businessInfo.tab,
      items: [
        fieldMap.businessStructure.country,
        fieldMap.businessStructure.addressLine1,
        fieldMap.businessStructure.addressLine2,
        fieldMap.businessStructure.townOrCity,
        fieldMap.businessStructure.postalCode,
        fieldMap.businessStructure.typeOfBusiness,
        fieldMap.businessRepresentative.firstName,
        fieldMap.businessRepresentative.lastName,
        fieldMap.businessRepresentative.jobTitle,
        fieldMap.businessRepresentative.email,
        fieldMap.businessRepresentative.dateOfBirth,
        fieldMap.businessRepresentative.phoneNumber,
        fieldMap.businessDetails.legalBusinessName,
        fieldMap.businessDetails.taxIdentificationCode,
        fieldMap.businessDetails.doingBusinessAs,
        fieldMap.businessDetails.businessWebsite,
        fieldMap.businessDetails.productDescription,
      ],
    },
    [EBusinessType.INDIVIDUAL]: {
      title: localization.businessInfo.tab,
      items: [
        fieldMap.businessStructure.country,
        fieldMap.businessStructure.addressLine1,
        fieldMap.businessStructure.addressLine2,
        fieldMap.businessStructure.townOrCity,
        fieldMap.businessStructure.postalCode,
        fieldMap.businessStructure.typeOfBusiness,
        fieldMap.businessRepresentative.firstName,
        fieldMap.businessRepresentative.lastName,
        fieldMap.businessRepresentative.identificationNumber,
        fieldMap.businessRepresentative.email,
        fieldMap.businessRepresentative.dateOfBirth,
        fieldMap.businessRepresentative.phoneNumber,
        fieldMap.businessDetails.individual,
        fieldMap.businessDetails.doingBusinessAs,
        fieldMap.businessDetails.businessWebsite,
        fieldMap.businessDetails.productDescription,
      ],
    },
  };

  const supportTabConfig = {
    title: localization.support.tab,
    items: [
      fieldMap.supportDetails.phoneNumber,
      fieldMap.supportDetails.email,
    ],
  };

  const bankTabConfig = {
    [EBusinessType.COMPANY]: {
      title: localization.bank.tab,
      items: [
        fieldMap.accountForPayouts.currency,
        fieldMap.accountForPayouts.country,
        fieldMap.accountForPayouts.bankName,
        fieldMap.accountForPayouts.iban,
        fieldMap.accountForPayouts.vatNumber,
        fieldMap.accountForPayouts.swift,
      ],
    },
    [EBusinessType.INDIVIDUAL]: {
      title: localization.bank.tab,
      items: [
        fieldMap.accountForPayouts.currency,
        fieldMap.accountForPayouts.country,
        fieldMap.accountForPayouts.bankName,
        fieldMap.accountForPayouts.iban,
        fieldMap.accountForPayouts.swift,
      ],
    },
    [EBusinessType.INDIVIDUAL_ENTREPRENEUR]: {
      title: localization.bank.tab,
      items: [
        fieldMap.accountForPayouts.currency,
        fieldMap.accountForPayouts.country,
        fieldMap.accountForPayouts.bankName,
        fieldMap.accountForPayouts.iban,
        fieldMap.accountForPayouts.vatNumber,
        fieldMap.accountForPayouts.swift,
      ],
    },
    [EBusinessType.SELF_EMPLOYED]: {
      title: localization.bank.tab,
      items: [
        fieldMap.accountForPayouts.currency,
        fieldMap.accountForPayouts.country,
        fieldMap.accountForPayouts.bankName,
        fieldMap.accountForPayouts.iban,
        fieldMap.accountForPayouts.swift,
      ],
    },
  };

  const taxCalculationTabConfig = {
    title: localization.taxCalculation.tab,
    items: [
      fieldMap.taxCalculation.taxCalculation,
    ],
  };

  const blockConfigMap = {
    [EBusinessType.COMPANY]: {
      none: [
        businessInfoTabConfig[EBusinessType.COMPANY],
        supportTabConfig,
        bankTabConfig[EBusinessType.COMPANY],
        taxCalculationTabConfig,
      ],
    },
    [EBusinessType.INDIVIDUAL]: {
      [EBusinessType.INDIVIDUAL]: [
        businessInfoTabConfig[EBusinessType.INDIVIDUAL],
        supportTabConfig,
        bankTabConfig[EBusinessType.INDIVIDUAL],
        taxCalculationTabConfig,
      ],
      [EBusinessType.INDIVIDUAL_ENTREPRENEUR]: [
        businessInfoTabConfig[EBusinessType.INDIVIDUAL],
        supportTabConfig,
        bankTabConfig[EBusinessType.INDIVIDUAL_ENTREPRENEUR],
        taxCalculationTabConfig,
      ],
      [EBusinessType.SELF_EMPLOYED]: [
        businessInfoTabConfig[EBusinessType.INDIVIDUAL],
        supportTabConfig,
        bankTabConfig[EBusinessType.SELF_EMPLOYED],
        taxCalculationTabConfig,
      ],
    },
  };

  return blockConfigMap[typeOfBusiness][subtypeOfBusiness];
}

function mapFormDataField(fieldName, value, locale) {
  const dateFields = [
    'businessRepresentative.dateOfBirth',
  ];

  const withValuesFields = [
    'businessStructure.typeOfBusiness',
    'businessDetails.taxationSystem',
    'businessDetails.individual',
    'accountForPayouts.currency',
    'taxCalculation.taxCalculation',
  ];

  let mappedValue = value;
  if (withValuesFields.includes(fieldName)) {
    mappedValue = mappedValue.title;
  } else if (dateFields.includes(fieldName)) {
    if (locale === 'ru') {
      mappedValue = moment(mappedValue).locale('ru').format('MMM DD, YYYY');
      mappedValue = mappedValue.charAt(0).toUpperCase() + mappedValue.slice(1);
    } else {
      mappedValue = moment(mappedValue).format('MMM DD, YYYY');
    }
  }

  return mappedValue?.title ?? mappedValue;
}

function getBlockData(
  localisationRegistry: ILocalisationRegistry,
  countryLocalisation: ICountryLocalisation,
  locale,
  merchantAccountInfo,
): ICInfoBlock[] {
  if (!merchantAccountInfo?.businessStructure?.typeOfBusiness) {
    return [];
  }

  /* @TODO переделать типы бизнеса.
       Костыль для обратной совместимости после переноса ИП в тип бизнеса "company" на стороне Allsoft */
  const individual = merchantAccountInfo.businessDetails.organizationalLegalFormOfBusiness;
  if (individual === EIndividual.INDIVIDUAL_ENTREPRENEUR) {
    merchantAccountInfo.businessStructure.typeOfBusiness = ETypeOfBusiness.INDIVIDUAL;
  }

  /* @TODO костыль для обратной совместимости после переименования в API Allsoft
        поля "individual" в "organizational_legal_form_of_business" */
  merchantAccountInfo.businessDetails.individual = individual;

  const localization = localisationRegistry.accountActivate;

  const typeOfBusinessList = getTypeOfBusinessList(localization);
  const individualList = getIndividualList(localization);
  const taxCalculationList = getTaxCalculationList(localization);
  const taxationSystemList = getTaxationSystemList(localization);
  const businessRepresentativeList = getBusinessRepresentativeList(localization);

  const typeOfBusiness = merchantAccountInfo.businessStructure.typeOfBusiness;

  const businessType = getBusinessType(
    localization,
    typeOfBusiness,
    individual,
  );

  const blocksConfig = getBlockConfig(
    localisationRegistry,
    businessType,
  );

  const currencyList: ICurrency[] = [
    {
      id: 1,
      title: localization.bank.currency.buttons.eur,
      value: ECurrency.EUR,
    },
    {
      id: 2,
      title: localization.bank.currency.buttons.usd,
      value: ECurrency.USD,
    },
    {
      id: 3,
      title: localization.bank.currency.buttons.rub,
      value: ECurrency.RUB,
    },
  ];

  const countryFields = [
    'businessStructure.country',
    'accountForPayouts.country',
  ];

  const documentsFields = [
    'businessRepresentative.documentCompany',
    'businessRepresentative.documentIndividualEntrepreneur',
    'businessRepresentative.documentSnils',
    'businessRepresentative.documentPassport',
  ];

  const fieldsWithEmptyFormData = [
    ...documentsFields,
    'businessStructure.businessType',
  ];

  return blocksConfig.reduce((infoBlocks: ICInfoBlock[], block) => {
    const items = block.items.reduce((items, item) => {
      const key = `${item.fieldBlock}.${item.fieldName}`;

      if (key === 'businessRepresentative.document'
        && merchantAccountInfo.businessRepresentative.representative === EBusinessRepresentative.HEADER
      ) {
        return items;
      }

      const field: ICInfoBlockItem = {
        title: readSummaryFieldTitle(
          localization,
          item.fieldBlock,
          item.fieldName,
          businessType,
        ),
        value: '-',
      };

      let formDataValue = merchantAccountInfo[item.fieldBlock][item.fieldName];

      if (!isEmpty(formDataValue)) {
        if (countryFields.includes(key)) {
          formDataValue = countryLocalisation[formDataValue];
        }
        if (key === 'businessStructure.typeOfBusiness') {
          formDataValue = Object.values(typeOfBusinessList)
            .find((typeOfBusiness) => typeOfBusiness.value === formDataValue);
        }
        if (key === 'businessDetails.individual') {
          formDataValue = Object.values(individualList)
            .find((individualItem) => individualItem.value === formDataValue);
        }
        if (key === 'businessDetails.taxationSystem') {
          formDataValue = Object.values(taxationSystemList)
            .find((taxationSystem) => taxationSystem.id === formDataValue);
        }
        if (key === 'businessRepresentative.representative') {
          formDataValue = Object.values(businessRepresentativeList)
            .find((businessRepresentative) => businessRepresentative.id === formDataValue);
        }
        if (key === 'accountForPayouts.currency') {
          formDataValue = Object.values(currencyList)
            .find((currency) => currency.value === formDataValue);
        }
        if (key === 'taxCalculation.taxCalculation') {
          formDataValue = Object.values(taxCalculationList)
            .find((taxCalculation) => taxCalculation.value === formDataValue);
        }
        if (key === 'businessDetails.productTaxCategory') {
          field.type = EFormFieldType.CHECKBOX_GROUP;

          formDataValue = Object.keys(formDataValue)
            .filter((productTaxCategory) => formDataValue[productTaxCategory])
            .map((category) => localization.businessInfo.productTaxCategory[category].title);
        }

        try {
          field.value = mapFormDataField(key, formDataValue, locale);
        } catch (e) {
          field.value = '-';
        }
      } else if (fieldsWithEmptyFormData.includes(key)) {
        if (key === 'businessStructure.businessType') {
          field.value = businessType.title;
        }
        if (documentsFields.includes(key)) {
          const documents = merchantAccountInfo.documents ?? [];

          const formField: IFormField<EFieldName, EFieldBlock> = {
            fieldBlock: item.fieldBlock,
            fieldName: item.fieldName,
          };
          const fileList = documents.reduce((fileList, document) => {
            if (document.file_type === getFileType(formField)) {
              const file: IFile = {
                status: EFileStatus.SUCCESS,
                fileName: document.file_name,
                fileModel: {
                  s3ObjectKey: document.s3_object_key,
                  fileName: document.file_name,
                  fileType: document.file_type,
                },
                errors: [],
              };

              fileList.push(file);
            }

            return fileList;
          }, [] as IFile[]);

          if (fileList.length > 0) {
            field.value = fileList;
            field.type = EFormFieldType.FILE_UPLOAD;
          }
        }
      }

      items.push(field);

      return items;
    }, []);

    if (items.length > 0) {
      infoBlocks.push({
        title: block.title,
        data: items,
      });
    }

    return infoBlocks;
  }, [] as ICInfoBlock[]);
}

export default getBlockData;

export {
  initCountryLocalisation,
};

import AdminLoginPage from '@/module/admin/view/AdminLoginPage.vue';
import AdminPage from '@/module/admin/view/AdminPage.vue';
import ERouteName from '@/module/common/models/router/ERouteName';

export default () => [
  {
    path: '/adminLogin',
    name: ERouteName.ADMIN_LOGIN,
    component: AdminLoginPage,
    meta: { title: 'titles.admin_login' },
  },
  {
    path: '/admin',
    name: ERouteName.ADMIN,
    component: AdminPage,
    meta: { title: 'titles.admin' },
  },
];

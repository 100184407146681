
import {
  computed,
  defineComponent,
  inject,
  PropType,
} from 'vue';
import IVueInstance from '@/module/common/models/IVueInstance';

import getLocalisation from '@/module/common/helpers/getLocalisation';
import {
  ILocalisationMerchantList,
  IMerchant, mapMerchantList,
  rootLocalisationKey,
} from '@/module/admin/view/AdminPage.func';

import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import DSTag from '@/module/design-system/components/InfoBlock/DSTag.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default defineComponent({
  name: 'MerchantList',
  components: {
    ButtonMore,
    DSTag,
    DSTextElement,
  },
  props: {
    merchants: {
      type: Array as PropType<IMerchant[]>,
      default: () => [],
    },
  },
  setup(props) {
    const vueInstance = inject<IVueInstance>('vueInstance');
    const api = inject<{ admin }>('api');

    const localisation = getLocalisation<ILocalisationMerchantList>(rootLocalisationKey);
    const adminPageLocalisation = getLocalisation('pages.admin');

    const merchantsList = computed(() => mapMerchantList(
      vueInstance,
      api,
      localisation,
      adminPageLocalisation,
      props.merchants,
    ));

    return {
      merchantsList,
    };
  },
});

import axios from 'axios';

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    cancellingRequestToken: axios.CancelToken.source(),
    filterOptions: {
      offset: 0,
      limit: 20,
      searchString: '',
      status: 'all',
      sortBy: 'desc',
      salesArea: null,
    },
    isUpdating: false,
    isRequestRunning: true,
    isLoading: true,
    responseStatus: 200,
    totalCount: 0,
    merchants: [],
    isInitialRequest: true,
    scrollPosition: 0,
  },
  mutations: {
    setFilterOption(state, { optionName, newValue }) {
      state.filterOptions[optionName] = newValue;
    },
    setMerchants(state, newMerchants) {
      state.merchants = newMerchants;
    },
    setRequestRunning(state, value) {
      state.isRequestRunning = value;
    },
    setStatus(state, status) {
      state.responseStatus = status;
    },
    setTotalCount(state, value) {
      state.totalCount = value;
    },
    setCancelToken(state) {
      state.cancellingRequestToken = axios.CancelToken.source();
    },
    setFieldValue(state, { fieldName, value }) {
      state[fieldName] = value;
    },
    setMerchantStatus(state, value) {
      state.merchants.forEach((merchant) => {
        if (merchant.id === value) {
          merchant.isActivated = !merchant.isActivated;
        }
      });
    },
  },
  getters: {
    getMerchants(state) {
      return state.merchants;
    },
    getTotalCount(state) {
      return state.totalCount;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
    getIsUpdating(state) {
      return state.isUpdating;
    },
    getIsRequestRunning(state) {
      return state.isRequestRunning;
    },
    getIsInitialRequest(state) {
      return state.isInitialRequest;
    },
    getScrollPosition(state) {
      return state.scrollPosition;
    },
    getFilterOptions(state) {
      return state.filterOptions;
    },
  },
  actions: {
    getScrollPosition({ state }, scrollPosition) {
      state.scrollPosition = scrollPosition;
    },
    async getMerchantsList({ state, commit, getters }, isOverwrite) {
      const api = serviceContainer.resolve('api');

      if (isOverwrite) {
        commit('setFilterOption', { optionName: 'offset', newValue: 0 });
        commit('setFieldValue', { fieldName: 'isUpdating', value: true });
      }
      if (state.isRequestRunning) {
        state.cancellingRequestToken.cancel('userCancel');
      }

      commit('setRequestRunning', true);
      commit('setCancelToken');
      try {
        const { status, data } = await api.admin.list(
          getters.getFilterOptions,
          state.cancellingRequestToken.token,
        );

        commit('setTotalCount', data.totalCount);

        const defaultOffset = 20;
        if (isOverwrite) {
          commit('setMerchants', data.vendors);
        } else {
          const concatedMerchants = state.merchants.concat(data.vendors);
          commit('setMerchants', concatedMerchants);
        }

        commit('setStatus', status);
        commit('setFilterOption', { optionName: 'offset', newValue: state.filterOptions.offset + defaultOffset });
        commit('setRequestRunning', false);
        commit('setFieldValue', { fieldName: 'isLoading', value: false });
        commit('setFieldValue', { fieldName: 'isUpdating', value: false });
        commit('setFieldValue', { fieldName: 'isInitialRequest', value: false });
        return { isError: false };
      } catch (error) {
        if (error.message === 'userCancel') {
          return { isError: false };
        }
        commit('setStatus', error.response.status);
        commit('setRequestRunning', false);
        commit('setFieldValue', { fieldName: 'isLoading', value: false });
        commit('setFieldValue', { fieldName: 'isUpdating', value: false });
        commit('setFieldValue', { fieldName: 'isInitialRequest', value: false });
        return { isError: true };
      }
    },

    updateMerchantStatus({ commit }, data) {
      commit('setMerchantStatus', data);
    },
    updateFilterOptions({ commit }, option) {
      commit('setFilterOption', { optionName: option.name, newValue: option.value });
    },
  },
});

import IBasePageLocalisation from '@/module/common/utils/localisation/models/IBasePageLocalisation';
import EMerchantStatus from '@/module/common/models/EMerchantStatus';
import IDSTag from '@/module/design-system/components/models/IDSTag';

interface IMerchant {
  id: number;
  email: string;
  roles: Array<string>;
  fullName: string;
  isActivated: boolean;
  createdAt: string;
  status: EMerchantStatus;
  salesUid: number;
  testUid: number;
}

interface IMerchantItem {
  email: string,
  uid: string,
  status: EMerchantStatus,
  createdAt: string,
  isActivated: boolean,
  tags: IDSTag[],
  buttons: {
    name: string,
    handler: () => void,
  }[],
}

const rootLocalisationKey = 'pages.admin';
interface ILocalisationMerchantList extends IBasePageLocalisation {
  'title': string;
  'filters': {
    'search': {
      'label': string;
      'placeholder': string;
    },
    'status': {
      'label': string;
      'all': string;
      'test': string;
      'need_approve': string;
      'sales': string;
    },
    'sort_by': {
      'label': string;
      'asc': string;
      'desc': string;
    }
  },
  'status': {
    'test': string;
    'need_approve': string;
    'sales': string;
  }
}

function handleAuthorization(vueInstance, merchant) {
  vueInstance?.$store?.dispatch(
    'authStore/doMerchantLogin',
    merchant.id,
  );
}

async function handleToggleActivation(vueInstance, api, merchant) {
  const response = await api?.admin.update(merchant.id, {
    changeFields: {
      isActivated: !merchant.isActivated,
    },
  });
  if (response.status === 204) {
    vueInstance?.$store?.dispatch(
      'adminStore/updateMerchantStatus',
      merchant.id,
    );
  }
}

function mapMerchantList(
  vueInstance,
  api,
  localisation,
  adminPageLocalisation,
  merchants,
): IMerchantItem[] {
  return merchants?.map((merchantItem) => {
    let uid = `${localisation.merchant.uid.test}: ${merchantItem.testUid}`;

    if (merchantItem.salesUid) {
      uid += ` • ${localisation.merchant.uid.sales}: ${merchantItem.salesUid}`;
    }

    return {
      email: merchantItem.email,
      uid,
      status: merchantItem.status,
      createdAt: merchantItem.createdAt,
      isActivated: merchantItem.isActivated,
      tags: [
        {
          color: merchantItem.status === EMerchantStatus.SALES ? '#6f43cb' : '#4397cb',
          backgroundColor: merchantItem.status === EMerchantStatus.SALES ? '#f1ebff' : '#ebf7ff',
          text: localisation.merchant.status[merchantItem.status],
        },
      ],
      buttons: [
        {
          name: 'merchants.login',
          handler: () => handleAuthorization(vueInstance, merchantItem),
        },
        {
          name: merchantItem.isActivated ? 'merchants.deactivation' : 'merchants.activation',
          handler: () => handleToggleActivation(vueInstance, api, merchantItem),
        },
      ],
    };
  });
}

export {
  IMerchant,
  IMerchantItem,
  rootLocalisationKey,
  ILocalisationMerchantList,
  handleAuthorization,
  handleToggleActivation,
  mapMerchantList,
};

<template>
  <div
    id="app"
    class="app-layout"
  >
    <router-view
      name="header"
      class="app-layout__header"
    />
    <router-view
      name="sidebar"
      class="app-layout__sidebar"
    />
    <router-view
      name="mobileSidebar"
      class="app-layout__mobile-sidebar"
    />
    <perfect-scrollbar
      ref="layout"
      class="app-layout__content"
      @ps-scroll-y="handleScroll"
    >
      <div
        class="app-layout__notifications"
      >
        <CNotificationsList
          :list="notifications"
          @delete-notify="handleDeleteNotification"
        />
      </div>

      <router-view :key="$route.path" />

      <CFloatingElement
        v-if="scrollPosition > 1"
        position-x="left"
      >
        <ScrollTopButton
          :handle-scroll-to-zero="handleScrollToZero"
        />
      </CFloatingElement>

      <CFloatingElement
        v-if="isHelpWidgetShown"
      >
        <CHelpWidget />
      </CFloatingElement>
    </perfect-scrollbar>
    <portal-target name="modal" />
    <OverlayComponent v-if="isDeleteModalOpened">
      <DeleteModal
        v-if="isDeleteModalOpened"
      />
    </OverlayComponent>
  </div>
</template>

<script>
import {
  inject,
} from 'vue';
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';

import getSidebarConfig from '@/constants/index';
import DeleteModal from '@/components/DeleteModal.vue';
import CFloatingElement from '@/module/common/components/PageParts/CFloatingElement.vue';
import CHelpWidget from '@/module/common/components/CHelpWidget.vue';
import OverlayComponent from '@/components/OverlayComponent.vue';
import ScrollTopButton from '@/components/ScrollTopButton.vue';
import CNotificationsList from '@/module/common/components/UI/CNotificationsList.vue';

export default {
  name: 'App',
  components: {
    CNotificationsList,
    DeleteModal,
    CFloatingElement,
    CHelpWidget,
    OverlayComponent,
    ScrollTopButton,
  },
  data() {
    return {
      scrollPosition: 0,
    };
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const localisationRegistry = serviceContainer.localisationRegistry;
    const linkList = getSidebarConfig(serviceContainer);

    return {
      linkList,
      localisationRegistry,
    };
  },
  computed: {
    ...mapState({
      isDeleteModalOpened: (state) => state.deleteModalStore.isOpened,
      isPopUpOpened: (state) => state.popupStore.isOpened,
    }),

    ...mapGetters({
      notifications: 'notificationsStore/getNotifications',
      isMenuMinimized: 'commonStatusesStore/getIsMenuMinimized',
    }),
    isHelpWidgetShown() {
      const excludedRouts = [
        'PrivacyNotice',
        'CookiePolicy',
        'AdminLogin',
        'Admin',
        'Login',
      ];

      return !excludedRouts.includes(this.$route.name);
    },
  },
  async mounted() {
    this.initScrollHostRef(this.$refs.layout);

    window.addEventListener('resize', this.handleResize);
    window.dispatchEvent(new Event('resize'));
  },
  methods: {
    ...mapMutations({
      initScrollHostRef: 'initScrollHostRef',
      changeTryAgainHandler: 'popupStore/changeTryAgainHandler',
    }),
    ...mapActions({
      getScrollPositionProducts: 'productListStore/getScrollPosition',
      getScrollPositionMerchants: 'adminStore/getScrollPosition',
      updateCurrentBreakpoint: 'commonStatusesStore/updateCurrentBreakpoint',
      deleteNotification: 'notificationsStore/deleteNotification',
      changeLocalisationRegistry: 'changeLocalisationRegistry',
    }),
    handleScroll(e) {
      const event = new CustomEvent('scroll', { detail: { next: e, ref: this.$refs.layout } });
      window.dispatchEvent(event);
      this.scrollPosition = e.target.scrollTop;
    },
    handleScrollToZero() {
      this.scrollPosition = 0;
      this.$refs.layout.$el.scrollTo({ top: 0 });
    },
    handleResize() {
      let currentBreakpoint = 'xxl';

      if (window.innerWidth <= 440) {
        currentBreakpoint = 'xs';
      } else if (window.innerWidth <= 670) {
        currentBreakpoint = 's';
      } else if (window.innerWidth <= 768) {
        currentBreakpoint = 'm';
      } else if (window.innerWidth <= 992) {
        currentBreakpoint = 'l';
      } else if (window.innerWidth <= 1200) {
        currentBreakpoint = 'xl';
      }

      this.updateCurrentBreakpoint(currentBreakpoint);
    },
    handleDeleteNotification(notification) {
      this.deleteNotification(notification);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import '@/module/common/components/variables.scss';

.app-layout {
  display: grid;
  overflow: hidden;
  height: 100vh;
  min-height: 100vh;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;

  .ps__rail-y,
  .ps__rail-x {
    pointer-events: none;
  }

  .ps__rail-y {
    z-index: 30;
  }

  &__header {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }

  &__sidebar {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  &__content {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  &__notifications {
    position: fixed;
    top: 96px;
    right: 32px;
    z-index: 10001;
  }

  &__global-tooltip {
    z-index: 29;
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__sidebar {
      display: none;
    }

    &__mobile-sidebar {
      position: fixed;
      z-index: 6;
      display: flex;
      width: 100%;
      height: 100%;
      padding-top: 63px;
      transition: visibility 0.25s, background-color 0.2s linear;
    }

    &__content {
      padding-bottom: 80px;
    }

    .notifications {
      right: 50%;
      transform: translateX(50%);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    .ps__rail-y,
    .ps__rail-x {
      pointer-events: auto;
    }
  }
}
</style>

import IProductFromPage from '@/module/product/view/ProductFormPage/service/contract/IProductFromPage';
import IState from '@/module/product/view/ProductFormPage/model/IState';
import { reactive } from 'vue';
import IHandlerService from '@/module/product/view/ProductFormPage/service/contract/IHandlerService';
import HandlerService from '@/module/product/view/ProductFormPage/service/HandlerService';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IFormConfigBlock from '@/module/design-system/components/models/FormComponent/IFormConfigBlock';
import EFieldName from '@/module/product/view/ProductFormPage/model/EFieldName';
import EAvailableFor from '@/module/product/model/Product/Fields/AvailableFor/EAvailableFor';
import ELicenseType from '@/module/product/model/Product/Fields/LicenseType/ELicenseType';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import ECalendarSymbol from '@/module/product/model/ECalendarSymbol';
import ITermOption from '@/module/product/view/ProductFormPage/model/ITermOption';
import ScenarioService from '@/module/product/view/ProductFormPage/service/ScenarioService';
import IScenarioService from '@/module/product/view/ProductFormPage/service/contract/IScenarioService';
import TFormDataMap from '@/module/product/model/FormData/TFormDataMap';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import TValidationStatus from '@/module/product/view/ProductFormPage/model/TValidationStatus';

class ProductFormPage implements IProductFromPage {
  private state?: IState;

  private readonly serviceContainer: IServiceContainer;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private _handlerService: IHandlerService;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private _fieldBlurScenarioService: IScenarioService;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  private initState = (): IState => {
    const moduleProduct = this.serviceContainer.moduleProduct;
    const portalModeService = this.serviceContainer.portalModeService;
    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const availableForService = moduleProduct.availableForService;
    const licenseTypeService = moduleProduct.licenseTypeService;
    const licenseTermService = moduleProduct.termService;
    const pricingModelService = moduleProduct.pricingModelService;
    const currencyList = portalModeService.getProductFormPageHandler().getPriceCurrencyList()
      .map((currency) => {
        const title = currency.toUpperCase();
        return { id: currency, title };
      });

    return {
      isLoading: true,
      isProductLoadError: false,
      isFormValid: true,
      isEditMode: false,
      iconLoadError: undefined,
      statuses: {} as TValidationStatus,
      formDataMap: {} as TFormDataMap,
      formData: {
        availableFor: availableForService.get(EAvailableFor.ALL),
        description: '',
        fulfillmentId: null,
        imageUrl: null,
        licenseTerm: {
          inputValue: 1,
          selectValue: licenseTermService.list(1)
            .find((term) => term.id === ECalendarSymbol.M) as ITermOption,
        },
        licenseType: licenseTypeService.get(ELicenseType.NEW),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        price: {
          inputValue: '',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectValue: currencyList[0],
        },
        sku: '',
        pricingModel: pricingModelService.get(EPricingModelName.ONE_PRICE),
        renewal: false,
        renewalSubtitle: '',
        renewalTerm: {
          inputValue: 1,
          selectValue: licenseTermService.list(1)
            .find((term) => term.id === ECalendarSymbol.M) as ITermOption,
        },
        renewalTitle: '',
        subtitle: '',
        title: '',
        trial: false,
        trialSku: '',
        trialSubtitle: '',
        trialTerm: {
          inputValue: 1,
          selectValue: licenseTermService.list(1)
            .find((term) => term.id === ECalendarSymbol.M) as ITermOption,
        },
        trialTitle: '',
      },
      formConfig: [] as IFormConfigBlock<EFieldName, undefined>[],
      softwareRegistryLoading: false,
      blockRegistryDataSwitcher: false,
      softwareRegistryModal: null,
      tmpSoftwareRegistry: {
        link: '',
        number: '',
      },
      softwareRegistryModalAlert: null,
      softwareRegistrySwitcherAlert: null,
      softwareRegistrySwitcher: {
        label: localisationRegistry.productsForm.softwareRegistry.label,
        text: localisationRegistry.productsForm.softwareRegistry.description,
        tooltip: localisationRegistry.productsForm.softwareRegistry.tooltip,
      },
      softwareRegistryStatus: {
        link: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
        number: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
      },
    };
  };

  clearState = () => {
    this.state = reactive<IState>(this.initState());
  };

  getState = (): IState => {
    if (!this.state) {
      this.state = reactive<IState>(this.initState());
    }

    return this.state;
  };

  get handlerService(): IHandlerService {
    if (!this._handlerService) {
      this._handlerService = new HandlerService(this.serviceContainer);
    }

    return this._handlerService;
  }

  get scenarioService(): IScenarioService {
    if (!this._fieldBlurScenarioService) {
      this._fieldBlurScenarioService = new ScenarioService(this.serviceContainer);
    }

    return this._fieldBlurScenarioService;
  }
}

export default ProductFormPage;

<template>
  <COverviewPageLayout
    :is-loading="isInitialRequest"
    :error-status="404"
    :show-error="isError"
  >
    <template
      #header
    >
      <COverviewPageHeaderRow
        :page-title="$t(pageTitle)"
        :tooltip-text="$t(pageTooltip)"
        class="promotions-page__header"
      >
        <p
          class="secondary promotions-page__count"
        >
          {{ $tc(totalCounterText, totalCount) }}
        </p>
        <template
          #right-side
        >
          <DSButton
            class="promotions-page__header-button"
            :text="$t('global_variables.how_it_works')"
            show-text-after-breakpoint="xl"
            variant="secondary"
            @click="handleClickToDoc"
          >
            <template #icon-before>
              <DSIcon
                icon="arrowInsideCircle"
                color="linked"
              />
            </template>
          </DSButton>

          <DSButton
            class="promotions-page__header-button"
            :text="createButtonText"
            show-text-after-breakpoint="xl"
            @click="handleCreatePromotionClick"
          >
            <template
              #icon-before
            >
              <DSIcon
                icon="plus"
                color="primary-button"
              />
            </template>
          </DSButton>
        </template>
      </COverviewPageHeaderRow>
    </template>
    <template
      #content
    >
      <InlineAlert
        v-if="merchantModeAlert.visible"
        class="promotions-page__test-alert"
        :variant="merchantModeAlert.variant"
        :title="merchantModeAlert.title"
        :subtitle="merchantModeAlert.subtitle"
      >
        <template
          #alert-controls
        >
          <button
            class="inline-alert__button"
            type="button"
            @click="handleClickCloseMerchantModeAlert"
          >
            <DSIcon
              icon="cross"
            />
          </button>
        </template>
      </InlineAlert>

      <section class="promotions-page__section promotions-page__filters">
        <SearchComponent
          :error="$t(searchError)"
          :is-loading="isLoadingSearch"
          :label="$t(searchInput.label)"
          :maxlength="255"
          :placeholder="searchInput.placeholder"
          :value="searchValue"
          class="promotions-page__filter-field promotions-page__filter-field--search"
          @update:value="handleSearchInput"
          @update:clear="handleClearInput"
        />

        <DropdownMin
          :model-value="sortByOptions.name"
          :options="sortByDropdown.options.map(option => option.name)"
          class="promotions-page__filter-field promotions-page__filter-field--sort-min"
          @update:modelValue="handleSortByChange"
        />

        <UIDropdown
          :label="$t(typeDropdown.label)"
          :model-options="typeDropdown.options"
          :model-value="typeValue"
          class="promotions-page__filter-field promotions-page__filter-field--type"
          localized
          with-radio-buttons
          @update:modelValue="handleTypeChange"
        />

        <UIDropdown
          :label="$t(statusDropdown.label)"
          :model-options="statusDropdown.options"
          :model-value="statusValue"
          class="promotions-page__filter-field promotions-page__filter-field--status"
          localized
          with-radio-buttons
          @update:modelValue="handleStatusChange"
        />

        <UIDropdown
          :label="$t(sortByDropdown.label)"
          :model-options="sortByDropdown.options"
          :model-value="sortByOptions.value"
          class="promotions-page__filter-field promotions-page__filter-field--sort"
          localized
          with-radio-buttons
          @update:modelValue="handleSortByChange"
        />
      </section>

      <section
        v-if="totalCount !== searchCount && promotionsList.length"
        class="promotions-page__section"
      >
        <p class="secondary">
          {{ $tc(searchCounterText, searchCount) }}
        </p>
      </section>

      <section
        v-if="isOverwriting"
        class="promotions-page__section promotions-page__list-preloader"
      >
        <PreloaderComponent
          height="125px"
          width="125px"
        />
      </section>

      <template v-else>
        <section
          v-if="totalCount && promotionsList.length"
          class="promotions-page__section"
        >
          <PromotionList
            :promotions-list="promotionsList"
          />

          <IntersectionObserver
            v-if="promotionsList.length < searchCount"
            @intersecting="handleLoadMore"
          >
            <PreloaderComponent
              height="25px"
              width="25px"
            />
          </IntersectionObserver>
        </section>

        <ErrorComponent
          v-else
          class="promotions-page__error-component"
        />
      </template>
    </template>
  </COverviewPageLayout>
</template>

<script>
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';
import UIDropdown from '@/components/UI/UIDropdown.vue';
import SearchComponent from '@/components/Search/SearchComponent.vue';
import DropdownMin from '@/components/Dropdown/DropdownMin.vue';
import PromotionList from '@/components/PromotionList/PromotionList.vue';
import ErrorComponent from '@/components/ErrorComponent/ErrorComponent.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import {
  getIsMerchantModeAlertVisible,
  setMerchantModeAlertStatus,
} from '@/module/common/utils/merchantModeAlert';
import {
  inject,
} from 'vue';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import debounce from '@/services/debounce';
import improvedTrim from '@/helpers/improvedTrim';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';

export default {
  name: 'PromotionsOverviewPage',
  components: {
    COverviewPageLayout,
    InlineAlert,
    COverviewPageHeaderRow,
    DSButton,
    DropdownMin,
    ErrorComponent,
    IntersectionObserver,
    PreloaderComponent,
    PromotionList,
    SearchComponent,
    UIDropdown,
    DSIcon,
  },
  data() {
    return {
      isError: null,
      // text variables
      pageTitle: 'promotions.overview.title',
      pageTooltip: 'promotions.overview.tooltip',
      totalCounterText: 'promotions.overview.total_counter',
      searchCounterText: 'promotions.overview.search_counter',
      button: {
        create: 'promotions.overview.button.create',
      },
      searchInput: {
        label: 'promotions.overview.search_input.label',
        placeholder: 'promotions.overview.search_input.placeholder',
        error: 'promotions.overview.search_input.error',
      },
      typeDropdown: {
        label: 'promotions.overview.type_dropdown.label',
        options: [
          {
            value: 'all',
            name: 'global_variables.all',
          },
          {
            value: 'coupon',
            name: 'promotions.overview.type_dropdown.option.coupon',
          },
          {
            value: 'discount',
            name: 'promotions.overview.type_dropdown.option.discount',
          },
        ],
      },
      statusDropdown: {
        label: 'promotions.overview.status_dropdown.label',
        options: [
          {
            value: 'all',
            name: 'global_variables.all',
          },
          {
            value: 'active',
            name: 'promotions.overview.status_dropdown.option.active',
          },
          {
            value: 'inactive',
            name: 'promotions.overview.status_dropdown.option.disabled',
          },
          {
            value: 'expired',
            name: 'promotions.overview.status_dropdown.option.expired',
          },
        ],
      },
      sortByDropdown: {
        label: 'promotions.overview.sort_by_dropdown.label',
        options: [
          {
            value: 'desc',
            name: 'promotions.overview.sort_by_dropdown.option.asc',
          },
          {
            value: 'asc',
            name: 'promotions.overview.sort_by_dropdown.option.desc',
          },
        ],
      },

      isLoadingSearch: false,
      searchError: '',
      merchantModeAlert: {
        visible: true,
        variant: 'accent',
        title: this.$t('pages.promotions.list.test_mode_alert.title'),
        subtitle: this.$t('pages.promotions.list.test_mode_alert.text'),
      },
    };
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const portalConfigService = serviceContainer.portalConfigService;

    const docsSiteUrl = portalConfigService.get().url.docs;

    const utils = serviceContainer.resolve('utils');

    const store = serviceContainer.resolve('store');
    const locale = store.getters.getLocale;

    const helpLink = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/merchant-portal/promotions-overview`,
      locale,
    );

    return {
      helpLink,
    };
  },
  computed: {
    ...mapGetters({
      isMenuMinimized: 'commonStatusesStore/getIsMenuMinimized',
      searchValue: 'promotionsOverviewStore/getSearchValue',
      typeValue: 'promotionsOverviewStore/getTypeValue',
      statusValue: 'promotionsOverviewStore/getStatusValue',
      sortByValue: 'promotionsOverviewStore/getSortByValue',
      totalCount: 'promotionsOverviewStore/getTotalCount',
      searchCount: 'promotionsOverviewStore/getSearchCount',
      promotionsList: 'promotionsOverviewStore/getPromotionsList',
      isInitialRequest: 'promotionsOverviewStore/getIsInitialRequest',
      isRequestRunning: 'promotionsOverviewStore/getIsRequestRunning',
      isOverwriting: 'promotionsOverviewStore/getIsOverwriting',
      merchantMode: 'authStore/getMerchantMode',
    }),
    sortByOptions() {
      return this.sortByDropdown.options.find((option) => option.value === this.sortByValue);
    },
    debouncedGetPromotionsList() {
      return debounce(async () => {
        this.isLoadingSearch = false;
        await this.getPromotionsList(true);
      }, 1500);
    },
    createButtonText() {
      return this.$t(`pages.promotions.list.buttons.create.${this.merchantMode}`);
    },
  },
  async mounted() {
    if (this.isInitialRequest) {
      await this.getPromotionsList(true);
    }

    this.merchantModeAlert.visible = getIsMerchantModeAlertVisible(
      'promotions',
      this.merchantMode,
    );
  },
  methods: {
    ...mapMutations({
      updateSearchValue: 'promotionsOverviewStore/updateSearchValue',
      updateTypeValue: 'promotionsOverviewStore/updateTypeValue',
      updateStatusValue: 'promotionsOverviewStore/updateStatusValue',
      updateSortByValue: 'promotionsOverviewStore/updateSortByValue',
    }),
    ...mapActions({
      getPromotionsList: 'promotionsOverviewStore/getPromotionsList',
    }),
    handleClickToDoc() {
      window.open(this.helpLink, '_blank');
    },
    handleCreatePromotionClick() {
      this.$router.push('/promotions/new').catch(() => null);
    },
    handleSearchInput(value) {
      this.updateSearchValue(value);

      if (improvedTrim(value).length > 2 && improvedTrim(value).length < 256) {
        this.searchError = '';
        this.isLoadingSearch = true;
        this.debouncedGetPromotionsList();
      }

      if (!improvedTrim(value).length) {
        this.handleClearInput('');
      }

      if (improvedTrim(value).length && improvedTrim(value).length < 3) {
        this.searchError = this.searchInput.error;
      }

      if (improvedTrim(value).length > 255) {
        this.searchError = ' ';
      }
    },
    handleClearInput(value) {
      this.updateSearchValue(value);
      this.getPromotionsList(true);
      this.searchError = '';
    },
    async handleTypeChange(value) {
      this.updateTypeValue(value);
      await this.getPromotionsList(true);
    },
    async handleStatusChange(value) {
      this.updateStatusValue(value);
      await this.getPromotionsList(true);
    },
    async handleSortByChange(value) {
      const newSortByValue = this.sortByDropdown.options.find((option) => option.name === value);
      if (!newSortByValue) {
        this.updateSortByValue(value);
      } else {
        this.updateSortByValue(newSortByValue.value);
      }

      await this.getPromotionsList(true);
    },
    async handleLoadMore() {
      if (this.isRequestRunning) {
        return;
      }
      await this.getPromotionsList();
    },
    handleClickCloseMerchantModeAlert() {
      this.merchantModeAlert.visible = false;
      setMerchantModeAlertStatus('promotions', 'hidden');
    },
  },
};
</script>

<style lang="scss">

.promotions-page {
  width: 100%;
  min-height: 100%;

  &__preloader {
    display: flex;
    justify-content: center;
    margin-top: 50%;
    transform: translateY(-50%);
  }

  &__list-preloader {
    display: flex;
    justify-content: center;
  }

  &__error-component {
    height: 280px;
  }

  &__header {
    margin-bottom: 22px;
  }

  &__title {
    display: flex;
    align-items: baseline;
    line-height: 48px;
  }

  &__tooltip {
    align-self: flex-end;
    margin-bottom: 7px;
    margin-left: 10px;
  }

  &__count {
    margin-bottom: 10px;
  }

  &__test-alert {
    margin: 32px 0;
  }

  &__block {
    display: flex;
    margin-left: auto;
    column-gap: 16px;
  }

  &__header-button {
    &--min {
      display: none;
    }
  }

  &__secondary-button {
    padding: 13px 13px;
  }

  &__filters {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
    gap: 12px;
  }

  &__filter-field {

    &--search {
      flex: 1 1 310px;

      .input-text__options-error {
        position: absolute;
      }
    }

    &--type,
    &--status {
      flex: 1 0 127px;
    }

    &--sort {
     flex: 1 1 210px;
    }

    &--sort-min {
      display: none;
    }
  }
}

@media screen and (max-width: 1120px) {
  .promotions-page {
    &__header-button {
      display: none;

      &--min {
        display: flex;
      }
    }

    &__filter-field {
      &--search {
        width: 70%;
      }

      &--type {
        width: calc(30% - 12px);
      }

      &--status,
      &--sort {
        width: calc((100% - 12px) / 2);
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .promotions-page {
    margin-top: 24px;

    &__title {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__count {
      display: block;
      width: 100%;
      margin-left: 0;
      margin-bottom: 0;
    }

    &__filters {
      flex-wrap: wrap;
    }

    &__filter-field {
      &--search {
        order: 1;
      }

      &--sort {
        order: 2;
      }

      &--type,
      &--status {
        order: 3;
        width: calc((100% - 12px) / 2);
        flex-basis: calc((100% - 12px) / 2);
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .promotions-page {
    &__block {
      column-gap: 8px;
    }

    &__filter-field {
      &--search {
        width: calc(100% - 60px);
        flex-basis: calc(100% - 60px);
      }

      &--sort {
        display: none;
      }

      &--sort-min {
        display: flex;
        order: 2;

        button {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .promotions-page {
    &__header {
      flex-direction: column;
      min-width: 300px;
      row-gap: 20px;
    }

    &__tooltip {
      margin-bottom: 10px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__block {
      margin-left: 0;
    }
  }
}
</style>

// eslint-disable-next-line max-len
import IDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import IUtils from '@/plugins/utils/IUtils';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import ICurrency from '@/module/merchant/models/ICurrency';
import ICountry from '@/module/merchant/models/ICountry';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
// eslint-disable-next-line max-len
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import TFormData
  from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';
import ITaxCalculation from '@/module/merchant/view/AccountActivatePage/models/ITaxCalculation';
import TFromDataDraft
  from '@/module/merchant/view/AccountActivatePage/models/Draft/TFromDataDraft';
import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import IBusinessType from '@/module/merchant/models/BuisnessType/IBusinessType';
import IFormConfigHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormConfigHandler';

class DraftToFormDataMapper implements IDataMapper {
  private readonly state: IPageState;

  private readonly serviceContainer: IServiceContainer;

  private readonly formConfigHandler: IFormConfigHandler;

  private readonly localisationRegistry: ILocalisationRegistry;

  constructor(
    state: IPageState,
    serviceContainer: IServiceContainer,
    formConfigHandler: IFormConfigHandler,
    localisationRegistry: ILocalisationRegistry,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.formConfigHandler = formConfigHandler;
    this.localisationRegistry = localisationRegistry;
  }

  mapFieldValue(
    formFieldConfigItem: IFormFieldItem<EFieldName, EFieldBlock>,
    data: TFormData | TFromDataDraft,
  ) {
    const utils = this.serviceContainer.resolve<IUtils>(EServiceContainerInstances.UTILS);

    const { fieldBlock, fieldName, type } = formFieldConfigItem;

    const draftValue = this.state.formDraft[fieldBlock]?.[fieldName];

    if (['Invalid Date', undefined, null].includes(draftValue as never)) {
      return data;
    }

    let value = draftValue;
    if (type === EFormFieldType.DATE) {
      value = utils.date.getDateObject(value as string);
    } else if (
      fieldBlock === EFieldBlock.BUSINESS_STRUCTURE
      && fieldName === EFieldName.BUSINESS_TYPE
    ) {
      const list = this.formConfigHandler.getBusinessTypes(
        this.localisationRegistry.accountActivate,
      ).filter((item) => [EBusinessType.COMPANY, EBusinessType.INDIVIDUAL].includes(item.id));

      const firstIterationValue = list.find((item) => item.id === (value as IBusinessType).id);
      value = firstIterationValue ?? list.find(
        (item) => item.typeOfBusiness.id === (value as IBusinessType)?.typeOfBusiness?.id,
      );
    } else if (
      fieldBlock === EFieldBlock.BUSINESS_DETAILS
      && fieldName === EFieldName.INDIVIDUAL
    ) {
      const list = this.formConfigHandler.getBusinessTypes(
        this.localisationRegistry.accountActivate,
      );

      value = list.find((item) => item.individual?.id === (value as IBusinessType).individual?.id);
    } else if (
      fieldBlock === EFieldBlock.ACCOUNT_FOR_PAYOUTS
      && fieldName === EFieldName.CURRENCY
    ) {
      const list = this.formConfigHandler.getCurrencies(
        this.localisationRegistry.accountActivate,
      );
      value = list.find((item) => item.id === (value as ICurrency).id);
    } else if (
      fieldBlock === EFieldBlock.TAX_CALCULATION
      && fieldName === EFieldName.TAX_CALCULATION
    ) {
      const list = this.formConfigHandler.getTaxCalculationList(this.localisationRegistry.accountActivate);
      value = Object.values(list).find((item) => item.id === (value as ITaxCalculation).id);
    } else if (
      [
        EFieldBlock.BUSINESS_STRUCTURE,
        EFieldBlock.ACCOUNT_FOR_PAYOUTS,
      ].includes(fieldBlock)
      && fieldName === EFieldName.COUNTRY
    ) {
      const list = this.state.lists.country;
      value = Object.values(list).find((item) => item.code === (value as ICountry).code);
      if (value) {
        // TODO: typing
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value.name = this.localisationRegistry.country[value.code] ?? value.name;
      }
    }

    if ([null, undefined].includes(value as null | undefined)) {
      return data;
    }

    data[fieldBlock][fieldName] = value;

    return data;
  }
}

export default DraftToFormDataMapper;

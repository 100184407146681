import ERootLocalisationKey
  from '@/module/common/utils/localisation/initialisator/model/ERootLocalisationKey';
import EServiceContainerInstances
  from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

import IServiceContainer
  from '@/module/common/service/ServiceContainer/IServiceContainer';
import IVueInstance
  from '@/module/common/models/IVueInstance';

import ICamelCaseLocalisationAccountActivatePage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationAccountActivatePage';
import ICamelCaseLocalisationAdminLogin
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationAdminLogin';
import ICamelCaseLocalisationAdminPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationAdminPage';
import ICamelCaseLocalisationApiKeysPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationApiKeysPage';
import ICamelCaseLocalisationBrandingPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationBrandingPage';
import ICamelCaseLocalisationCheckoutLinksFormPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationCheckoutLinksFormPage';
import ICamelCaseLocalisationCheckoutLinksListPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationCheckoutLinksListPage';
import ICamelCaseLocalisationCommon
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationCommon';
import ICamelCaseLocalisationCookiePolicyPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationCookiePolicyPage';
import ICamelCaseLocalisationCountry
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationCountry';
import ICamelCaseLocalisationCurrency
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationCurrency';
import ICamelCaseLocalisationDashboardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationDashboardPage';
import ICamelCaseLocalisationDataExportsPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationDataExportsPage';
import ICamelCaseLocalisationEmail
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationEmail';
import ICamelCaseLocalisationFulfillmentPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationFulfillmentPage';
import ICamelCaseLocalisationHelpWidget
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationHelpWidget';
import ICamelCaseLocalisationLoginPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationLoginPage';
import ICamelCaseLocalisationModalWindow
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationModalWindow';
import ICamelCaseLocalisationPayoutsPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationPayoutsPage';
import ICamelCaseLocalisationPopupMessages
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationPopupMessages';
import ICamelCaseLocalisationPrivacyNoticePage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationPrivacyNoticePage';
import ICamelCaseLocalisationProductsCardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationProductsCardPage';
import ICamelCaseLocalisationProductsFormPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationProductsFormPage';
import ICamelCaseLocalisationProductsListPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationProductsListPage';
import ICamelCaseLocalisationProductsOld
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationProductsOld';
import ICamelCaseLocalisationProfile
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationProfile';
import ICamelCaseLocalisationPromotionsCardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationPromotionsCardPage';
import ICamelCaseLocalisationPromotionsFormPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationPromotionsFormPage';
import ICamelCaseLocalisationPromotionsListPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationPromotionsListPage';
import ICamelCaseLocalisationReports
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationReports';
import ICamelCaseLocalisationValidationMessages
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationValidationMessages';
import ICamelCaseLocalisationWebhooksCardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationWebhooksCardPage';
import ICamelCaseLocalisationWebhooksFormPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationWebhooksFormPage';
import ICamelCaseLocalisationWebhooksListPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationWebhooksListPage';
import ILocalisationAccountActivatePage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationAccountActivatePage';
import ILocalisationAdminLoginPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationAdminLoginPage';
import ILocalisationAdminPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationAdminPage';
import ILocalisationApiKeysPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationApiKeysPage';
import ILocalisationBrandingPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationBrandingPage';
import ILocalisationCheckoutLinksFormPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationCheckoutLinksFormPage';
import ILocalisationCheckoutLinksListPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationCheckoutLinksListPage';
import ILocalisationCommon
  from '@/module/common/utils/localisation/initialisator/model/ILocalisationCommon';
import ILocalisationCookiePolicyPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationCookiePolicyPage';
import ILocalisationCurrency
  from '@/module/common/utils/localisation/initialisator/model/ILocalisationCurrency';
import ILocalisationDashboardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationDashboardPage';
import ILocalisationDataExportsPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationDataExportsPage';
import ILocalisationEmail
  from '@/module/common/utils/localisation/initialisator/model/ILocalisationEmail';
import ILocalisationFulfillmentPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationFulfillmentPage';
import ILocalisationHelpWidget
  from '@/module/common/utils/localisation/initialisator/model/ILocalisationHelpWidget';
import ILocalisationLoginPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationLoginPage';
import ILocalisationModalWindow
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationModalWindow';
import ILocalisationPayoutsPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationPayoutsPage';
import ILocalisationPopupMessages
  from '@/module/common/utils/localisation/initialisator/model/ILocalisationPopupMessages';
import ILocalisationPrivacyNoticePage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationPrivacyNoticePage';
import ILocalisationProductsCardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationProductsCardPage';
import ILocalisationProductsFormPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationProductsFormPage';
import ILocalisationProductsListPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationProductsListPage';
import ILocalisationProductsOld
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationProductsOld';
import ILocalisationProfile
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationProfile';
import ILocalisationPromotionsCardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationPromotionsCardPage';
import ILocalisationPromotionsFormPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationPromotionsFormPage';
import ILocalisationPromotionsListPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationPromotionsListPage';
import ILocalisationReports
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationReports';
import ILocalisationValidationMessages
  from '@/module/common/utils/localisation/initialisator/model/ILocalisationValidationMessages';
import ILocalisationWebhooksCardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationWebhooksCardPage';
import ILocalisationWebhooksFormPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationWebhooksFormPage';
import ILocalisationWebhooksListPage
  from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationWebhooksListPage';

import getLocalisationVue2
  from '@/module/common/helpers/getLocalisationVue2';
import mapObjectSnakeKeysToCamelCaseRecursive
  from '@/module/common/utils/object/mapObjectSnakeKeysToCamelCaseRecursive';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import ILocalisationTooltips from '@/module/common/utils/localisation/initialisator/model/page/ILocalisationTooltips';
import ICamelCaseLocalisationTooltips
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationTooltips';

class LocalisationRegistry implements ILocalisationRegistry {
  private readonly serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  private getLocalisationByKey<SnakeCaseLocalisation, CamelCaseLocalisation>(rootLocalisationKey) {
    const vueInstance = this.serviceContainer.resolve<IVueInstance>(EServiceContainerInstances.VUE_INSTANCE);

    let localisation = getLocalisationVue2<SnakeCaseLocalisation>(rootLocalisationKey, vueInstance);
    if (!localisation) {
      localisation = {} as SnakeCaseLocalisation;
    }

    return mapObjectSnakeKeysToCamelCaseRecursive<
      SnakeCaseLocalisation,
      CamelCaseLocalisation
    >(localisation) as CamelCaseLocalisation;
  }

  get accountActivate(): ICamelCaseLocalisationAccountActivatePage {
    return this.getLocalisationByKey<
      ILocalisationAccountActivatePage,
      ICamelCaseLocalisationAccountActivatePage
    >(ERootLocalisationKey.ACCOUNT_ACTIVATE);
  }

  get admin(): ICamelCaseLocalisationAdminPage {
    return this.getLocalisationByKey<
      ILocalisationAdminPage,
      ICamelCaseLocalisationAdminPage
    >(ERootLocalisationKey.ADMIN);
  }

  get adminLogin(): ICamelCaseLocalisationAdminLogin {
    return this.getLocalisationByKey<
      ILocalisationAdminLoginPage,
      ICamelCaseLocalisationAdminLogin
    >(ERootLocalisationKey.ADMIN_LOGIN);
  }

  get apiKeys(): ICamelCaseLocalisationApiKeysPage {
    return this.getLocalisationByKey<
      ILocalisationApiKeysPage,
      ICamelCaseLocalisationApiKeysPage
    >(ERootLocalisationKey.API_KEYS);
  }

  get branding(): ICamelCaseLocalisationBrandingPage {
    return this.getLocalisationByKey<
      ILocalisationBrandingPage,
      ICamelCaseLocalisationBrandingPage
    >(ERootLocalisationKey.BRANDING);
  }

  get checkoutLinksForm(): ICamelCaseLocalisationCheckoutLinksFormPage {
    return this.getLocalisationByKey<
      ILocalisationCheckoutLinksFormPage,
      ICamelCaseLocalisationCheckoutLinksFormPage
    >(ERootLocalisationKey.CHECKOUT_LINK_FORM);
  }

  get checkoutLinksList(): ICamelCaseLocalisationCheckoutLinksListPage {
    return this.getLocalisationByKey<
      ILocalisationCheckoutLinksListPage,
      ICamelCaseLocalisationCheckoutLinksListPage
    >(ERootLocalisationKey.CHECKOUT_LINK_LIST);
  }

  get common(): ICamelCaseLocalisationCommon {
    return this.getLocalisationByKey<
      ILocalisationCommon,
      ICamelCaseLocalisationCommon
    >(ERootLocalisationKey.COMMON);
  }

  get cookiePolicy(): ICamelCaseLocalisationCookiePolicyPage {
    return this.getLocalisationByKey<
      ILocalisationCookiePolicyPage,
      ICamelCaseLocalisationCookiePolicyPage
    >(ERootLocalisationKey.COOKIE_POLICY);
  }

  get country(): ICamelCaseLocalisationCountry {
    return this.getLocalisationByKey<
      ICamelCaseLocalisationCountry,
      ICamelCaseLocalisationCountry
    >(ERootLocalisationKey.COUNTRY);
  }

  get currency(): ICamelCaseLocalisationCurrency {
    return this.getLocalisationByKey<
      ILocalisationCurrency,
      ICamelCaseLocalisationCurrency
    >(ERootLocalisationKey.CURRENCY);
  }

  get dashboard():ICamelCaseLocalisationDashboardPage {
    return this.getLocalisationByKey<
      ILocalisationDashboardPage,
      ICamelCaseLocalisationDashboardPage
    >(ERootLocalisationKey.DASHBOARD);
  }

  get dataExports(): ICamelCaseLocalisationDataExportsPage {
    return this.getLocalisationByKey<
      ILocalisationDataExportsPage,
      ICamelCaseLocalisationDataExportsPage
    >(ERootLocalisationKey.DATA_EXPORTS);
  }

  get email(): ICamelCaseLocalisationEmail {
    return this.getLocalisationByKey<
      ILocalisationEmail,
      ICamelCaseLocalisationEmail
    >(ERootLocalisationKey.EMAIL);
  }

  get fulfillment():ICamelCaseLocalisationFulfillmentPage {
    return this.getLocalisationByKey<
      ILocalisationFulfillmentPage,
      ICamelCaseLocalisationFulfillmentPage
    >(ERootLocalisationKey.FULFILLMENT_FORM);
  }

  get helpWidget():ICamelCaseLocalisationHelpWidget {
    return this.getLocalisationByKey<
      ILocalisationHelpWidget,
      ICamelCaseLocalisationHelpWidget
    >(ERootLocalisationKey.HELP_WIDGET);
  }

  get login(): ICamelCaseLocalisationLoginPage {
    return this.getLocalisationByKey<
      ILocalisationLoginPage,
      ICamelCaseLocalisationLoginPage
    >(ERootLocalisationKey.LOGIN);
  }

  get modalWindow(): ICamelCaseLocalisationModalWindow {
    return this.getLocalisationByKey<
      ILocalisationModalWindow,
      ICamelCaseLocalisationModalWindow
    >(ERootLocalisationKey.MODAL_WINDOW);
  }

  get payouts(): ICamelCaseLocalisationPayoutsPage {
    return this.getLocalisationByKey<
      ILocalisationPayoutsPage,
      ICamelCaseLocalisationPayoutsPage
    >(ERootLocalisationKey.PAYOUTS);
  }

  get popUp(): ICamelCaseLocalisationPopupMessages {
    return this.getLocalisationByKey<
      ILocalisationPopupMessages,
      ICamelCaseLocalisationPopupMessages
    >(ERootLocalisationKey.POPUP_MESSAGES);
  }

  get privacyNotice(): ICamelCaseLocalisationPrivacyNoticePage {
    return this.getLocalisationByKey<
      ILocalisationPrivacyNoticePage,
      ICamelCaseLocalisationPrivacyNoticePage
    >(ERootLocalisationKey.PRIVACY_NOTICE);
  }

  get productsCard(): ICamelCaseLocalisationProductsCardPage {
    return this.getLocalisationByKey<
      ILocalisationProductsCardPage,
      ICamelCaseLocalisationProductsCardPage
    >(ERootLocalisationKey.PRODUCT_CARD);
  }

  get productsForm(): ICamelCaseLocalisationProductsFormPage {
    return this.getLocalisationByKey<
      ILocalisationProductsFormPage,
      ICamelCaseLocalisationProductsFormPage
    >(ERootLocalisationKey.PRODUCT_FORM);
  }

  get productsList(): ICamelCaseLocalisationProductsListPage {
    return this.getLocalisationByKey<
      ILocalisationProductsListPage,
      ICamelCaseLocalisationProductsListPage
    >(ERootLocalisationKey.PRODUCT_LIST);
  }

  get productsOld(): ICamelCaseLocalisationProductsOld {
    return this.getLocalisationByKey<
      ILocalisationProductsOld,
      ICamelCaseLocalisationProductsOld
    >(ERootLocalisationKey.PRODUCT_OLD);
  }

  get profile(): ICamelCaseLocalisationProfile {
    return this.getLocalisationByKey<
      ILocalisationProfile,
      ICamelCaseLocalisationProfile
    >(ERootLocalisationKey.PROFILE);
  }

  get promotionsCard(): ICamelCaseLocalisationPromotionsCardPage {
    return this.getLocalisationByKey<
      ILocalisationPromotionsCardPage,
      ICamelCaseLocalisationPromotionsCardPage
    >(ERootLocalisationKey.PROMOTION_CARD);
  }

  get promotionsForm(): ICamelCaseLocalisationPromotionsFormPage {
    return this.getLocalisationByKey<
      ILocalisationPromotionsFormPage,
      ICamelCaseLocalisationPromotionsFormPage
    >(ERootLocalisationKey.PROMOTION_FORM);
  }

  get promotionsList(): ICamelCaseLocalisationPromotionsListPage {
    return this.getLocalisationByKey<
      ILocalisationPromotionsListPage,
      ICamelCaseLocalisationPromotionsListPage
    >(ERootLocalisationKey.PROMOTION_LIST);
  }

  get reports(): ICamelCaseLocalisationReports {
    return this.getLocalisationByKey<
      ILocalisationReports,
      ICamelCaseLocalisationReports
    >(ERootLocalisationKey.REPORTS);
  }

  get tooltip(): ICamelCaseLocalisationTooltips {
    return this.getLocalisationByKey<
      ILocalisationTooltips,
      ICamelCaseLocalisationTooltips
    >(ERootLocalisationKey.TOOLTIPS);
  }

  get validationMessages(): ICamelCaseLocalisationValidationMessages {
    return this.getLocalisationByKey<
      ILocalisationValidationMessages,
      ICamelCaseLocalisationValidationMessages
    >(ERootLocalisationKey.VALIDATION_MESSAGES);
  }

  get webhooksCard(): ICamelCaseLocalisationWebhooksCardPage {
    return this.getLocalisationByKey<
      ILocalisationWebhooksCardPage,
      ICamelCaseLocalisationWebhooksCardPage
    >(ERootLocalisationKey.WEBHOOK_CARD);
  }

  get webhooksForm(): ICamelCaseLocalisationWebhooksFormPage {
    return this.getLocalisationByKey<
      ILocalisationWebhooksFormPage,
      ICamelCaseLocalisationWebhooksFormPage
    >(ERootLocalisationKey.WEBHOOK_FORM);
  }

  get webhooksList(): ICamelCaseLocalisationWebhooksListPage {
    return this.getLocalisationByKey<
      ILocalisationWebhooksListPage,
      ICamelCaseLocalisationWebhooksListPage
    >(ERootLocalisationKey.WEBHOOK_LIST);
  }
}

export default LocalisationRegistry;

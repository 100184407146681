<template>
  <COverviewPageLayout>
    <template
      #header
    >
      <COverviewPageHeaderRow
        :page-title="localisation.title"
      />
    </template>
    <template
      #content
    >
      <div
        id="cookiebotWrapper"
      />
    </template>
  </COverviewPageLayout>
</template>

<script>
import {
  inject,
} from 'vue';

import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';

export default {
  name: 'CookiePolicyPage',
  components: {
    COverviewPageLayout,
    COverviewPageHeaderRow,
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const store = serviceContainer.resolve('store');
    const localisationRegistry = store.getters.getLocalisationRegistry;
    const localisation = localisationRegistry.cookiePolicy;

    return {
      localisation,
    };
  },
  mounted() {
    const src = 'https://consent.cookiebot.com/6bc30741-3851-439d-adb1-18e8ce4dba89/cd.js';
    const id = 'CookieDeclaration';
    const type = 'text/javascript';

    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.setAttribute('id', id);
    script.setAttribute('type', type);

    document.getElementById('cookiebotWrapper').appendChild(script);
  },
};
</script>

<style
  lang="scss"
  scoped
>
</style>

import RESTApi from '@/module/common/service/RESTApi/RESTApi';
import RESTApiDecoratorMap from '@/module/common/service/RESTApi/RESTApiDecoratorMap';
import IApiKeysModel from '@/module/developers/models/IApiKeysModel';
import RegistryModel from '@/module/product/model/RegistryModel';
import IFileStorageFrontendModel from '@/module/merchant/models/IFileStorageFrontendModel';
import {
  AxiosInstance,
} from 'axios';
import widgetConfigMapper from '@/module/common/service/RESTApi/mappers/widgetConfigMapper';
import IDashboardWidgetRequestBackendModel from '@/module/dashboard/models/IDashboardWidgetRequestBackendModel';
import IDashboardWidgetRequestFrontendModel from '@/module/dashboard/models/IDashboardWidgetRequestFrontendModel';
import IChartDataResponse from '@/module/dashboard/models/ChartDataResponse/IChartDataResponse';
import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';
import TPerformanceDataResponse from '@/module/dashboard/models/PerformanceDataResponse/TPerformanceDataResponse';
import IFulfillmentBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentBackendModel';
import IFulfillmentParametersResponseModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentParametersResponseModel';
import IKontragentioIndividual from '@/module/merchant/models/Kontragentio/IKontragentioIndividual';
import IKontragentioCompany from '@/module/merchant/models/Kontragentio/IKontragentioCompany';
import FulfillmentTestRequestApi from '@/module/product/api/FulfillmentTestRequestApi';
import IFulfillmentTestRequestApi from '@/module/product/api/contract/IFulfillmentTestRequestApi';
import FulfillmentEmailApi from '@/module/product/api/FulfillmentEmailApi';
import IFulfillmentEmailApi from '@/module/product/api/contract/IFulfillmentEmailApi';
import IApiService from '@/module/common/service/ApiService/contract/IApiService';
import IProductApi from '@/module/product/api/contract/IProductApi';
import ProductApi from '@/module/product/api/ProductApi';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ProductMapperService from '@/module/product/service/ProductMapperService';
import ProductTypeOptionCheckHelper
  from '@/module/product/helper/ProductTypeOptionCheckHelper/ProductTypeOptionCheckHelper';
import IProductIconApi from '@/module/product/api/contract/IProductIconApi';
import ProductIconApi from '@/module/product/api/ProductIconApi';
import ITranslationApi from '@/i18n/api/contract/ITranslationApi';
import TranslationApi from '@/i18n/api/TranslationApi';

class ApiService implements IApiService {
  private readonly instance: AxiosInstance;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    instance: AxiosInstance,
    serviceContainer: IServiceContainer,
  ) {
    this.instance = instance;
    this.serviceContainer = serviceContainer;
  }

  get apiKeys(): IRESTApi<IApiKeysModel> {
    return new RESTApi<IApiKeysModel>(this.instance, 'vendor/api-keys');
  }

  get kontragentioInfo(): IRESTApi<IKontragentioIndividual | IKontragentioCompany> {
    return new RESTApi<IKontragentioIndividual | IKontragentioCompany>(
      this.instance,
      'vendor/company-info',
    );
  }

  get filesStorage(): IRESTApi<IFileStorageFrontendModel> {
    return new RESTApi<IFileStorageFrontendModel>(this.instance, 'vendor/files-storage');
  }

  get widgetConfig(): RESTApiDecoratorMap<
    IDashboardWidgetRequestBackendModel,
    IDashboardWidgetRequestFrontendModel
    > {
    const widgetConfigInstance = new RESTApi<IDashboardWidgetRequestBackendModel>(
      this.instance,
      'vendor/dashboard/state',
    );

    return new RESTApiDecoratorMap<
      IDashboardWidgetRequestBackendModel,
      IDashboardWidgetRequestFrontendModel
    >(
      widgetConfigInstance,
      widgetConfigMapper,
    );
  }

  get metrics(): IRESTApi<IChartDataResponse> {
    return new RESTApi<IChartDataResponse>(this.instance, 'vendor/dashboard');
  }

  get performance(): IRESTApi<TPerformanceDataResponse> {
    return new RESTApi<TPerformanceDataResponse>(this.instance, 'vendor/report/performance/table');
  }

  get fulfillment(): IRESTApi<IFulfillmentBackendModel> {
    return new RESTApi<IFulfillmentBackendModel>(this.instance, 'vendor/fulfillment');
  }

  get fulfillmentParameters(): IRESTApi<IFulfillmentParametersResponseModel> {
    return new RESTApi<IFulfillmentParametersResponseModel>(
      this.instance,
      'vendor/fulfillment-parameters',
    );
  }

  get product(): IProductApi {
    const mapper = new ProductMapperService(
      this.serviceContainer,
      new ProductTypeOptionCheckHelper(),
    );

    return new ProductApi(
      this.instance,
      this.serviceContainer,
      mapper,
    );
  }

  get productIconApi(): IProductIconApi {
    return new ProductIconApi(this.instance);
  }

  get fulfillmentEmail(): IFulfillmentEmailApi {
    return new FulfillmentEmailApi(this.instance);
  }

  get fulfillmentTestRequest(): IFulfillmentTestRequestApi {
    return new FulfillmentTestRequestApi(this.instance);
  }

  get translation(): ITranslationApi {
    return new TranslationApi(this.instance);
  }

  // TODO: change api signature. not REST
  get softwareRegistry(): IRESTApi<RegistryModel> {
    return new RESTApi<RegistryModel>(this.instance, 'vendor/russian-software/check');
  }
}

export default ApiService;

import IProductModule from '@/module/product/service/contract/IProductModule';
import IFulfillmentService from '@/module/product/service/contract/IFulfillmentService';
import FulfillmentService from '@/module/product/service/FulfillmentService';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IProductService from '@/module/product/service/contract/IProductService';
import ProductService from '@/module/product/service/ProductService';
import IProductModuleState from '@/module/product/service/model/IProductModuleState';
import IProductFromPage from '@/module/product/view/ProductFormPage/service/contract/IProductFromPage';
import ProductFormPage from '@/module/product/view/ProductFormPage/service/ProductFormPage';
import IAvailableForService from '@/module/product/service/contract/IAvailableForService';
import AvailableForService from '@/module/product/service/AvailableForService';
import LicenseTypeService from '@/module/product/service/LicenseTypeService';
import ILicenseTypeService from '@/module/product/service/contract/ILicenseTypeService';
import PricingModelService from '@/module/product/service/PricingModelService';
import IPricingModelService from '@/module/product/service/contract/IPricingModelService';
import ITermService from '@/module/product/service/contract/ITermService';
import TermService from '@/module/product/service/TermService';
import IPricingTableHelper from '@/module/product/helper/PricingTableHelper/IPricingTableHelper';
import PricingTableHelper from '@/module/product/helper/PricingTableHelper/PricingTableHelper';

class ProductModule implements IProductModule {
  private readonly serviceContainer: IServiceContainer;

  private state: IProductModuleState = {} as IProductModuleState;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  getFulfillmentService = (): IFulfillmentService => {
    if (!this.state.fulfillmentService) {
      this.state.fulfillmentService = new FulfillmentService(this.serviceContainer);
    }

    return this.state.fulfillmentService;
  };

  get productService(): IProductService {
    if (!this.state.productService) {
      this.state.productService = new ProductService(
        this.serviceContainer,
      );
    }

    return this.state.productService;
  }

  get productFormPage(): IProductFromPage {
    if (!this.state.productFormPage) {
      this.state.productFormPage = new ProductFormPage(this.serviceContainer);
    }

    return this.state.productFormPage;
  }

  get availableForService(): IAvailableForService {
    if (!this.state.availableForService) {
      this.state.availableForService = new AvailableForService(this.serviceContainer);
    }

    return this.state.availableForService;
  }

  get pricingModelService(): IPricingModelService {
    if (!this.state.pricingModelService) {
      this.state.pricingModelService = new PricingModelService(this.serviceContainer);
    }

    return this.state.pricingModelService;
  }

  get licenseTypeService(): ILicenseTypeService {
    if (!this.state.licenseTypeService) {
      this.state.licenseTypeService = new LicenseTypeService(this.serviceContainer);
    }

    return this.state.licenseTypeService;
  }

  get termService(): ITermService {
    if (!this.state.termService) {
      this.state.termService = new TermService(this.serviceContainer);
    }

    return this.state.termService;
  }

  get pricingTableHelper(): IPricingTableHelper {
    if (!this.state.pricingTableHelper) {
      this.state.pricingTableHelper = new PricingTableHelper(this.serviceContainer);
    }

    return this.state.pricingTableHelper;
  }
}

export default ProductModule;

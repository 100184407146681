<template>
  <div
    class="header"
  >
    <div
      class="header__block header__block_left"
    >
      <button
        :class="arrowClass"
        class="menuButtonDesktop"
        type="button"
        @click="handleMenuClick"
      >
        <DSIcon
          icon="doubleArrowLeft"
        />
      </button>
      <button
        class="menuButtonMobile header__navButton"
        type="button"
        @click="handleMobileMenuClick"
      >
        <DSIcon
          icon="burgerMenu"
          color="menu-active"
        />
      </button>
      <a
        :href="state.mainSiteLink"
        class="header__navButton logo"
        target="_blank"
      >
        <CLogoIcon />
      </a>
      <div
        class="header__merchants"
      >
        <DSHeader
          color="menu"
          size="3"
          :text="$t('header.merchants')"
        />
        <DSIcon
          icon="dots"
          class="header__dots"
        />
      </div>
    </div>
    <div
      class="header__block header__block_right"
    >
      <DSSubstrate
        class="header__account-mode"
        borderRadius="m"
      >
        <CSwitcher
          v-if="isMerchantActivated"
          :label="$t('header.merchant_test_mode')"
          :value="isTestMode"
          color="menu"
          @change="handleMerchantModeSwitch($event)"
        />
        <DSTextElement
          v-else
          color="menu"
          variant="medium"
        >
          {{ $t('header.merchant_test_mode') }}
        </DSTextElement>
      </DSSubstrate>
      <button
        v-if="!isMerchantActivated && salesStatusActivationAvailable"
        class="header__activate-account"
        type="button"
        @click="$router.push('/merchant/account/activate').catch(() => null)"
      >
        {{ $t('header.activate_account') }} →
      </button>
      <CHeaderProfileMenu />
    </div>
    <div
      v-if="isTestMode"
      :class="testModeClasses"
    >
      <DSTextElement
        :variant="testModeTextVariant"
        class="header__test-mode-text"
        color="menu"
      >
        {{ $t('header.test_mode') }}
      </DSTextElement>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  inject,
} from 'vue';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import getPathWithoutParams from '@/common/getPathWithoutParams';
import {
  clearMerchantModeAlert,
} from '@/module/common/utils/merchantModeAlert';

import ETextVariants from '@/module/common/models/ETextVariants';

import CHeaderProfileMenu from '@/module/common/components/UI/CHeaderProfileMenu.vue';
import CLogoIcon from '@/module/common/components/CLogoIcon.vue';
import CSwitcher from '@/module/common/components/UI/CSwitcher.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSSubstrate from '@/module/design-system/components/DSSubstrate.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    CHeaderProfileMenu,
    CLogoIcon,
    CSwitcher,
    DSHeader,
    DSIcon,
    DSSubstrate,
    DSTextElement,
  },
  props: {
    menuMinimizedHandler: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      pressed: false,
      unpressed: false,
      mobileBreakpoints: [
        'xxs',
        'xs',
        'sm',
      ],
    };
  },
  computed: {
    ...mapGetters({
      isMenuMinimized: 'commonStatusesStore/getIsMenuMinimized',
      merchantMode: 'authStore/getMerchantMode',
      isMerchantActivated: 'authStore/getIsMerchantActivated',
      salesStatusActivationAvailable: 'authStore/getSalesStatusActivationAvailable',
      currentBreakpoint: 'commonStatusesStore/getCurrentBreakpoint',
    }),
    ...mapState({
      isMobileMenuShown: (state) => state.productLayoutStore.isMobileMenuShown,
    }),
    arrowClass() {
      return {
        header__navButton: true,
        pressed: !this.isMenuMinimized,
        unpressed: this.isMenuMinimized,
      };
    },
    isTestMode() {
      return this.merchantMode === 'test';
    },
    testModeClasses() {
      return [
        'header__test-mode',
        `header__test-mode_${this.isMenuMinimized ? 'wide' : 'narrow'}`,
      ];
    },
    testModeTextVariant() {
      return this.mobileBreakpoints.includes(this.currentBreakpoint)
        ? ETextVariants.X_SMALL_MEDIUM
        : ETextVariants.SMALL_MEDIUM;
    },
  },
  methods: {
    ...mapActions({
      updateMenuStatus: 'commonStatusesStore/updateMenuStatus',
    }),
    ...mapMutations({
      toggleMobileMenu: 'productLayoutStore/toggleMobileMenu',
      updateMerchantMode: 'authStore/updateMerchantMode',
    }),
    handleMenuClick() {
      this.pressed = this.isMenuMinimized;
      this.unpressed = !this.isMenuMinimized;
      this.updateMenuStatus(!this.isMenuMinimized);
    },
    handleMobileMenuClick() {
      this.pressed = this.isMenuMinimized;
      this.unpressed = !this.isMenuMinimized;
      this.toggleMobileMenu(!this.isMobileMenuShown);
    },
    handleMerchantModeSwitch(value) {
      const merchantModes = JSON.parse(localStorage.getItem('allowedMerchantModes'));
      const merchantMode = merchantModes.find((mode) => mode.value === value);

      this.updateMerchantMode(merchantMode.name);
      clearMerchantModeAlert();

      const path = getPathWithoutParams(this.$route);
      if (path !== this.$route.path) {
        window.location.href = path;
      } else {
        this.$router.go(0);
      }
    },
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const portalConfigService = serviceContainer.portalConfigService;

    const state = {
      mainSiteLink: portalConfigService.get().url.main,
    };

    return {
      state,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
@import "@/module/design-system/components/variables.scss";

.pressed {
  animation-name: arrowRotate2;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
  animation-fill-mode: forwards;
}

.unpressed {
  animation-name: arrowRotate1;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
  animation-fill-mode: forwards;
}

@keyframes arrowRotate1 {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  33% {
    transform: rotate3d(1, 0, 1, 45deg);
  }
  66% {
    transform: rotate3d(1, 1, 1, 90deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 180deg);
  }
}

@keyframes arrowRotate2 {
  0% {
    transform: rotate3d(0, 1, 0, 180deg);
  }
  33% {
    transform: rotate3d(1, 1, 1, 90deg);
  }
  66% {
    transform: rotate3d(1, 0, 1, 45deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

.header {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 63px;
  padding-right: 24px;
  padding-left: 24px;
  background-color: $menu-bg-color;

  &__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    &_right {
      justify-content: flex-end;
    }
  }

  &__localeSwitcher {
    margin-right: 20px;
  }

  &__navButton {
    display: flex;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    padding: 0;
  }

  &__merchants {
    display: flex;
    align-items: center;
    font-family: $text-font-proxima-nova;
    font-size: 24px;
    color: #ffffff;
    gap: 8px;
  }

  &__account-mode {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    white-space: nowrap;
  }

  &__activate-account {
    padding: 8px 12px;
    font-family: $text-font-proxima-nova;
    font-size: 16px;
    white-space: nowrap;
    color: #e38700;
    border: 0;
    border-radius: 8px;
    outline: 0;
    background-color: #463b35;
    cursor: pointer;
  }

  &__test-mode {
    position: absolute;
    top: 100%;
    height: 8px;
    background-color: $test-mode-custom-color;
    transition: all 0.2s ease-in;

    &_wide {
      left: 64px;
      width: calc(100% - 64px);
    }

    &_narrow {
      left: 255px;
      width: calc(100% - 255px);
    }
  }

  &__test-mode-text {
    position: absolute;
    top: 0;
    left: 50%;
    padding: 8px 12px;
    text-transform: uppercase;
    border-radius: 8px;
    background-color: $test-mode-custom-color;
    transform: translateX(-50%);
  }
}

.menuButtonMobile {
  display: none;
}

@media (max-width: $breakpoint-sm) {
  .header {
    padding-left: 16px;
    padding-right: 16px;

    &__block {
      &_left {
        gap: 12px;
      }

      &_right {
        gap: 16px;
      }
    }

    &__merchants {
      display: none;
    }

    &__dots {
      display: none;
    }

    &__activate-account {
      display: none;
    }

    &__test-mode {
      &_wide,
      &_narrow {
        left: 0;
        width: 100%;
      }
    }
  }

  .menuButtonDesktop {
    display: none;
  }

  .menuButtonMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 25px;
  }
}

@media (max-width: $breakpoint-xs) {
  .header {
    gap: 16px;

    &__block {
      &_right {
        gap: 8px;
      }
    }
  }

  .logo {
    width: 100px;
  }
}
</style>

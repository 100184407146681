import accountActivateStore from '@/module/merchant/store/accountActivateStore';
import commonStatusesStore from '@/module/common/store/commonStatusesStore';
import checkoutLinkFormPageStore from '@/module/product/store/checkoutLinkFormPageStore';
import checkoutLinkPageStore from '@/module/product/store/checkoutLinkPageStore';
import dashboardStore from '@/module/dashboard/store/dashboardStore';
import merchantAccountStore from '@/module/merchant/store/merchantAccountStore';
import notificationsStore from '@/module/common/store/notificationsStore';
import adminStore from '@/module/admin/store/adminStore';
import getLocale from '@/i18n/getLocale';
import setLocalStorageItem from '@/module/common/utils/localStorage/setLocalStorageItem';
import authStore from './modules/authStore';
import createEditProductStore from './modules/createEditProductStore';
import createEditPromotionStore from './modules/createEditPromotionStore';
import createEditWebhookStore from './modules/createEditWebhookStore';
import deleteModalStore from './modules/deleteModalStore';
import globalMerchantVariablesStore from './modules/globalMerchantVariablesStore';
import popupStore from './modules/popupStore';
import productLayoutStore from './modules/productLayoutStore';
import productListStore from './modules/productListStore';
import promotionsOverviewStore from './modules/promotionsOverviewStore';
import promotionStore from './modules/promotionStore';
import reportsOverviewStore from './modules/reportsOverviewStore';
import reportsTableScrollStore from './modules/reportsTableScrollStore';
import webhooksStore from './modules/webhooksStore';

function initStore(vue, vuex, serviceContainer) {
  vue.use(vuex);

  return new vuex.Store({
    state: {
      locale: getLocale(),
      locales: {
        locales_long: ['English', 'Русский'],
        locales_short: ['en', 'ru'],
      },
      localisationRegistry: null,
      scrollHostRef: null,
      country: null,
    },
    mutations: {
      initScrollHostRef(state, ref) {
        state.scrollHostRef = ref;
      },
      setLocale(state, locale) {
        state.locale = locale;
      },
      setLocalisationRegistry(state, payload) {
        state.localisationRegistry = payload;
      },
      setCountry(state, payload) {
        state.country = payload;
      },
    },
    getters: {
      getScrollHostRef(state) {
        return state.scrollHostRef;
      },
      getLocale(state) {
        return state.locale;
      },
      getCountry(state) {
        return state.country;
      },
      getLocalisationRegistry(state) {
        return state.localisationRegistry;
      },
    },
    actions: {
      changeLocale({ commit }, locale) {
        const $i18n = serviceContainer.resolve('i18n');

        const newLocale = getLocale(locale);
        commit('setLocale', newLocale);
        setLocalStorageItem('locale', newLocale);
        $i18n.locale = newLocale;
      },
      changeLocalisationRegistry({ commit }, localisationRegistry) {
        commit('setLocalisationRegistry', localisationRegistry);
      },
    },
    modules: {
      accountActivateStore: accountActivateStore(serviceContainer),
      adminStore: adminStore(serviceContainer),
      authStore: authStore(serviceContainer),
      checkoutLinkFormPageStore: checkoutLinkFormPageStore(serviceContainer),
      checkoutLinkPageStore: checkoutLinkPageStore(serviceContainer),
      commonStatusesStore: commonStatusesStore(serviceContainer),
      createEditProductStore: createEditProductStore(serviceContainer),
      createEditPromotionStore: createEditPromotionStore(serviceContainer),
      createEditWebhookStore: createEditWebhookStore(serviceContainer),
      dashboardStore: dashboardStore(serviceContainer),
      deleteModalStore: deleteModalStore(serviceContainer),
      globalMerchantVariablesStore: globalMerchantVariablesStore(serviceContainer),
      merchantAccountStore: merchantAccountStore(serviceContainer),
      notificationsStore: notificationsStore(serviceContainer),
      popupStore: popupStore(serviceContainer),
      promotionsOverviewStore: promotionsOverviewStore(serviceContainer),
      promotionStore: promotionStore(serviceContainer),
      productLayoutStore: productLayoutStore(serviceContainer),
      productListStore: productListStore(serviceContainer),
      reportsOverviewStore: reportsOverviewStore(serviceContainer),
      reportsTableScrollStore: reportsTableScrollStore(serviceContainer),
      webhooksStore: webhooksStore(serviceContainer),
    },
  });
}
export default initStore;

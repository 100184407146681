import IPricingTableHelper from '@/module/product/helper/PricingTableHelper/IPricingTableHelper';
import TPricingTableValue from '@/module/product/model/TPricingTableValue';
import IOnePriceForEachCurrencyPricingTable
  from '@/module/product/model/FormData/Price/IOnePriceForEachCurrencyPricingTableItem';
import IVolumePricingAndOptionsPricingTableItem
  from '@/module/product/model/FormData/Price/IVolumePricingAndOptionsPricingTableItem';
import IVolumePricePricingTableItem from '@/module/product/model/FormData/Price/IVolumePricePricingTableItem';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import ECurrency from '@/module/merchant/models/ECurrency';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import TFormData from '@/module/product/model/FormData/TFormData';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import IVolumePriceColumn from '@/module/product/model/FormData/Price/IVolumePriceColumn';
import IVolumePricingAndOptionsColumn
  from '@/module/product/model/FormData/Price/IVolumePricingAndOptionsColumn';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

class PricingTableHelper implements IPricingTableHelper {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  initValue = (formData: TFormData): TPricingTableValue => {
    const productFormPageHandler = this.serviceContainer.portalModeService.getProductFormPageHandler();
    const isVolumePrice = formData.pricingModel?.id === EPricingModelName.VOLUME_PRICING;
    const currencies = isVolumePrice
      ? productFormPageHandler.getPriceCurrencyList()
      : productFormPageHandler.getSellingCurrencyList();
    const currencyList = currencies.map(
      (currency) => ({ id: currency, title: currency }),
    );

    if (
      isVolumePrice
    ) {
      return {
        pricingModelName: EPricingModelName.VOLUME_PRICING,
        value: {
          currency: currencyList[0],
          columns: [
            this.getVolumePriceColumn(1, 1, 2),
            this.getVolumePriceColumn(2, 3, 4),
          ],
        },
      };
    }

    if (
      formData.pricingModel?.id === EPricingModelName.VOLUME_PRICING_AND_OPTIONS
    ) {
      return {
        pricingModelName: EPricingModelName.VOLUME_PRICING_AND_OPTIONS,
        value: [
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.getVolumePriceAndOptionsColumn(1, 1, 2),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.getVolumePriceAndOptionsColumn(2, 3, 4),
        ],
      };
    }

    if (
      formData.pricingModel?.id === EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY
    ) {
      return {
        pricingModelName: EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY,
        value: {
          sku: {
            value: '',
            status: {
              code: EValidationFieldStatusCode.DEFAULT,
            },
          },
          ...currencyList.reduce((list, currency) => {
            list[currency.id] = {
              value: '',
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            };

            return list;
          }, {} as { [key in ECurrency]: { value: string; status: IFieldStatus } }),
        },
      };
    }

    throw Error('[initValue] Unknown pricing type');
  };

  addTier = (data: TPricingTableValue): TPricingTableValue => {
    const utilsService = this.serviceContainer.utilsService;
    const localData = utilsService.data.cloneImmutable(data);

    if (this.isVolumePrice(localData)) {
      const index = localData.value.columns.length - 1;

      if (localData.value.columns.length >= 2 && localData.value.columns[index].to.value === '∞') {
        localData.value.columns[index].to.value = `${+localData.value.columns[index].from.value + 1}`;
      }

      const lastId = localData.value.columns[index].id;
      const from = +localData.value.columns[index].to.value + 1;
      const to = localData.value.columns.length >= 2 ? '∞' : from + 1;

      localData.value.columns.push(this.getVolumePriceColumn(lastId + 1, from, to));

      return utilsService.data.cloneImmutable(localData);
    }

    if (this.isVolumePriceAndOptions(localData)) {
      const index = localData.value.length - 1;

      if (localData.value.length >= 2 && localData.value[index].to.value === '∞') {
        localData.value[index].to.value = `${+localData.value[index].from.value + 1}`;
      }

      const lastId = localData.value[index].id;
      const from = +localData.value[index].to.value + 1;
      const to = localData.value.length >= 2 ? '∞' : from + 1;

      localData.value.push(
        // TODO: разобраться с type-mismatch
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.getVolumePriceAndOptionsColumn(lastId + 1, from, to),
      );

      return utilsService.data.cloneImmutable(localData);
    }

    return localData;
  };

  private getVolumePriceColumn = (id: number, from: number | string, to: number | string): IVolumePriceColumn => ({
    id,
    from: {
      value: `${from}`,
      status: {
        code: EValidationFieldStatusCode.DEFAULT,
      },
    },
    to: {
      value: `${to}`,
      status: {
        code: EValidationFieldStatusCode.DEFAULT,
      },
    },
    sku: {
      value: '',
      status: {
        code: EValidationFieldStatusCode.DEFAULT,
      },
    },
    price: {
      value: '',
      status: {
        code: EValidationFieldStatusCode.DEFAULT,
      },
    },
  });

  private getVolumePriceAndOptionsColumn = (
    id: number,
    from: number | string,
    to: number | string,
  ): IVolumePricingAndOptionsColumn => {
    const productFormPageHandler = this.serviceContainer.portalModeService.getProductFormPageHandler();

    const currencyList = productFormPageHandler.getSellingCurrencyList()
      .map((currency) => ({ id: currency, title: currency }));

    return {
      id,
      from: {
        value: `${from}`,
        status: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
      },
      to: {
        value: `${to}`,
        status: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
      },
      sku: {
        value: '',
        status: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
      },
      ...currencyList.reduce((list, currency) => {
        list[currency.id] = {
          value: '',
          status: {
            code: EValidationFieldStatusCode.DEFAULT,
          },
        };

        return list;
      }, {} as { [key in ECurrency]: { value: string; status: IFieldStatus } }),
    };
  };

  cloneRenewalFromBasePrice = (formData: TFormData): TFormData => {
    const utilsService = this.serviceContainer.utilsService;
    const localPrice = utilsService.data.cloneImmutable(formData.price) as TPricingTableValue;

    if (!formData.renewalPrice) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.renewalPrice = localPrice;
    } else if (this.isVolumePrice(localPrice)) {
      const renewalPrice = utilsService.data.cloneImmutable(formData.renewalPrice) as IVolumePricePricingTableItem;
      const priceColumnsIds = localPrice.value.columns.map((column) => column.id);

      renewalPrice.value.columns = renewalPrice.value.columns.filter((column) => priceColumnsIds.includes(column.id));

      if (renewalPrice.value.columns.length !== priceColumnsIds.length) {
        const renewalPriceColumnsMap: { [key: number]: IVolumePriceColumn } = renewalPrice.value.columns
          .reduce((map, column) => {
            map[column.id] = column;

            return map;
          }, {});

        let previous;
        renewalPrice.value.columns = priceColumnsIds.reduce((columns, id, index) => {
          if (renewalPriceColumnsMap[id]) {
            columns.push(renewalPriceColumnsMap[id]);
            previous = renewalPriceColumnsMap[id];
          } else {
            const from = previous.to.value === '∞' ? +previous.from.value + 2 : +previous.to.value + 1;
            const to = index === priceColumnsIds.length - 1 ? '∞' : from + 1;

            if (columns[index - 1].to.value === '∞') {
              columns[index - 1].to.value = `${+columns[index - 1].from.value + 1}`;
            }

            columns.push(this.getVolumePriceColumn(id, from, to));
          }

          return columns;
        }, [] as IVolumePriceColumn[]);
      }

      formData.renewalPrice = renewalPrice;
    } else if (this.isVolumePriceAndOptions(localPrice)) {
      const renewalPrice = utilsService.data
        .cloneImmutable(formData.renewalPrice) as IVolumePricingAndOptionsPricingTableItem;
      const priceColumnsIds = localPrice.value.map((column) => column.id);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renewalPrice.value = renewalPrice.value.filter((column) => priceColumnsIds.includes(column.id));

      if (renewalPrice.value.length !== priceColumnsIds.length) {
        const renewalPriceColumnsMap: { [key: number]: IVolumePricingAndOptionsColumn } = renewalPrice.value
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .reduce((map, column) => {
            map[column.id] = column;

            return map;
          }, {});

        let previous;
        renewalPrice.value = priceColumnsIds.reduce((columns, id, index) => {
          if (renewalPriceColumnsMap[id]) {
            columns.push(renewalPriceColumnsMap[id]);
            previous = renewalPriceColumnsMap[id];
          } else {
            const from = previous.to.value === '∞' ? +previous.from.value + 2 : +previous.to.value + 1;
            const to = index === priceColumnsIds.length - 1 ? '∞' : from + 1;

            if (columns[index - 1].to.value === '∞') {
              columns[index - 1].to.value = `${+columns[index - 1].from.value + 1}`;
            }

            columns.push(this.getVolumePriceAndOptionsColumn(id, from, to));
          }

          return columns;
        }, [] as IVolumePricingAndOptionsColumn[]);
      }

      formData.renewalPrice = renewalPrice;
    }

    return formData;
  };

  deleteTier = (data: TPricingTableValue, id: number): TPricingTableValue => {
    const utilsService = this.serviceContainer.utilsService;
    const localData = utilsService.data.cloneImmutable(data) as TPricingTableValue;

    if (this.isVolumePrice(localData)) {
      localData.value.columns = localData.value.columns.filter((column) => column.id !== id);

      return utilsService.data.cloneImmutable(localData);
    }

    if (this.isVolumePriceAndOptions(localData)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localData.value = localData.value.filter((column) => column.id !== id);

      return utilsService.data.cloneImmutable(localData);
    }

    return localData;
  };

  isVolumePrice = (
    value?: TPricingTableValue,
  ): value is IVolumePricePricingTableItem => value?.pricingModelName === EPricingModelName.VOLUME_PRICING;

  isVolumePriceAndOptions = (value?: TPricingTableValue): value is IVolumePricingAndOptionsPricingTableItem => {
    const pricingModelName = value?.pricingModelName;
    const volumePricingAndOptions = EPricingModelName.VOLUME_PRICING_AND_OPTIONS;

    return pricingModelName === volumePricingAndOptions;
  };

  isOnePriceForEachCurrency = (
    value?: TPricingTableValue,
  ): value is IOnePriceForEachCurrencyPricingTable => {
    const pricingModelName = value?.pricingModelName;
    const onePriceForEachCurrency = EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY;

    return pricingModelName === onePriceForEachCurrency;
  };
}

export default PricingTableHelper;

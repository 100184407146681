import IAxiosClientService from '@/module/common/service/AxiosClientService/IAxiosClientService';
import axios, { AxiosInstance } from 'axios';
import ILocalStorageService from '@/module/common/service/LocalStorage/ILocalStorageService';
import IAdminAuthorizeService from '@/module/common/service/AuthorizeService/IAdminAuthorizeService';
import IAuthorizeService from '@/module/common/service/AuthorizeService/IAuthorizeService';
import ELocalStorageKeys from '@/module/common/service/LocalStorage/ELocalStorageKeys';

class AxiosClientService implements IAxiosClientService {
  private _instance?: AxiosInstance;

  private retry = false;

  get instance(): AxiosInstance {
    if (!this._instance) {
      this._instance = axios.create({
        baseURL: `${this.computeBackendHost()}/api`,
        headers: {
          accept: 'aplication/json',
        },
      });
    }

    return this._instance;
  }

  addRequestInterceptors = (
    adminAuthorizeService: IAdminAuthorizeService,
    authorizeService: IAuthorizeService,
  ) => {
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers = {
          Accept: 'aplication/json',
        };

        if (config?.url?.split('/')[0] === 'admin') {
          config = adminAuthorizeService.signRequest(config);
        } else {
          config = authorizeService.signRequest(config);
        }
        return config;
      },
      async (error) => Promise.reject(error),
    );
  };

  addResponseInterceptors = (localStorageService: ILocalStorageService) => {
    this.instance.interceptors.response.use(
      async (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (originalRequest.url === 'logout') {
          return Promise.resolve(error);
        }

        if (error.response) {
          const { status } = error.response;

          if ((status === 403 || status === 401) && !this.retry) {
            this.retry = true;

            const { data } = await this.instance.post('/token/refresh');
            const newToken = data.accessToken;

            localStorageService.write(ELocalStorageKeys.AUTH_TOKEN, newToken);
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            // TODO: fix
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return instance(originalRequest);
          }

          if (this.retry) {
            this.retry = false;

            localStorageService.remove(ELocalStorageKeys.AUTH_TOKEN);
          }
        }
        return Promise.reject(error);
      },
    );
  };

  private computeBackendHost = () => {
    let location = `${process.env.VUE_APP_BACKEND_HOST}`;

    if (location.length === 0) {
      location = window.location.origin;
    }

    return location;
  };
}

export default AxiosClientService;

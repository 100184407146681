
import CFormPageLayout from '@/module/common/components/PageParts/CFormPageLayout.vue';
import CFormPageHeaderRow from '@/module/common/components/PageParts/CFormPageHeaderRow.vue';
import CFormPageContent from '@/module/common/components/PageParts/CFormPageContent.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import Vue, {
  computed,
  ComputedRef,
  inject,
  onMounted,
} from 'vue';
import getButton, {
  getButtonWithIcon,
} from '@/module/common/helpers/getButton';
import IButtonConfig from '@/module/common/models/helpers/getButton/IButtonConfig';
import EIcon from '@/module/design-system/components/models/EIcon';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import { Store } from 'vuex';
import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import VueRouter from 'vue-router';
import DSTabLine from '@/module/design-system/components/UI/Tab/DSTabLine.vue';
import IDSIcon from '@/module/design-system/components/models/IDSIcon';
import ESize from '@/module/design-system/components/models/ESize';
import initState, {
  getCountryList,
  getDraft,
  getTabs,
} from '@/module/merchant/view/AccountActivatePage/AccountActivatePage.func';
import CFormComponent from '@/module/common/components/CFormComponent.vue';
// eslint-disable-next-line max-len
// eslint-disable-next-line max-len
import DraftToFormDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/DraftToFormDataMapper';
// eslint-disable-next-line max-len
import FormDataToDraftMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/FormDataToDraftMapper';
// eslint-disable-next-line max-len
import FormDataToSummaryMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/FormDataToSummaryMapper';
import CInfoBlockList from '@/module/common/components/CInfoBlockList.vue';
import IVueInstance from '@/module/common/models/IVueInstance';
import CCheckbox from '@/module/common/components/UI/CCheckbox.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';
// eslint-disable-next-line max-len
import FormDataToSaveMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/FormDataToSaveMapper';
// eslint-disable-next-line max-len
import ValidatorHandler from '@/module/merchant/view/AccountActivatePage/Validator/ValidatorHandler';
import SimpleFieldValidatorStatusHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/SimpleFieldValidatorStatusHandler';
import SimpleTabValidatorStatusHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/SimpleTabValidatorStatusHandler';
import EmptyFieldValidatorStatusHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/EmptyFieldValidatorStatusHandler';
import EmptyTabValidatorStatusHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/EmptyTabValidatorStatusHandler';
import EPageTab from '@/module/merchant/view/AccountActivatePage/models/EPageTab';
import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';
import TNavigationItem from '@/module/common/models/CFormPageHeaderRow/TNavigationItem';
import ECurrency from '@/module/merchant/models/ECurrency';
import FilteredSelectHandler from '@/module/merchant/view/AccountActivatePage/Handler/FilteredSelectHandler';
import FormConfigHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/FormConfigHandler';
import FormDraftHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/FormDraftHandler';
import FormDataHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/FormDataHandler';
import TabHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/TabHandler';
import ETabStatus
  from '@/module/merchant/view/AccountActivatePage/models/ETabStatus';
import IFormConfig from '@/module/merchant/view/AccountActivatePage/models/IFormConfig';

interface IAccountActivatePageButtons {
  summaryEdit: ComputedRef<IButtonConfig>;
}

export default Vue.extend({
  name: 'AccountActivateNewPage',
  components: {
    DSIcon,
    DSButton,
    CFormPageLayout,
    CFormPageHeaderRow,
    CFormPageContent,
    DSTabLine,
    CFormComponent,
    CInfoBlockList,
    CCheckbox,
    InlineAlert,
  },
  setup() {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const router = serviceContainer.resolve<VueRouter>(EServiceContainerInstances.ROUTER);
    const portalConfigService = serviceContainer.portalConfigService;

    const state = initState(
      store.getters.getLocalisationRegistry,
    );

    const filteredSelectHandler = new FilteredSelectHandler(state);

    const simpleValidatorHandler = new ValidatorHandler(
      serviceContainer,
      store.getters.getLocalisationRegistry,
      state,
      new SimpleFieldValidatorStatusHandler(),
      new SimpleTabValidatorStatusHandler(),
    );

    const emptyFieldValidatorHandler = new ValidatorHandler(
      serviceContainer,
      store.getters.getLocalisationRegistry,
      state,
      new EmptyFieldValidatorStatusHandler(),
      new EmptyTabValidatorStatusHandler(),
    );

    const formConfigHandler = new FormConfigHandler(
      state,
      store.getters.getLocalisationRegistry,
      simpleValidatorHandler,
      serviceContainer,
    );

    const draftHandler = new FormDraftHandler(
      state,
      serviceContainer,
      new FormDataToDraftMapper(state, serviceContainer),
    );

    const formDataHandler = new FormDataHandler(
      state,
      store.getters.getLocalisationRegistry,
      serviceContainer,
      emptyFieldValidatorHandler,
      draftHandler,
      formConfigHandler,
      new DraftToFormDataMapper(
        state,
        serviceContainer,
        formConfigHandler,
        store.getters.getLocalisationRegistry,
      ),
      new FormDataToSummaryMapper(serviceContainer),
      new FormDataToSaveMapper(
        state,
        serviceContainer,
      ),
      filteredSelectHandler,
    );

    const tabHandler = new TabHandler(
      state,
      serviceContainer,
      simpleValidatorHandler,
      emptyFieldValidatorHandler,
      formConfigHandler,
      store.getters.getLocalisationRegistry,
    );

    onMounted(async () => {
      state.formDraft = await getDraft(serviceContainer);

      state.lists.country = await getCountryList(serviceContainer, store.getters.getLocalisationRegistry);

      state.tabListConfig = formConfigHandler.getTabListConfig();

      state.activeTabConfig = formConfigHandler.getFormConfig(state.activeTab);

      state.formData = formDataHandler.initFromData();

      state.tabs = getTabs(store.getters.getLocalisationRegistry);

      state.isLoading = false;
    });

    const summaryData = computed(() => formDataHandler.getSummaryData(state.formData, state.tabListConfig));

    const checkboxLabel = computed(() => {
      const globalResellerAgreementFile = state.formData.accountForPayouts.currency.value === ECurrency.USD
        ? portalConfigService.get().documents.reseller_agreement_usd
        : portalConfigService.get().documents.reseller_agreement;

      const accountActivate = store.getters.getLocalisationRegistry.accountActivate;

      const vueInstance = serviceContainer.resolve<IVueInstance>(EServiceContainerInstances.VUE_INSTANCE);
      // @todo создать компонент, который будет парсить текст из перевода, вытаскивать ссылки и подменять их на DSLink
      const resellerAgreements = accountActivate.summary.termsAndConditions.noventiq.links.resellerAgreements;
      const privacyNotice = accountActivate.summary.termsAndConditions.noventiq.links.privacyNotice;
      return vueInstance.$t('pages.account_activate.form.summary.terms_and_conditions.noventiq.title', {
        reseller_agreement: `<a href="${globalResellerAgreementFile}" download>${resellerAgreements}</a>`,
        privacy_notice: `<a href="/privacy-notice" target="_blank">${privacyNotice}</a>`,
      });
    });

    const termsAndConditionsDescription = computed(() => {
      const summary = store.getters.getLocalisationRegistry.accountActivate.summary;
      return summary.termsAndConditions.noventiq.description;
    });

    const isSaveButton = computed(() => state.activeTab.id === EPageTab.SUMMARY);

    const isSaveEnabled = computed(() => {
      const isFromValid = state.tabs.reduce(
        (isValid, tab) => isValid && tab.status !== ETabStatus.ERROR,
        true,
      );

      return state.isTermsAndConditionsChecked && isFromValid && !state.isSaveInProgress;
    });

    const formConfig = computed(() => formConfigHandler.getFormConfig(state.activeTab)?.formConfig ?? []);

    const summaryEditButton = computed<IButtonConfig>(() => getButton(
      {
        variant: EDSButtonVariant.TRETIARY,
        text: store.getters.getLocalisationRegistry.accountActivate.summary.edit,
      },
      (value) => tabHandler.goToTabFirstElement(
        (value as { block?: { tabConfig?: IFormConfig } })?.block?.tabConfig as unknown as IFormConfig,
      ),
    ));

    const headerButtonsLeft = computed<TNavigationItem[]>(() => [
      getButtonWithIcon(
        {
          text: store.getters.getLocalisationRegistry.accountActivate.cancel,
          variant: EDSButtonVariant.TRETIARY,
        },
        () => {
          router.push('/merchant/account/profile').catch(() => null);
        },
        {
          icon: EIcon.CROSS,
          color: EIconColorName.LINKED,
        },
      ),
    ]);

    const headerButtonsRight = computed<TNavigationItem[]>(() => [
      getButton(
        {
          text: store.getters.getLocalisationRegistry.accountActivate.next,
        },
        tabHandler.nextTab,
        !isSaveButton.value,
      ),
      getButton(
        {
          text: store.getters.getLocalisationRegistry.accountActivate.save,
          disabled: !isSaveEnabled.value,
        },
        () => {
          state.isSaveInProgress = true;
          formDataHandler.saveData().then(async () => {
            const accountActivate = store.getters.getLocalisationRegistry.accountActivate;
            await store.dispatch('notificationsStore/pushNotification', {
              title: accountActivate.popup.info.title,
              text: accountActivate.popup.info.text,
              type: 'info',
              component: 'DSPopup',
            });

            await store.dispatch('authStore/refreshInfo');

            await router.push('/merchant/account/profile');

            state.isSaveInProgress = false;
          }).catch(() => {
            state.isSaveInProgress = false;

            const accountActivate = store.getters.getLocalisationRegistry.accountActivate;
            store.dispatch('notificationsStore/pushNotification', {
              title: accountActivate.popup.error.title,
              text: accountActivate.popup.error.text,
              type: ENotifyVariant.ERROR,
              component: 'DSPopup',
            });
          });
        },
        isSaveButton.value,
      ),
    ]);

    const buttons: IAccountActivatePageButtons = {
      summaryEdit: summaryEditButton,
    };

    const tabListIcons: { error: IDSIcon, success: IDSIcon } = {
      error: {
        icon: EIcon.WARN_CIRCLE,
        size: ESize.M,
        color: EIconColorName.ERROR,
      },
      success: {
        icon: EIcon.CHECK_CIRCLE,
        size: ESize.M,
        color: EIconColorName.SUCCESS,
      },
    };

    return {
      headerButtonsLeft,
      headerButtonsRight,
      buttons,
      localisationRegistry: store.getters.getLocalisationRegistry,
      state,
      tabHandler,
      tabListIcons,
      formDataHandler,
      summaryData,
      checkboxLabel,
      termsAndConditionsDescription,
      isSaveButton,
      isSaveEnabled,
      formConfig,
      filteredSelectHandler,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => (vm.$store.getters['authStore/getSalesStatusActivationAvailable']
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
      ? to : vm.$router.push(from).catch(() => null)));
  },
});

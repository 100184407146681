import defaultLayout from '@/router/defaultLayout';
import CheckoutLinkPage from '@/module/product/view/CheckoutLinkPage.vue';
import CheckoutLinkFormPage from '@/module/product/view/CheckoutLinkFormPage.vue';
import FulfillmentFormPage from '@/module/product/view/FulfillmentFormPage.vue';
import ERouteName from '@/module/common/models/router/ERouteName';

const checkoutLinkSlug = 'checkout-links';
const fulfillmentSlug = 'fulfillment';
export default () => [
  {
    path: `/${checkoutLinkSlug}`,
    name: ERouteName.CHECKOUT_LINK_LIST,
    components: {
      default: CheckoutLinkPage,
      ...defaultLayout,
    },
    meta: { title: 'pages.checkout_links.list.title' },
  },
  {
    path: `/${checkoutLinkSlug}/new`,
    name: ERouteName.CHECKOUT_LINK_FORM,
    components: {
      default: CheckoutLinkFormPage,
    },
    meta: { title: 'pages.checkout_links.form.title' },
  },
  {
    path: `/${checkoutLinkSlug}/:id`,
    name: ERouteName.CHECKOUT_LINK_FORM_EDIT,
    components: {
      default: CheckoutLinkFormPage,
    },
    meta: {
      title: 'pages.checkout_links.form.edit_title',
      editMode: true,
    },
  },
  {
    path: `/${fulfillmentSlug}/new`,
    name: ERouteName.FULFILLMENT_FORM,
    components: {
      default: FulfillmentFormPage,
    },
    meta: {
      title: 'pages.fulfillment.form.title',
    },
  },
  {
    path: `/${fulfillmentSlug}/:id`,
    name: ERouteName.FULFILLMENT_FORM_EDIT,
    components: {
      default: FulfillmentFormPage,
    },
    meta: {
      title: 'pages.fulfillment.form.title',
      editMode: true,
    },
  },
];

import VueRouter, { RouteConfig } from 'vue-router';
import merchant from '@/module/merchant/router/index';
import products from '@/module/product/router/index';
import dataExports from '@/module/data-exports/router/index';
import developers from '@/module/developers/router/index';
import branding from '@/module/branding/router/index';
import documentation from '@/module/documentation/router/index';
import dashboard from '@/module/dashboard/router/index';
import adminLogin from '@/module/admin/router/index';
import ProductFormPage from '@/module/product/view/ProductFormPage.vue';
import ProductsOverviewPage from '@/views/ProductsOverviewPage.vue';
import ProductCardPage from '@/module/product/view/ProductCardPage.vue';
import WebhooksPage from '@/views/WebhooksPage.vue';
import CreateEditWebhookPage from '@/views/CreateEditWebhookPage.vue';
import WebhookCardPage from '@/views/WebhookCardPage.vue';
import CreateEditPromotionPage from '@/views/CreateEditPromotionPage.vue';
import PromotionCardPage from '@/views/PromotionCardPage.vue';
import PromotionsOverviewPage from '@/views/PromotionsOverviewPage.vue';
import CookiePolicyPage from '@/views/CookiePolicyPage.vue';
import devTools from '@/module/dev-tools/router/index';
import defaultLayout from '@/router/defaultLayout';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import { Store } from 'vuex';
import ERouteName from '@/module/common/models/router/ERouteName';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';
import IAdminAuthorizeService from '@/module/common/service/AuthorizeService/IAdminAuthorizeService';
import IAuthorizeService from '@/module/common/service/AuthorizeService/IAuthorizeService';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import Vue, { nextTick } from 'vue';

function initRouter(vue, serviceContainer: IServiceContainer) {
  vue.use(VueRouter);

  const authService = serviceContainer.resolve<IAuthorizeService>(EServiceContainerInstances.AUTHORIZE_SERVICE);
  const adminAuthService = serviceContainer
    .resolve<IAdminAuthorizeService>(EServiceContainerInstances.ADMIN_AUTHORIZE_SERVICE);
  const utilsService = serviceContainer.utilsService;

  const routes = [
    ...dashboard(),
    ...developers(),
    {
      path: '/cookie-policy',
      name: ERouteName.COOKIE_POLICY,
      components: {
        default: CookiePolicyPage,
        ...defaultLayout,
      },
      meta: { title: 'titles.cookie_policy' },
    },
    {
      path: '/ssoAuth',
      name: ERouteName.SSO_AUTH,
    },
    ...adminLogin(),
    {
      path: '/products',
      name: ERouteName.PRODUCT_LIST,
      components: {
        default: ProductsOverviewPage,
        ...defaultLayout,
      },
      meta: { title: 'titles.products_overview' },
    },
    ...merchant(),
    ...products(),
    ...dataExports(),
    ...branding(),
    ...documentation(),
    ...devTools,
    {
      path: '/products/new',
      name: ERouteName.PRODUCT_FORM,
      components: {
        default: ProductFormPage,
      },
      meta: { title: 'titles.create_product' },
    },
    {
      path: '/products/:id',
      name: ERouteName.PRODUCT_CARD,
      components: {
        default: ProductCardPage,
        ...defaultLayout,
      },
      meta: { title: 'titles.product_card' },
    },
    {
      path: '/products/edit/:id',
      name: ERouteName.PRODUCT_FORM_EDIT,
      components: {
        default: ProductFormPage,
      },
      meta: { isEdit: true, title: 'titles.edit_product' },
    },
    {
      path: '/webhooks',
      name: ERouteName.WEBHOOK_LIST,
      components: {
        default: WebhooksPage,
        ...defaultLayout,
      },
      meta: { title: 'titles.webhooks' },
    },
    {
      path: '/webhooks/new',
      name: ERouteName.WEBHOOK_FORM,
      components: {
        default: CreateEditWebhookPage,
      },
      meta: { title: 'titles.create_webhook' },
    },
    {
      path: '/webhooks/:id',
      name: ERouteName.WEBHOOK_CARD,
      components: {
        default: WebhookCardPage,
        ...defaultLayout,
      },
      meta: { title: 'titles.webhook_card' },
    },
    {
      path: '/webhooks/edit/:id',
      name: ERouteName.WEBHOOK_FORM_EDIT,
      components: {
        default: CreateEditWebhookPage,
      },
      meta: { isEdit: true, title: 'titles.edit_webhook' },
    },
    {
      path: '/promotions',
      name: ERouteName.PROMOTION_LIST,
      components: {
        default: PromotionsOverviewPage,
        ...defaultLayout,
      },
      meta: { title: 'titles.promotions' },
    },
    {
      path: '/promotions/new',
      name: ERouteName.PROMOTION_FORM,
      components: {
        default: CreateEditPromotionPage,
      },
    },
    {
      path: '/promotions/edit/:id',
      name: ERouteName.PROMOTION_FORM_EDIT,
      components: {
        default: CreateEditPromotionPage,
      },
      meta: { isEdit: true },
    },
    {
      path: '/promotions/:id',
      name: ERouteName.PROMOTION_CARD,
      components: {
        default: PromotionCardPage,
        ...defaultLayout,
      },
    },
  ];

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes.filter((route) => utilsService.typeCheck.isSet<RouteConfig>(route)) as unknown as RouteConfig[],
    scrollBehavior() {
      const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

      // TODO: refactor scrollHostRef logic
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return store.state.scrollHostRef.$el.scrollTo({
        top: 0,
      });
    },
  });

  router.afterEach((to) => {
    const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;

    const commonLocalisation = localisationRegistry.common;

    const accountActivateLocalisation = localisationRegistry.accountActivate;
    const adminLocalisation = localisationRegistry.admin;
    const adminLoginLocalisation = localisationRegistry.adminLogin;
    const apiKeysLocalisation = localisationRegistry.apiKeys;
    const brandingLocalisation = localisationRegistry.branding;
    const checkoutLinksFormLocalisation = localisationRegistry.checkoutLinksForm;
    const checkoutLinksListLocalisation = localisationRegistry.checkoutLinksList;
    const cookiePolicyLocalisation = localisationRegistry.cookiePolicy;
    const dashboardLocalisation = localisationRegistry.dashboard;
    const dataExportsLocalisation = localisationRegistry.dataExports;
    const fulfillmentLocalisation = localisationRegistry.fulfillment;
    const loginLocalisation = localisationRegistry.login;
    const payoutsLocalisation = localisationRegistry.payouts;
    const privacyNoticeLocalisation = localisationRegistry.privacyNotice;
    const productsCardLocalisation = localisationRegistry.productsCard;
    const productsFormLocalisation = localisationRegistry.productsForm;
    const productsListLocalisation = localisationRegistry.productsList;
    const profileLocalisation = localisationRegistry.profile;
    const promotionsCardLocalisation = localisationRegistry.promotionsCard;
    const promotionsFormLocalisation = localisationRegistry.promotionsForm;
    const promotionsListLocalisation = localisationRegistry.promotionsList;
    const reportsLocalisation = localisationRegistry.reports;
    const webhooksCardLocalisation = localisationRegistry.webhooksCard;
    const webhooksFormLocalisation = localisationRegistry.webhooksForm;
    const webhooksListLocalisation = localisationRegistry.webhooksList;

    const titleMap = {
      [ERouteName.ACCOUNT_ACTIVATE]: accountActivateLocalisation.pageTitle,
      [ERouteName.ADMIN]: adminLocalisation.pageTitle,
      [ERouteName.ADMIN_LOGIN]: adminLoginLocalisation.pageTitle,
      [ERouteName.API_KEYS]: apiKeysLocalisation.pageTitle,
      [ERouteName.BRANDING]: brandingLocalisation.pageTitle,
      [ERouteName.CHECKOUT_LINK_FORM]: checkoutLinksFormLocalisation.pageTitle.create,
      [ERouteName.CHECKOUT_LINK_FORM_EDIT]: checkoutLinksFormLocalisation.pageTitle.edit,
      [ERouteName.CHECKOUT_LINK_LIST]: checkoutLinksListLocalisation.pageTitle,
      [ERouteName.COMPONENT_DEMO_DEFAULT]: 'Component Demo',
      [ERouteName.COMPONENT_DEMO_ROOT]: 'Component Demo',
      [ERouteName.COOKIE_POLICY]: cookiePolicyLocalisation.pageTitle,
      [ERouteName.DASHBOARD]: dashboardLocalisation.pageTitle,
      [ERouteName.DATA_EXPORTS]: dataExportsLocalisation.pageTitle,
      [ERouteName.DS_COLOR_SCHEME]: 'Design System Demo',
      [ERouteName.DS_DEFAULT]: 'Design System Demo',
      [ERouteName.DS_ROOT]: 'Design System Demo',
      [ERouteName.FORM_COMPONENT_DEMO]: 'Form Component Demo',
      [ERouteName.FULFILLMENT_FORM]: fulfillmentLocalisation.form.pageTitle,
      [ERouteName.FULFILLMENT_FORM_EDIT]: fulfillmentLocalisation.form.pageTitle,
      [ERouteName.LOGIN]: loginLocalisation.pageTitle,
      [ERouteName.PAYOUTS]: payoutsLocalisation.pageTitle,
      [ERouteName.PRIVACY_NOTICE]: privacyNoticeLocalisation.pageTitle,
      [ERouteName.PRODUCT_CARD]: productsCardLocalisation.pageTitle,
      [ERouteName.PRODUCT_FORM]: productsFormLocalisation.pageTitle.create,
      [ERouteName.PRODUCT_FORM_EDIT]: productsFormLocalisation.pageTitle.edit,
      [ERouteName.PRODUCT_LIST]: productsListLocalisation.pageTitle,
      [ERouteName.PROFILE]: profileLocalisation.pageTitle,
      [ERouteName.PROMOTION_CARD]: promotionsCardLocalisation.pageTitle,
      [ERouteName.PROMOTION_FORM]: promotionsFormLocalisation.pageTitle.create,
      [ERouteName.PROMOTION_FORM_EDIT]: promotionsFormLocalisation.pageTitle.edit,
      [ERouteName.PROMOTION_LIST]: promotionsListLocalisation.pageTitle,
      [ERouteName.REPORTS_OVERVIEW]: reportsLocalisation.pageTitle,
      [ERouteName.WEBHOOK_CARD]: webhooksCardLocalisation.pageTitle,
      [ERouteName.WEBHOOK_FORM]: webhooksFormLocalisation.pageTitle.create,
      [ERouteName.WEBHOOK_FORM_EDIT]: webhooksFormLocalisation.pageTitle.edit,
      [ERouteName.WEBHOOK_LIST]: webhooksListLocalisation.pageTitle,
    };

    let pageTitle = '';

    if (to.name && titleMap[to.name]) {
      pageTitle = `${titleMap[to.name]} -`;
    }

    Vue.nextTick(() => {
      document.title = `${pageTitle} ${commonLocalisation.brand.noventiq}`;
    });
  });

  router.beforeEach(async (to, from, next) => {
    if ([ERouteName.EMPTY_PAGE, ERouteName.ADMIN_LOGIN].includes((to.name ?? '') as ERouteName)) {
      await nextTick();
      return next();
    }

    const utilsService = serviceContainer
      .resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    if (to.name === ERouteName.SSO_AUTH) {
      try {
        let result = true;
        if (utilsService.typeCheck.isSet(to.query?.code)) {
          if (utilsService.typeCheck.isString(to.query.code) || utilsService.typeCheck.isNumber(to.query.code)) {
            result = await authService.auth(to.query.code);
          }
        } else if (utilsService.typeCheck.isSet(to.query?.error)) {
          result = await authService.auth(to.query.code);
        }
        return result ? next({ name: ERouteName.DASHBOARD }) : null;
      } catch (e) {
        await authService.logout();

        return null;
      }
    }

    if (to.name === ERouteName.ADMIN) {
      const isAuthToken = await adminAuthService.checkToken();
      if (!isAuthToken) {
        return next({ name: ERouteName.ADMIN_LOGIN });
      }

      await nextTick();
      return next();
    }

    const isAuthToken = await authService.checkToken();

    if (isAuthToken) {
      const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
      await store.dispatch('authStore/refreshInfo');

      await nextTick();
      return next();
    }

    return authService.goToSSO();
  });

  return router;
}

export default initRouter;

import IEmailTool from '@/module/common/service/UtilsService/contract/IEmailTool';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

class EmailTool implements IEmailTool {
  private readonly serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  getSupportEmail(isActiveMerchant: boolean): string {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    const emailLocalisation = localisationRegistry.email;

    const modeKey = isActiveMerchant ? 'activatedMerchant' : 'testMerchant';

    return emailLocalisation.noventiq.support[modeKey];
  }

  replaceSupportEmail(string: string, isActiveMerchant: boolean): string {
    if (isActiveMerchant) {
      return string;
    }

    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const emailLocalisation = localisationRegistry.email;

    const utilsService = this.serviceContainer.utilsService;

    return utilsService.string.replaceAll(
      string,
      emailLocalisation.noventiq.support.activatedMerchant,
      emailLocalisation.noventiq.support.testMerchant,
    );
  }
}

export default EmailTool;

import IPortalModeService from '@/module/common/service/PortalMode/IPortalModeService';
import IProductFormPageHandler from '@/module/common/service/PortalMode/PageHandler/IProductFormPageHandler';
import EPriceType from '@/module/product/model/EPriceType';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import { Store } from 'vuex';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ECurrency from '@/module/merchant/models/ECurrency';

class ProductFormPageHandler implements IProductFormPageHandler {
  private portalModeService: IPortalModeService;

  private serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
    portalModeService: IPortalModeService,
  ) {
    this.serviceContainer = serviceContainer;
    this.portalModeService = portalModeService;
  }

  getAllowedPricingModes = (): EPriceType[] => [
    EPriceType.ONE_PRICE,
    EPriceType.VOLUME_PRICE,
    EPriceType.ONE_PRICE_EACH_CURRENCY,
    EPriceType.VOLUME_PRICE_AND_OPTIONS,
  ];

  getPriceCurrencyList = (): ECurrency[] => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    return store.getters['authStore/getPriceCurrency'];
  };

  getSellingCurrencyList = (): ECurrency[] => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    return store.getters['authStore/getSellingCurrencies'];
  };
}

export default ProductFormPageHandler;

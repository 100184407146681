// eslint-disable-next-line max-len
import IFormDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import camelCaseToSnake from '@/module/common/utils/string/camelCaseToSnake';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import IUtils from '@/plugins/utils/IUtils';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import TFormData
  from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';
import TFromDataDraft
  from '@/module/merchant/view/AccountActivatePage/models/Draft/TFromDataDraft';

class FormDataToSaveMapper implements IFormDataMapper {
  private readonly state: IPageState;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    state: IPageState,
    serviceContainer: IServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
  }

  // eslint-disable-next-line class-methods-use-this
  mapFieldValue(
    formFieldConfigItem: IFormFieldItem<EFieldName, EFieldBlock>,
    data: TFormData | TFromDataDraft,
  ) {
    const namesMap = {
      [EFieldName.BUSINESS_TYPE]: 'type_of_business',
      [EFieldName.ADDRESS_LINE_1]: 'address_line_1',
      [EFieldName.ADDRESS_LINE_2]: 'address_line_2',
      [EFieldName.INDIVIDUAL]: 'organizational_legal_form_of_business',
    };
    const utils = this.serviceContainer.resolve<IUtils>(EServiceContainerInstances.UTILS);

    const { fieldBlock, fieldName, type } = formFieldConfigItem;

    const fieldBlockToSave = camelCaseToSnake(fieldBlock);
    const fieldNameToSave = namesMap[fieldName] ?? camelCaseToSnake(fieldName);

    let value = this.state.formData[fieldBlock]?.[fieldName];

    if (type === EFormFieldType.DATE) {
      value = utils.date.getDateStringFromDate(value, 'YYYY-MM-DD');
    } else if (
      fieldBlock === EFieldBlock.BUSINESS_STRUCTURE
      && fieldName === EFieldName.SELLING_AREA
    ) {
      value = value.id;
    } else if (
      fieldBlock === EFieldBlock.BUSINESS_STRUCTURE
      && fieldName === EFieldName.BUSINESS_TYPE
    ) {
      value = value.id;
    } else if (
      fieldBlock === EFieldBlock.BUSINESS_DETAILS
      && fieldName === EFieldName.INDIVIDUAL
    ) {
      value = value.id;
    } else if (
      fieldBlock === EFieldBlock.ACCOUNT_FOR_PAYOUTS
      && fieldName === EFieldName.CURRENCY
    ) {
      value = value.value;
    } else if (
      fieldBlock === EFieldBlock.TAX_CALCULATION
      && fieldName === EFieldName.TAX_CALCULATION
    ) {
      value = value.value;
    } else if (
      [
        EFieldBlock.BUSINESS_STRUCTURE,
        EFieldBlock.ACCOUNT_FOR_PAYOUTS,
      ].includes(fieldBlock)
      && fieldName === EFieldName.COUNTRY
    ) {
      value = value.code;
    } else {
      value = value ?? '';
    }

    if (!data[fieldBlockToSave]) {
      data[fieldBlockToSave] = {};
    }

    data[fieldBlockToSave][fieldNameToSave] = value;

    return data;
  }
}

export default FormDataToSaveMapper;

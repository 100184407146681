export default function adminModule(instance) {
  return {
    list(options = {}, token = false) {
      const parts = [];

      if (![null, undefined].includes(options.limit) && ![null, undefined].includes(options.offset)) {
        parts.push(`offset=${options.offset}`);
        parts.push(`limit=${options.limit}`);
      }

      if (options.searchString) {
        parts.push(`searchString=${options.searchString}`);
      }

      if (options.status && options.status !== 'all') {
        parts.push(`status=${options.status}`);
      }

      if (options.sortBy) {
        parts.push(`sortByCreateDate=${options.sortBy}`);
      }

      if (options.salesArea && options.salesArea) {
        parts.push(`salesArea=${options.salesArea}`);
      }

      const query = `${parts.filter((part) => !!part).join('&')}`;

      return instance.get(`admin/vendor?${query}`, { cancelToken: token });
    },
    update(id, payload) {
      return instance.patch(`admin/vendor/${id}`, payload);
    },
    adminAuthentication(id) {
      return instance.get(`admin/token/vendor/${id}`);
    },
  };
}

import getSidebarConfig from '@/constants/index';

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    isMobileMenuShown: false,
    tooltipIndexToShow: null,
    linkList: getSidebarConfig(serviceContainer),
  },
  mutations: {
    toggleMobileMenu(state, value) {
      state.isMobileMenuShown = value;
    },
    changeTooltipIndex(state, value) {
      state.tooltipIndexToShow = value;
    },
    subgroupToggle(state, index) {
      if (state.linkList[index]?.subgroup) {
        state.linkList[index].subgroup.show = !state.linkList[index].subgroup.show;
      }
    },
  },
  actions: {

  },
});

import IAdminAuthorizeService from '@/module/common/service/AuthorizeService/IAdminAuthorizeService';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import { AxiosRequestConfig } from 'axios';
import ILocalStorageService from '@/module/common/service/LocalStorage/ILocalStorageService';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ELocalStorageKeys from '@/module/common/service/LocalStorage/ELocalStorageKeys';

class AdminAuthorizeService implements IAdminAuthorizeService {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  checkToken = (): Promise<boolean> => Promise.resolve(this.isAuthorised());

  signRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const localStorageService = this.serviceContainer
      .resolve<ILocalStorageService>(EServiceContainerInstances.LOCAL_STORAGE_SERVICE);

    const authToken = localStorageService.read(ELocalStorageKeys.ADMIN_AUTH_TOKEN);

    if (!config.headers) {
      config.headers = {};
    }

    config.headers.Authorization = `Bearer ${authToken}`;

    return config;
  };

  private isAuthorised = (): boolean => {
    const localStorageService = this.serviceContainer
      .resolve<ILocalStorageService>(EServiceContainerInstances.LOCAL_STORAGE_SERVICE);

    return !!localStorageService.read(ELocalStorageKeys.ADMIN_AUTH_TOKEN);
  };
}

export default AdminAuthorizeService;

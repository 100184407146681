<template>
  <div class="adminlogin">
    <form
      class="adminLogin__wrapper"
      method="POST"
      @submit.prevent
    >
      <a
        :href="state.mainSiteLink"
        target="_blank"
      >
        <CLogoIcon />
      </a>
      <div
        v-if="mainError.message"
        class="adminLogin__errorWrapper"
      >
        <p class="adminLogin__error">
          {{ mainError.message }}
        </p>
      </div>
      <InputText
        :value="user.login.value"
        name="email"
        :label="$t('auth.login')"
        class="adminLogin__input"
        @update:value="handleLoginChange"
      />

      <InputText
        :value="user.password.value"
        type="password"
        name="password"
        :label="$t('auth.password')"
        class="adminLogin__input"
        @update:value="handlePasswordChange"
      />

      <ButtonComponent
        class="adminLogin__input"
        type="submit"
        @click="handleClick"
      >
        {{ $t('auth.sign_in') }}
      </ButtonComponent>
    </form>
  </div>
</template>

<script>
import ButtonComponent from '@/components/Button/ButtonComponent.vue';
import InputText from '@/components/InputText/InputText.vue';
import CLogoIcon from '@/module/common/components/CLogoIcon.vue';
import {
  inject,
} from 'vue';
import {
  mapActions,
} from 'vuex';

export default {
  name: 'AdminLoginPage',
  components: {
    ButtonComponent,
    InputText,
    CLogoIcon,
  },
  data() {
    return {
      user: {
        login: {
          value: '',
        },
        password: {
          value: '',
        },
      },
      mainError: {
        message: '',
        isError: false,
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'user.password.value': function (value) {
      this.mainError.isError = false;
      this.mainError.message = '';
      this.user.password.value = value;
    },
    // eslint-disable-next-line func-names
    'user.login.value': function (value) {
      this.mainError.isError = false;
      this.mainError.message = '';
      this.user.login.value = value;
    },
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const portalConfigService = serviceContainer.portalConfigService;

    const state = {
      mainSiteLink: portalConfigService.get().url.main,
    };

    return {
      state,
    };
  },
  methods: {
    ...mapActions({
      auth: 'authStore/doAdminLogin',
    }),
    handleClick() {
      if (this.user.login.value && this.user.password.value) {
        this.auth({
          login: this.user.login.value,
          password: this.user.password.value,
        })
          .then(() => {
            const store = this.$container.resolve('store');

            this.mainError.message = store.getters['authStore/getLoginError'];
            this.mainError.isError = !!store.getters['authStore/getLoginError'];
          });
      } else {
        this.mainError.message = 'You need to fill in all required fields';
      }
    },
    handleLoginChange(value) {
      this.user.login.value = value;
    },
    handlePasswordChange(value) {
      this.user.password.value = value;
    },
  },
};
</script>

<style scoped lang="scss">
.adminlogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.adminLogin__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
}

.adminLogin__input {
  margin: 15px;
}

.adminLogin__errorWrapper {
  background-color: $app-indication-red-color-text;
  width: 225px;
  display: flex;
  justify-self: center;
  padding: 10px;
  margin-top: 5px;
}

.adminLogin__error {
  color: #fff;
}
</style>

<template>
  <div class="merchant-page">
    <CFormPageLayout
      width="lg"
    >
      <CFormPageContent
        v-if="!isLoading && !isInitialRequest"
      >
        <DSHeader
          :text="$t('pages.admin.title')"
          class="merchant-page__header"
          size="1"
        />
        <div
          v-if="!isError"
          class="merchant-page__filters"
        >
          <CInput
            v-model="filters.searchString"
            :label="searchInputLabel"
            :placeholder="searchInputPlaceholder"
            class="merchant-page__filter-search"
            @input="handleSearchStringChange"
          />
          <CSelect
            v-model="filters.status"
            :label="statusLabel"
            :list="statusList"
            class="merchant-page__filter-status"
            @input="handleStatusFilterChange"
          />
          <CSelect
            v-model="filters.sortBy"
            :iconOnly="isMobile"
            :label="sortByLabel"
            :list="sortByList"
            class="merchant-page__filter-sort-by"
            @input="handleSortByFilterChange"
          />
        </div>
        <div
          v-if="isUpdating"
          class="merchant-page__preloader"
        >
          <PreloaderComponent
            height="125px"
            width="125px"
          />
        </div>
        <template
          v-else-if="!isUpdating && !isError"
        >
          <MerchantList
            v-if="merchants.length > 0"
            :merchants="merchants"
          />
          <CStatusWindowComponent
            v-if="merchants.length === 0 && filters.searchString.length > 0 && !isLoading"
            :subtitle="$t('pages.admin.not_found.title')"
            :title="$t('pages.admin.not_found.text')"
            size="m"
          />
          <div
            v-if="!isLoading && merchants.length < totalCount"
            class="merchant-page__morePreloaderWrapper"
          >
            <PreloaderComponent
              class="merchant-page__morePreloader"
              height="30px"
              width="30px"
            />
          </div>
        </template>
      </CFormPageContent>
      <PreloaderComponent
        v-if="isLoading && isInitialRequest"
        class="merchant-page__loader"
        height="125px"
        width="125px"
      />
    </CFormPageLayout>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';

import CFormPageContent from '@/module/common/components/PageParts/CFormPageContent.vue';
import CFormPageLayout from '@/module/common/components/PageParts/CFormPageLayout.vue';
import CInput from '@/module/common/components/UI/CInput.vue';
import CSelect from '@/module/common/components/UI/CSelect.vue';
import CStatusWindowComponent from '@/module/common/components/UI/CStatusWindowComponent.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import MerchantList from '@/module/admin/components/MerchantList.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';

export default {
  name: 'AdminPage',
  components: {
    CFormPageContent,
    CFormPageLayout,
    CInput,
    CSelect,
    CStatusWindowComponent,
    DSHeader,
    MerchantList,
    PreloaderComponent,
  },
  data() {
    return {
      isError: false,
      filters: {
        searchString: '',
        status: {
          title: this.$t('pages.admin.filters.status.all'),
          id: '1',
          value: 'all',
        },
        sortBy: {
          title: this.$t('pages.admin.filters.sort_by.desc'),
          id: '1',
          value: 'desc',
        },
      },
      mobileBreakpoints: [
        'xxs',
        'xs',
        'sm',
        'md',
      ],
    };
  },
  computed: {
    ...mapGetters({
      merchants: 'adminStore/getMerchants',
      totalCount: 'adminStore/getTotalCount',
      isLoading: 'adminStore/getIsLoading',
      isUpdating: 'adminStore/getIsUpdating',
      isRequestRunning: 'adminStore/getIsRequestRunning',
      isInitialRequest: 'adminStore/getIsInitialRequest',
      scrollPosition: 'adminStore/getScrollPosition',
      scrollHostRef: 'getScrollHostRef',
      currentBreakpoint: 'commonStatusesStore/getCurrentBreakpoint',
    }),
    isMobile() {
      return this.mobileBreakpoints.includes(this.currentBreakpoint);
    },
    searchInputLabel() {
      return this.isMobile ? '' : this.$t('pages.admin.filters.search.label');
    },
    searchInputPlaceholder() {
      return this.isMobile
        ? this.$t('pages.admin.filters.search.label')
        : this.$t('pages.admin.filters.search.placeholder');
    },
    statusLabel() {
      return this.isMobile ? '' : this.$t('pages.admin.filters.status.label');
    },
    sortByLabel() {
      return this.isMobile ? '' : this.$t('pages.admin.filters.sort_by.label');
    },
    statusList() {
      return [
        {
          id: '1',
          title: this.$t('pages.admin.filters.status.all'),
          value: 'all',
        },
        {
          id: '2',
          title: this.$t('pages.admin.filters.status.test'),
          value: 'test',
        },
        {
          id: '3',
          title: this.$t('pages.admin.filters.status.need_approve'),
          value: 'need_approve',
        },
        {
          id: '4',
          title: this.$t('pages.admin.filters.status.sales'),
          value: 'sales',
        },
      ];
    },
    sortByList() {
      return [
        {
          id: '1',
          title: this.$t('pages.admin.filters.sort_by.desc'),
          value: 'desc',
        },
        {
          id: '2',
          title: this.$t('pages.admin.filters.sort_by.asc'),
          value: 'asc',
        },
      ];
    },
  },
  mounted() {
    if (!this.merchants.length) {
      this.getMerchants();
    }

    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions({
      getMerchantsList: 'adminStore/getMerchantsList',
      updateFilterOptions: 'adminStore/updateFilterOptions',
    }),

    handleScroll(event) {
      if (event.detail?.ref?.ps?.reach?.y === 'end' && this.totalCount > this.merchants.length) {
        this.getMerchants();
      }
    },

    handleScrollToZero() {
      this.scrollHostRef.$el.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    // getting merchants
    async getMerchants(isOverwrite = false) {
      const { isError } = await this.getMerchantsList(isOverwrite);
      this.isError = isError;
    },

    handleIntersection() {
      if (!this.isRequestRunning && this.merchants.length < this.totalCount) {
        this.getMerchants();
      }
    },

    handleSearchStringChange() {
      if (this.filters.searchString.length >= 3 || this.$utils.data.isEmpty(this.filters.searchString)) {
        this.updateFilterOptions({
          name: 'offset',
          value: 0,
        });
        this.updateFilterOptions({
          name: 'searchString',
          value: this.filters.searchString,
        });
        this.getMerchants(true);
      }
    },
    handleStatusFilterChange() {
      this.updateFilterOptions({
        name: 'offset',
        value: 0,
      });
      this.updateFilterOptions({
        name: 'status',
        value: this.filters.status.value,
      });
      this.getMerchants(true);
    },
    handleSortByFilterChange() {
      this.updateFilterOptions({
        name: 'offset',
        value: 0,
      });
      this.updateFilterOptions({
        name: 'sortBy',
        value: this.filters.sortBy.value,
      });
      this.getMerchants(true);
    },
    handleSalesAreaChange() {
      this.updateFilterOptions({
        name: 'offset',
        value: 0,
      });
      this.updateFilterOptions({
        name: 'sortBy',
        value: this.filters.sortBy.value,
      });
      this.getMerchants(true);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.merchant-page {
  position: relative;
  min-height: 100vh;
  padding-top: 40px;

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__header {
    margin-bottom: 35px;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    gap: 16px;
  }

  &__filter-search {
    min-width: 300px;
    flex: 1 1 auto;
  }

  &__filter-status {
    flex: 0 0 300px;
  }

  &__filter-sort-by {
    flex: 0 0 300px;
  }

  &__preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 230px;
  }

  &__morePreloader {
    margin-top: 15px;
  }

  &__morePreloaderWrapper {
    display: flex;
    justify-content: center;
    height: 60px;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .merchant-page {
    &__filter-sort-by {
      flex: 0 0 48px;
    }

    &__filter-status {
      flex: 0 0 100%;
    }

    &__filter-sort-by {
      flex: 0 0 100%;
    }

    &__filters {
      flex-wrap: wrap;
    }
  }
}
</style>

<template>
  <COverviewPageLayout
    :is-loading="isLoading && isInitialRequest"
  >
    <template
      #header
    >
      <COverviewPageHeaderRow
        :page-title="$t('webhooks.title')"
        :tooltip-text="$t('webhooks.tooltip')"
        class="webhooks-page__header"
      >
        <p
          class="secondary webhooks-page__count"
        >
          {{ $tc('webhooks.webhooks_count', totalCount) }}
        </p>
        <template
          #right-side
        >
          <DSButton
            class="webhooks-page__button"
            :text="$t('global_variables.how_it_works')"
            show-text-after-breakpoint="xl"
            variant="secondary"
            @click="handleClickToDoc"
          >
            <template #icon-before>
              <DSIcon
                icon="arrowInsideCircle"
                color="linked"
              />
            </template>
          </DSButton>

          <DSButton
            class="webhooks-page__button"
            :text="createButtonText"
            show-text-after-breakpoint="xl"
            @click="$router.push('/webhooks/new').catch(() => null)"
          >
            <template #icon-before>
              <DSIcon
                icon="plus"
                color="primary-button"
              />
            </template>
          </DSButton>
        </template>
      </COverviewPageHeaderRow>
    </template>
    <template
      #content
    >
      <main
        class="webhooks-page"
      >
        <InlineAlert
          v-if="merchantModeAlert.visible"
          class="webhooks-page__test-alert"
          :variant="merchantModeAlert.variant"
          :title="merchantModeAlert.title"
          :subtitle="merchantModeAlert.subtitle"
        >
          <template
            #alert-controls
          >
            <button
              class="inline-alert__button"
              type="button"
              @click="handleClickCloseMerchantModeAlert"
            >
              <DSIcon
                icon="cross"
              />
            </button>
          </template>
        </InlineAlert>

        <CStatusWindowComponent
          v-if="!totalCount"
          :subtitle="statusWindow.subtitle"
          :title="statusWindow.title"
        >
          <template
            #buttons
          >
            <DSButton
              :text="statusWindow.buttons.create.config.text"
              @click="statusWindow.buttons.create.handler"
            >
              <template
                #icon-before
              >
                <DSIcon
                  :color="statusWindow.buttons.create.icon.color"
                  :icon="statusWindow.buttons.create.icon.icon"
                />
              </template>
            </DSButton>
            <DSButton
              :text="statusWindow.buttons.howItWorks.config.text"
              :variant="statusWindow.buttons.howItWorks.config.variant"
              @click="statusWindow.buttons.howItWorks.handler"
            >
              <template
                #icon-before
              >
                <DSIcon
                  :color="statusWindow.buttons.howItWorks.icon.color"
                  :icon="statusWindow.buttons.howItWorks.icon.icon"
                />
              </template>
            </DSButton>
          </template>
        </CStatusWindowComponent>

        <template
          v-else
        >
          <div
            v-if="isUpdating"
            class="webhooks-page__preloader"
          >
            <PreloaderComponent
              height="125px"
              width="125px"
            />
          </div>
          <template v-else-if="!isUpdating && !isError">
            <WebhooksTable
              :endpoints="endpoints"
            />
          </template>
        </template>

        <ScrollTopButton
          v-if="scrollPosition"
          class="webhooks-page__scroll-top-button"
          :handle-scroll-to-zero="handleScrollToZero"
        />
      </main>
    </template>
  </COverviewPageLayout>
</template>

<script>
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import CStatusWindowComponent from '@/module/common/components/UI/CStatusWindowComponent.vue';
import {
  getIsMerchantModeAlertVisible,
  setMerchantModeAlertStatus,
} from '@/module/common/utils/merchantModeAlert';
import {
  inject,
} from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import WebhooksTable from '@/components/WebhooksTable/WebhooksTable.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import ScrollTopButton from '@/components/ScrollTopButton.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import {
  getButtonWithIcon,
} from '@/module/common/helpers/getButton';

export default {
  name: 'WebhooksPage',
  components: {
    CStatusWindowComponent,
    COverviewPageLayout,
    InlineAlert,
    DSButton,
    COverviewPageHeaderRow,
    DSIcon,
    PreloaderComponent,
    ScrollTopButton,
    WebhooksTable,
  },
  data() {
    return {
      isError: false,
      merchantModeAlert: {
        visible: true,
        variant: 'accent',
        title: this.$t('pages.developers.webhooks.list.test_mode_alert.title'),
        subtitle: this.$t('pages.developers.webhooks.list.test_mode_alert.text'),
      },
    };
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const portalConfigService = serviceContainer.portalConfigService;

    const docsSiteUrl = portalConfigService.get().url.docs;

    const utils = serviceContainer.resolve('utils');

    const store = serviceContainer.resolve('store');
    const locale = store.getters.getLocale;

    const helpLink = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/merchant-portal/webhooks-overview`,
      locale,
    );

    return {
      helpLink,
    };
  },
  computed: {
    ...mapState({
      endpoints: (state) => state.webhooksStore.endpoints,
      totalCount: (state) => state.webhooksStore.totalCount,
      isLoading: (state) => state.webhooksStore.isLoading,
      isUpdating: (state) => state.webhooksStore.isUpdating,
      isRequestRunning: (state) => state.webhooksStore.isRequestRunning,
      isInitialRequest: (state) => state.webhooksStore.isInitialRequest,
      scrollPosition: (state) => state.webhooksStore.scrollPosition,
      responseStatus: (state) => state.webhooksStore.responseStatus,
    }),
    ...mapGetters({
      scrollHostRef: 'getScrollHostRef',
      merchantMode: 'authStore/getMerchantMode',
      locale: 'getLocale',
    }),
    statusWindow() {
      return {
        title: this.$t('popup_messages.no_endpoints'),
        subtitle: this.$t('popup_messages.create_endpoint'),
        buttons: {
          create: getButtonWithIcon(
            {
              text: this.createButtonText,
            },
            () => this.$router.push('/webhooks/new').catch(() => null),
            {
              color: 'primary-button',
              icon: 'plus',
            },
          ),
          howItWorks: getButtonWithIcon(
            {
              text: this.$t('global_variables.how_it_works'),
              variant: 'secondary',
            },
            this.handleClickToDoc,
            {
              color: 'linked',
              icon: 'arrowInsideCircle',
            },
          ),
        },
      };
    },
    createButtonText() {
      return this.$t(`pages.developers.webhooks.list.buttons.create.${this.merchantMode}`);
    },
  },
  mounted() {
    if (!this.endpoints.length) {
      this.getEndpoints();
    }

    this.merchantModeAlert.visible = getIsMerchantModeAlertVisible(
      'webhooks',
      this.merchantMode,
    );
  },
  methods: {
    ...mapActions({
      getEndpointsAction: 'webhooksStore/getEndpoints',
    }),
    handleScrollToZero() {
      this.scrollHostRef.$el.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    // getting endpoints
    async getEndpoints(isOverwrite = false) {
      const { isError } = await this.getEndpointsAction(isOverwrite);
      this.isError = isError;
    },
    handleIntersection() {
      if (!this.isRequestRunning) {
        this.getEndpoints();
      }
    },
    handleClickToDoc() {
      window.open(this.helpLink, '_blank');
    },
    handleClickCloseMerchantModeAlert() {
      this.merchantModeAlert.visible = false;
      setMerchantModeAlertStatus('webhooks', 'hidden');
    },
  },
};
</script>

<style lang="scss">
.webhooks-page {
  &__header {
    margin-bottom: 22px;
  }

  &__title {
    display: flex;
    align-items: baseline;
    line-height: 48px;
  }

  &__tooltip {
    margin-left: 10px;
    align-self: flex-end;
    margin-bottom: 7px;
  }

  &__count {
    margin-bottom: 10px;
  }

  &__test-alert {
    margin: 32px 0;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__preloader {
    text-align: center;
  }
}

@media screen and (max-width: 920px) {
  .webhooks-page {
    margin-top: 24px;
  }
}

@media screen and (max-width: 840px) {
  .webhooks-page {
    &__title {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__count {
      display: block;
      width: 100%;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 370px) {
  .webhooks-page {
    &__tooltip {
      margin-bottom: 10px;
    }
  }
}
</style>

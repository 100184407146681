import {
  AxiosError,
} from 'axios';

import ECurrency from '@/module/merchant/models/ECurrency';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';

import ICamelCaseLocalisationProductsFormPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationProductsFormPage';
import ICamelCaseLocalisationProductsOld
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationProductsOld';
import ICInfoBlock from '@/module/common/models/ICInfoBlock/ICInfoBlock';
import ICInfoBlockItem from '@/module/common/models/ICInfoBlock/ICInfoBlockItem';
import IOnePriceEachCurrencyPricingModel
  from '@/module/product/model/Product/Fields/PricingModel/IOnePriceEachCurrencyPricingModel';
import IOnePricePricingModel from '@/module/product/model/Product/Fields/PricingModel/IOnePricePricingModel';
import IProductCardPage from '@/module/product/view/ProductCardPage/contract/IProductCardPage';
import IProductResponseFrontendModel from '@/module/product/model/Api/Product/IProductResponseFrontendModel';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IState from '@/module/product/view/ProductCardPage/model/IState';
import IVolumePriceAndOptionsPricingModel
  from '@/module/product/model/Product/Fields/PricingModel/IVolumePriceAndOptionsPricingModel';
import IVolumePricePricingModel from '@/module/product/model/Product/Fields/PricingModel/IVolumePricePricingModel';
import IVueInstance from '@/module/common/models/IVueInstance';
import TPricingModel from '@/module/product/model/Product/Fields/PricingModel/TPricingModel';
import TPricingModelOption from '@/module/product/model/Product/Fields/PricingModel/Option/TPricingModelOption';
import TProduct from '@/module/product/model/Product/TProduct';

class ProductCardPage implements IProductCardPage {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly vueInstance: IVueInstance;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    vueInstance: IVueInstance,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.vueInstance = vueInstance;
  }

  private termParser = (termString) => {
    const termNames = {
      D: 'day',
      M: 'month',
      Y: 'year',
    };
    const termAmount = termString.slice(1, termString.length - 1);
    const termType = termNames[termString[termString.length - 1]];

    return [termType, termAmount];
  };

  private mapPriceRowsOnePrice = (
    variants: IOnePricePricingModel[],
    localisationOld: ICamelCaseLocalisationProductsOld,
  ): ICInfoBlockItem => {
    const numberFormatService = this.serviceContainer.numberFormatService;

    const price = variants[0].price.common.price;
    const currency = variants[0].price.common.currency;

    return {
      title: localisationOld.productCard.price,
      value: numberFormatService.money(price, currency),
    };
  };

  private mapPriceRowsOnePriceEachCurrency = (
    variants: IOnePriceEachCurrencyPricingModel[],
    localisationOld: ICamelCaseLocalisationProductsOld,
  ): ICInfoBlockItem => {
    const numberFormatService = this.serviceContainer.numberFormatService;

    const skuRow: string[] = [
      localisationOld.productSku,
      variants[0].sku.length > 0 ? variants[0].sku : '-',
    ];

    const currencyRows: string[][] = [];

    Object.values(variants[0].price).forEach((item) => {
      const price = item as { currency: ECurrency, price: string };

      currencyRows.push([
        `${localisationOld.productCard.pricePerUnit}, ${price.currency}`,
        numberFormatService.money(price.price, price.currency),
      ]);
    });

    return {
      title: localisationOld.productCard.price,
      type: EFormFieldType.TABLE,
      value: {
        fillFirstCol: true,
        rows: [
          [
            skuRow,
            ...currencyRows,
          ],
        ],
      },
    };
  };

  private mapPriceRowsVolumePrice = (
    variants: IVolumePricePricingModel[],
    localisationOld: ICamelCaseLocalisationProductsOld,
  ): ICInfoBlockItem => {
    const numberFormatService = this.serviceContainer.numberFormatService;

    const head = [localisationOld.productCard.units];
    const skuRow = [localisationOld.productSku];
    const pricesRow = [`${localisationOld.productCard.pricePerUnit}, ${variants[0].price.common.currency}`];

    (variants as IVolumePricePricingModel[]).forEach((variant) => {
      head.push(`${variant.from} - ${variant.to ?? '∞'}`);
      skuRow.push(variant.sku.length > 0 ? variant.sku : '-');

      const priceValue = variant.price?.common;

      if (priceValue) {
        pricesRow.push(numberFormatService.money(priceValue.price, priceValue.currency));
      } else {
        pricesRow.push('');
      }
    });

    return {
      title: localisationOld.productCard.price,
      type: EFormFieldType.TABLE,
      value: {
        fillFirstCol: true,
        head,
        rows: [
          [
            skuRow,
            pricesRow,
          ],
        ],
      },
    };
  };

  private mapPriceRowsVolumePriceAndOptions = (
    variants: IVolumePriceAndOptionsPricingModel[],
    localisationOld: ICamelCaseLocalisationProductsOld,
  ): ICInfoBlockItem => {
    const numberFormatService = this.serviceContainer.numberFormatService;

    const head = [localisationOld.productCard.units];
    const skuRow = [localisationOld.productSku];
    const pricesRows: Record<string, string[]> = {} as Record<string, string[]>;

    variants.forEach((variant) => {
      Object.values(variant.price).forEach((item) => {
        const price = item as { currency: ECurrency, price: string };

        if (!pricesRows[price.currency]) {
          pricesRows[price.currency] = [`${localisationOld.productCard.pricePerUnit}, ${price.currency}`];
        }
      });
    });

    (variants as IVolumePriceAndOptionsPricingModel[]).forEach((variant) => {
      head.push(`${variant.from} - ${variant.to ?? '∞'}`);
      skuRow.push(variant.sku.length > 0 ? variant.sku : '-');

      Object.keys(pricesRows).forEach((priceRowKey) => {
        const price = variant.price[priceRowKey]?.price ?? null;
        const priceString = price ? numberFormatService.money(price, priceRowKey) : '';

        pricesRows[priceRowKey].push(priceString);
      });
    });

    return {
      title: localisationOld.productCard.price,
      type: EFormFieldType.TABLE,
      value: {
        fillFirstCol: true,
        head,
        rows: [
          [
            skuRow,
            ...Object.values(pricesRows),
          ],
        ],
      },
    };
  };

  private mapPrice = (
    pricingModel: TPricingModelOption,
    variants: TPricingModel,
    localisationOld: ICamelCaseLocalisationProductsOld,
  ): ICInfoBlockItem[] => {
    const moduleProduct = this.serviceContainer.moduleProduct;
    const productService = moduleProduct.productService;
    const productTypeOptionCheckHelper = productService.productTypeOptionCheckHelper;

    const productPrice: ICInfoBlockItem[] = [];
    let priceRow: ICInfoBlockItem = {} as ICInfoBlockItem;

    // @TODO проверять не pricingModel, а variants.
    if (productTypeOptionCheckHelper.isOnePriceOption(pricingModel)) {
      if (variants[0].sku) {
        productPrice.push({
          title: localisationOld.productSku,
          value: variants[0].sku,
        });
      }

      priceRow = this.mapPriceRowsOnePrice(
        variants as IOnePricePricingModel[],
        localisationOld,
      );
    }

    // @TODO проверять не pricingModel, а variants.
    if (productTypeOptionCheckHelper.isOnePriceEachCurrencyPricingModelOption(pricingModel)) {
      priceRow = this.mapPriceRowsOnePriceEachCurrency(
        variants as IOnePriceEachCurrencyPricingModel[],
        localisationOld,
      );
    }

    // @TODO проверять не pricingModel, а variants.
    if (productTypeOptionCheckHelper.isVolumePricePricingModelOption(pricingModel)) {
      priceRow = this.mapPriceRowsVolumePrice(
        variants as IVolumePricePricingModel[],
        localisationOld,
      );
    }

    // @TODO проверять не pricingModel, а variants.
    if (productTypeOptionCheckHelper.isVolumePriceAndOptionsPricingModelOption(pricingModel)) {
      priceRow = this.mapPriceRowsVolumePriceAndOptions(
        variants as IVolumePriceAndOptionsPricingModel[],
        localisationOld,
      );
    }

    productPrice.push(
      {
        title: localisationOld.productCard.pricingModel,
        value: pricingModel.title,
        tooltip: pricingModel.tooltip,
      },
      priceRow,
    );

    return productPrice;
  };

  private mapInitialLicenseInfoBlockData = (
    product: TProduct,
    localisation: ICamelCaseLocalisationProductsFormPage,
    localisationOld: ICamelCaseLocalisationProductsOld,
  ): ICInfoBlockItem[] => {
    const initialLicenseInfoBlockData: ICInfoBlockItem[] = [];

    if (product.licenseTerm && product.licenseTerm !== '0') {
      const termPeriodToken = `product.product_card.${this.termParser(product.licenseTerm)[0]}`;

      initialLicenseInfoBlockData.push({
        title: localisationOld.productCard.term,
        value: this.vueInstance.$tc(termPeriodToken, this.termParser(product.licenseTerm)[1]),
      });
    }

    const pricingModel = product.pricingModel;
    const price = product.price;

    const productPrice = this.mapPrice(
      pricingModel,
      price,
      localisationOld,
    );

    initialLicenseInfoBlockData.push(
      {
        title: localisationOld.productCard.title,
        value: product.title,
      },
      {
        title: localisationOld.productCard.subtitle,
        value: product.subtitle,
      },
      {
        title: localisationOld.productCard.other,
        value: `${localisationOld.productCard.availableFor.label}: ${product.availableFor.title}`,
      },
      ...productPrice,
    );

    return initialLicenseInfoBlockData;
  };

  private mapTrialPeriodInfoBlockData = (
    product: TProduct,
    localisationOld: ICamelCaseLocalisationProductsOld,
  ): ICInfoBlockItem[] => {
    const termTrialPeriodToken = `product.product_card.${this.termParser(product.trialTerm)[0]}`;

    const trialInfoBlockData: ICInfoBlockItem[] = [
      {
        title: localisationOld.productCard.term,
        value: this.vueInstance.$tc(termTrialPeriodToken, this.termParser(product.trialTerm)[1]),
      },
      {
        title: localisationOld.productCard.title,
        value: product.trialTitle,
      },
      {
        title: localisationOld.productCard.subtitle,
        value: product.trialSubtitle,
      },
    ];

    if (product.trialSku) {
      trialInfoBlockData.push({
        title: localisationOld.productSku,
        value: product.trialSku,
      });
    }

    return trialInfoBlockData;
  };

  private mapRenewalInfoBlockData = (
    product: TProduct,
    localisationOld: ICamelCaseLocalisationProductsOld,
  ): ICInfoBlockItem[] => {
    const renewalTermPeriodToken = `product.product_card.${this.termParser(product.renewalTerm)[0]}`;

    const pricingModel = product.pricingModel;
    const price = product.renewalPrice;

    if (price === null) {
      throw new Error('Renewal price is null');
    }

    const renewalPrice = this.mapPrice(
      pricingModel,
      price,
      localisationOld,
    );

    return [
      {
        title: localisationOld.productCard.term,
        value: this.vueInstance.$tc(renewalTermPeriodToken, this.termParser(product.renewalTerm)[1]),
      },
      {
        title: localisationOld.productCard.title,
        value: product.renewalTitle,
      },
      {
        title: localisationOld.productCard.subtitle,
        value: product.renewalSubtitle,
      },
      ...renewalPrice,
    ];
  };

  loadProduct = async () => {
    const apiService = this.serviceContainer.apiService;
    const apiProduct = apiService.product;

    try {
      const response = await apiProduct.get(this.state.id);

      const productResponseData: IProductResponseFrontendModel = response.data;

      this.state.buyLink = productResponseData.buyLink;
      this.state.product = productResponseData.product;
    } catch (e) {
      const error = e as AxiosError;

      this.state.isError = true;
      this.state.responseStatus = error.response?.status ?? 200;
    } finally {
      this.state.isLoading = false;
    }
  };

  mapDataForInfoBlocks = (): ICInfoBlock[] => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const localisation = localisationRegistry.productsForm;
    const localisationOld = localisationRegistry.productsOld;

    const product = this.state.product;

    const infoBlockList: ICInfoBlock[] = [];

    infoBlockList.push({
      title: localisationOld.productCard.initialLicense,
      data: this.mapInitialLicenseInfoBlockData(
        product,
        localisation,
        localisationOld,
      ),
    });

    if (product.trialTitle) {
      infoBlockList.push({
        title: localisationOld.productCard.trialPeriod,
        data: this.mapTrialPeriodInfoBlockData(
          product,
          localisationOld,
        ),
      });
    }

    if (product.renewalTitle) {
      infoBlockList.push({
        title: localisationOld.productCard.renewal,
        data: this.mapRenewalInfoBlockData(
          product,
          localisationOld,
        ),
      });
    }

    return infoBlockList;
  };
}

export default ProductCardPage;

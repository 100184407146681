enum EFieldName {
  DOCUMENT_PASSPORT = 'documentPassport',
  DOCUMENT_SNILS = 'documentSnils',
  DOCUMENT_COMPANY = 'documentCompany',
  DOCUMENT_INDIVIDUAL_ENTREPRENEUR = 'documentIndividualEntrepreneur',
  COUNTRY = 'country',
  BUSINESS_TYPE = 'businessType',
  TYPE_OF_BUSINESS = 'typeOfBusiness',
  LEGAL_ADDRESS = 'legalAddress',
  ACTUAL_ADDRESS = 'actualAddress',
  TAX_IDENTIFICATION_CODE = 'taxIdentificationCode',
  KPP = 'kpp',
  OGRN = 'ogrn',
  TAXATION_SYSTEM = 'taxationSystem',
  PASSPORT = 'passport',
  PASSPORT_DATE = 'passportDate',
  PASSPORT_CODE = 'passportCode',
  SNILS = 'snils',
  DOCUMENT_OGRNIP = 'documentOgrnip',
  LEGAL_BUSINESS_NAME = 'legalBusinessName',
  DOING_BUSINESS_AS = 'doingBusinessAs',
  BUSINESS_WEBSITE = 'businessWebsite',
  PRODUCT_TAX_CATEGORY = 'productTaxCategory',
  PRODUCT_DESCRIPTION = 'productDescription',
  LAST_NAME = 'lastName',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  REPRESENTATIVE = 'representative',
  JOB_TITLE = 'jobTitle',
  DOCUMENT = 'document',
  DATE_OF_BIRTH = 'dateOfBirth',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  CURRENCY = 'currency',
  BIK = 'bik',
  BANK_NAME = 'bankName',
  KS = 'ks',
  RS = 'rs',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  TOWN_OR_CITY = 'townOrCity',
  POSTAL_CODE = 'postalCode',
  IDENTIFICATION_NUMBER = 'identificationNumber',
  INDIVIDUAL = 'individual',
  IBAN = 'iban',
  IBAN_CONFIRM = 'ibanConfirm',
  VAT_NUMBER = 'vatNumber',
  SWIFT = 'swift',
  TAX_CALCULATION = 'taxCalculation',
  SELLING_AREA = 'sellingArea',
}

export default EFieldName;

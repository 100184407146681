import IFormDataToModelMapper
  from '@/module/product/view/ProductFormPage/mapper/contract/IFormDataToModelMapper';
import TFormData from '@/module/product/model/FormData/TFormData';
import TProduct from '@/module/product/model/Product/TProduct';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import IOnePriceFormData from '@/module/product/model/FormData/IOnePriceFormData';
import IVolumePriceFormData from '@/module/product/model/FormData/IVolumePriceFormData';
import IVolumePriceAndOptionsFormData from '@/module/product/model/FormData/IVolumePriceAndOptionsFormData';
import IOnePriceForEachCurrencyFormData from '@/module/product/model/FormData/IOnePriceForEachCurrencyFormData';
import IOnePriceProduct from '@/module/product/model/Product/IOnePriceProduct';
import IVolumePriceProduct from '@/module/product/model/Product/IVolumePriceProduct';
import IVolumePriceAndOptionsProduct
  from '@/module/product/model/Product/IVolumePriceAndOptionsProduct';
import IOnePriceForEachCurrencyProduct
  from '@/module/product/model/Product/IOnePriceForEachCurrencyProduct';
import IOnePricePricingModelOption
  from '@/module/product/model/Product/Fields/PricingModel/Option/IOnePricePricingModelOption';
import IVolumePricePricingModelOption
  from '@/module/product/model/Product/Fields/PricingModel/Option/IVolumePricePricingModelOption';
import IVolumePricePricingModel from '@/module/product/model/Product/Fields/PricingModel/IVolumePricePricingModel';
import IVolumePriceAndOptionsPricingModel
  from '@/module/product/model/Product/Fields/PricingModel/IVolumePriceAndOptionsPricingModel';
import IVolumePriceAndOptionsPricingModelOption
  from '@/module/product/model/Product/Fields/PricingModel/Option/IVolumePriceAndOptionsPricingModelOption';
import IOnePriceEachCurrencyPricingModel
  from '@/module/product/model/Product/Fields/PricingModel/IOnePriceEachCurrencyPricingModel';
import IOnePriceEachCurrencyPricingModelOption
  from '@/module/product/model/Product/Fields/PricingModel/Option/IOnePriceEachCurrencyPricingModelOption';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

class FormDataToModelMapper implements IFormDataToModelMapper {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  map = (formData: TFormData): TProduct => {
    if (this.isOnePrice(formData)) {
      return this.mapOnePrice(formData);
    }

    if (this.isVolumePrice(formData)) {
      return this.mapVolumePrice(formData);
    }

    if (this.isVolumePricingAndOptions(formData)) {
      return this.mapVolumePriceAndOptions(formData);
    }

    if (this.isOnePriceForEachCurrency(formData)) {
      return this.mapOnePriceForEachCurrency(formData);
    }

    throw new Error('Unsupported price type');
  };

  private mapOnePrice = (formData: IOnePriceFormData): IOnePriceProduct => {
    const licenseTermService = this.serviceContainer.moduleProduct.termService;

    const product: IOnePriceProduct = {
      availableFor: formData.availableFor,
      description: formData.description,
      fulfillmentId: formData.fulfillmentId,
      id: formData.id,
      imageUrl: formData.imageUrl,
      licenseTerm: formData.enableLicenseTerm ? licenseTermService.get(formData.licenseTerm) : '0',
      licenseType: formData.licenseType,
      price: [
        {
          sku: formData.sku,
          price: {
            common: {
              currency: formData.price.selectValue.id,
              price: this.mapPriceValue(formData.price.inputValue),
            },
          },
          from: null,
          to: null,
        },
      ],
      pricingModel: formData.pricingModel as IOnePricePricingModelOption,
      renewal: formData.renewal,
      renewalPrice: formData.renewal ? [
        {
          sku: formData.renewalSku as string,
          price: {
            common: {
              currency: formData.renewalPrice.selectValue.id,
              price: this.mapPriceValue(formData.renewalPrice.inputValue),
            },
          },
          from: null,
          to: null,
        },
      ] : null,
      renewalSubtitle: formData.renewalSubtitle,
      renewalTerm: formData.renewalTerm ? licenseTermService.get(formData.renewalTerm) : '0',
      renewalTitle: formData.renewalTitle,
      subtitle: formData.subtitle,
      title: formData.title,
      trial: formData.trial,
      trialSku: formData.trialSku,
      trialSubtitle: formData.trialSubtitle,
      trialTerm: formData.trialTerm ? licenseTermService.get(formData.trialTerm) : '0',
      trialTitle: formData.trialTitle,
    };

    if (formData.softwareRegistry) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      product.softwareRegistry = formData.softwareRegistry;
    }

    return product;
  };

  private mapVolumePrice = (
    formData: IVolumePriceFormData,
  ): IVolumePriceProduct => {
    const licenseTermService = this.serviceContainer.moduleProduct.termService;

    const product: IVolumePriceProduct = {
      availableFor: formData.availableFor,
      description: formData.description,
      fulfillmentId: formData.fulfillmentId,
      id: formData.id,
      imageUrl: formData.imageUrl,
      licenseTerm: formData.enableLicenseTerm ? licenseTermService.get(formData.licenseTerm) : '0',
      licenseType: formData.licenseType,
      price: formData.price.value.columns.map((column) => ({
        sku: column.sku.value,
        from: +column.from.value,
        to: +column.to.value,
        price: {
          common: {
            price: this.mapPriceValue(column.price.value),
            currency: formData.price.value.currency.id,
          },
        },
      } as unknown as IVolumePricePricingModel)),
      pricingModel: formData.pricingModel as IVolumePricePricingModelOption,
      renewal: formData.renewal,
      renewalPrice: formData.renewal ? formData.renewalPrice.value.columns.map((column) => ({
        sku: column.sku.value,
        from: +column.from.value,
        to: +column.to.value,
        price: {
          common: {
            price: this.mapPriceValue(column.price.value),
            currency: formData.price.value.currency.id,
          },
        },
      } as unknown as IVolumePricePricingModel)) : null,
      renewalSubtitle: formData.renewalSubtitle,
      renewalTerm: formData.renewalTerm ? licenseTermService.get(formData.renewalTerm) : '0',
      renewalTitle: formData.renewalTitle,
      subtitle: formData.subtitle,
      title: formData.title,
      trial: formData.trial,
      trialSku: formData.trialSku,
      trialSubtitle: formData.trialSubtitle,
      trialTerm: formData.trialTerm ? licenseTermService.get(formData.trialTerm) : '0',
      trialTitle: formData.trialTitle,
    };

    if (formData.softwareRegistry) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      product.softwareRegistry = formData.softwareRegistry;
    }

    return product;
  };

  private mapVolumePriceAndOptions = (
    formData: IVolumePriceAndOptionsFormData,
  ): IVolumePriceAndOptionsProduct => {
    const licenseTermService = this.serviceContainer.moduleProduct.termService;

    const price: IVolumePriceAndOptionsPricingModel[] = formData.price.value.map((column) => {
      const result = {
        sku: column.sku.value,
        from: +column.from.value,
        to: +column.to.value,
        price: {},
      };

      Object.keys(column).forEach((key) => {
        const keys = ['from', 'to', 'sku'];

        if (!keys.includes(key) && column[key].value) {
          result.price[key] = {
            price: this.mapPriceValue(column[key].value),
            currency: key,
          };
        }
      });

      return result as IVolumePriceAndOptionsPricingModel;
    });

    let renewalPrice: IVolumePriceAndOptionsPricingModel[] | null = null;
    if (formData.renewal && formData.renewalPrice) {
      renewalPrice = formData.renewalPrice.value.map((column) => {
        const result = {
          sku: column.sku.value,
          from: +column.from.value,
          to: +column.to.value,
          price: {},
        };

        Object.keys(column).forEach((key) => {
          const keys = ['from', 'to', 'sku'];

          if (!keys.includes(key) && column[key].value) {
            result.price[key] = {
              price: this.mapPriceValue(column[key].value),
              currency: key,
            };
          }
        });

        return result as IVolumePriceAndOptionsPricingModel;
      });
    }

    const product: IVolumePriceAndOptionsProduct = {
      availableFor: formData.availableFor,
      description: formData.description,
      fulfillmentId: formData.fulfillmentId,
      id: formData.id,
      imageUrl: formData.imageUrl,
      licenseTerm: formData.enableLicenseTerm ? licenseTermService.get(formData.licenseTerm) : '0',
      licenseType: formData.licenseType,
      price,
      pricingModel: formData.pricingModel as IVolumePriceAndOptionsPricingModelOption,
      renewal: formData.renewal,
      renewalPrice,
      renewalSubtitle: formData.renewalSubtitle,
      renewalTerm: formData.renewalTerm ? licenseTermService.get(formData.renewalTerm) : '0',
      renewalTitle: formData.renewalTitle,
      subtitle: formData.subtitle,
      title: formData.title,
      trial: formData.trial,
      trialSku: formData.trialSku,
      trialSubtitle: formData.trialSubtitle,
      trialTerm: formData.trialTerm ? licenseTermService.get(formData.trialTerm) : '0',
      trialTitle: formData.trialTitle,
    };

    if (formData.softwareRegistry) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      product.softwareRegistry = formData.softwareRegistry;
    }

    return product;
  };

  private mapOnePriceForEachCurrency = (
    formData: IOnePriceForEachCurrencyFormData,
  ): IOnePriceForEachCurrencyProduct => {
    const licenseTermService = this.serviceContainer.moduleProduct.termService;

    const priceItem: IOnePriceEachCurrencyPricingModel = {
      sku: formData.price.value.sku.value,
      from: null,
      to: null,
      price: {},
    } as IOnePriceEachCurrencyPricingModel;
    Object.keys(formData.price.value).forEach((key) => {
      const keys = ['from', 'to', 'sku'];

      if (!keys.includes(key) && formData.price.value[key].value) {
        priceItem.price[key] = {
          price: this.mapPriceValue(formData.price.value[key].value),
          currency: key,
        };
      }
    });

    let renewalPriceItem: IOnePriceEachCurrencyPricingModel | null = null;
    if (formData.renewal && formData.renewalPrice) {
      const priceItem = {
        sku: formData.renewalPrice.value.sku.value,
        from: null,
        to: null,
        price: {},
      } as IOnePriceEachCurrencyPricingModel;

      Object.keys(formData.renewalPrice.value).forEach((key) => {
        const keys = ['from', 'to', 'sku'];

        if (!keys.includes(key) && formData.renewalPrice.value[key].value) {
          priceItem.price[key] = {
            price: this.mapPriceValue(formData.renewalPrice.value[key].value),
            currency: key,
          };
        }
      });

      renewalPriceItem = priceItem;
    }

    const product: IOnePriceForEachCurrencyProduct = {
      availableFor: formData.availableFor,
      description: formData.description,
      fulfillmentId: formData.fulfillmentId,
      id: formData.id,
      imageUrl: formData.imageUrl,
      licenseTerm: formData.enableLicenseTerm ? licenseTermService.get(formData.licenseTerm) : '0',
      licenseType: formData.licenseType,
      price: [priceItem],
      pricingModel: formData.pricingModel as IOnePriceEachCurrencyPricingModelOption,
      renewal: formData.renewal,
      renewalPrice: renewalPriceItem ? [renewalPriceItem] : null,
      renewalSubtitle: formData.renewalSubtitle,
      renewalTerm: formData.renewalTerm ? licenseTermService.get(formData.renewalTerm) : '0',
      renewalTitle: formData.renewalTitle,
      subtitle: formData.subtitle,
      title: formData.title,
      trial: formData.trial,
      trialSku: formData.trialSku,
      trialSubtitle: formData.trialSubtitle,
      trialTerm: formData.trialTerm ? licenseTermService.get(formData.trialTerm) : '0',
      trialTitle: formData.trialTitle,
    };

    if (formData.softwareRegistry) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      product.softwareRegistry = formData.softwareRegistry;
    }

    return product;
  };

  private isOnePrice(formData: TFormData): formData is IOnePriceFormData {
    return formData.pricingModel.id === EPricingModelName.ONE_PRICE;
  }

  private isVolumePrice(formData: TFormData): formData is IVolumePriceFormData {
    return formData.pricingModel.id === EPricingModelName.VOLUME_PRICING;
  }

  private isVolumePricingAndOptions(
    formData: TFormData,
  ): formData is IVolumePriceAndOptionsFormData {
    return formData.pricingModel.id === EPricingModelName.VOLUME_PRICING_AND_OPTIONS;
  }

  private isOnePriceForEachCurrency(formData: TFormData): formData is IOnePriceForEachCurrencyFormData {
    return formData.pricingModel.id === EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY;
  }

  private mapPriceValue(
    value: string,
  ): string {
    const numberFormatService = this.serviceContainer.numberFormatService;
    const utilsService = this.serviceContainer.utilsService;
    const delimiter = numberFormatService.getMoneyDelimiter();

    // TODO: find a more concise solution
    const localValue = utilsService.string.replaceLast(value, delimiter, '.');
    const parts = localValue.split('.');
    let decimal = parts[1];
    if (decimal && decimal.length === 1) {
      decimal = `${decimal}0`;
    }

    return decimal ? `${parts[0]}.${decimal}` : localValue;
  }
}

export default FormDataToModelMapper;

<template>
  <COverviewPageLayout
    :is-loading="isLoading && isInitialRequest"
  >
    <template
      #header
    >
      <COverviewPageHeaderRow
        :page-title="$t('product.products_title')"
        :tooltip-text="$t('product.tooltip')"
        class="product-page__header"
      >
        <p
          class="secondary product-page__count"
        >
          {{ $tc('product.products_count', totalCount) }}
        </p>
        <template
          #right-side
        >
          <DSButton
            class="product-page__create-button"
            :text="createButtonText"
            show-text-after-breakpoint="xl"
            @click="$router.push('/products/new').catch(() => null)"
          >
            <template #icon-before>
              <DSIcon
                icon="plus"
                color="primary-button"
              />
            </template>
          </DSButton>
        </template>
      </COverviewPageHeaderRow>
    </template>
    <template
      #content
    >
      <main
        class="product-page"
      >
        <InlineAlert
          v-if="merchantModeAlert.visible"
          class="product-page__test-alert"
          :variant="merchantModeAlert.variant"
          :title="merchantModeAlert.title"
          :subtitle="merchantModeAlert.subtitle"
        >
          <template
            #alert-controls
          >
            <button
              class="inline-alert__button"
              type="button"
              @click="handleClickCloseMerchantModeAlert"
            >
              <DSIcon
                icon="cross"
              />
            </button>
          </template>
        </InlineAlert>

        <CStatusWindowComponent
          v-if="!totalCount"
          :subtitle="statusWindow.subtitle"
          :title="statusWindow.title"
        >
          <template
            #buttons
          >
            <DSButton
              :text="statusWindow.buttons.create.config.text"
              @click="statusWindow.buttons.create.handler"
            >
              <template
                #icon-before
              >
                <DSIcon
                  color="primary-button"
                  icon="plus"
                />
              </template>
            </DSButton>
          </template>
        </CStatusWindowComponent>

        <template
          v-else
        >
          <CPageRow>
            <SearchComponent
              class="filter-control__search product-page__search"
              :value="filterOptions.search"
              :is-loading="isLoadingSearch"
              @update:value="handleSearchInput"
              @update:clear="handleClearSearch"
            />
            <Dropdown
              v-if="windowWidth > 768"
              class="filter-control__recently-edited product-page__dropdown"
              :checkboxed="true"
              :is-drop-to-top-blocked="true"
              :options="multipleSelected"
              :placeholder="selected"
              :model-value="selected"
              @update:modelValue="handleDropdownUpdate"
            />
            <DropdownMin
              v-else
              class="filter-control__recently-edited product-page__dropdown-min"
              :options="multipleSelected"
              :model-value="selected"
              @update:modelValue="handleDropdownUpdate"
            />
            <template
              #right-side
            >
              <ButtonGroup
                v-if="windowWidth > 840"
                class="product-page__switcher"
              >
                <ButtonComponent
                  :icon-button="true"
                  :variant="
                    listView === 'list' ? 'primary' : 'tretiary'
                  "
                  :class="listView === 'list' ? 'active' : 'inactive'"
                  @click="handleSwitchListView('list')"
                >
                  <DSIcon
                    icon="burgerMenu"
                    :color="listView === 'list' ? 'menu-active' : 'primary'"
                  />
                </ButtonComponent>

                <ButtonComponent
                  :icon-button="true"
                  :variant="
                    listView === 'table' ? 'primary' : 'tretiary'
                  "
                  @click="handleSwitchListView('table')"
                >
                  <DSIcon
                    icon="tableGrid"
                    :color="listView === 'table' ? 'menu-active' : 'primary'"
                  />
                </ButtonComponent>
              </ButtonGroup>
            </template>
          </CPageRow>

          <div
            v-if="isUpdating"
            class="product-page__preloader"
          >
            <PreloaderComponent
              height="125px"
              width="125px"
            />
          </div>
          <template v-else-if="!isUpdating && !isError">
            <p
              v-if="searchCount !== totalCount && searchCount !== 0"
              class="secondary"
            >
              {{ $tc('product.search_result', searchCount) }}
            </p>

            <DSInlineAlert
              v-if="fulfillmentAlertConfig"
              :variant="fulfillmentAlertConfig.variant"
              :text="fulfillmentAlertConfig.text"
              :title="fulfillmentAlertConfig.title"
              class="fulfillment-alert"
            >
              <template
                #icon
              >
                <DSIcon
                  :color="fulfillmentAlertConfig.icon.color"
                  :icon="fulfillmentAlertConfig.icon.icon"
                />
              </template>
              <template
                #controls
              >
                <DSLink
                  class="product-page__alert-link"
                  :title="fulfillmentAlertConfig.link.title"
                  :url="fulfillmentAlertConfig.link.url"
                  :color="fulfillmentAlertConfig.link.color"
                  :variant="fulfillmentAlertConfig.link.variant"
                  :is-router-link="true"
                />
              </template>
            </DSInlineAlert>

            <ProductList
              v-if="listView === 'list' && searchCount"
              :products="products"
            />
            <ProductTable
              v-if="listView === 'table' && searchCount"
              :products="products"
            />
            <ErrorComponent
              v-if="!searchCount"
              class="product-page__error-component"
            />

            <IntersectionObserver
              v-if="!isLoading && products.length < searchCount"
              class="product-page__morePreloaderWrapper"
              @intersecting="handleIntersection"
            >
              <PreloaderComponent
                class="product-page__morePreloader"
                height="30px"
                width="30px"
              />
            </IntersectionObserver>
          </template>
          <ErrorPage
            v-else
            :error-custom="error"
            :status="responseStatus"
          />
        </template>

        <ScrollTopButton
          v-if="scrollPosition"
          class="product-page__scroll-top-button"
          :handle-scroll-to-zero="handleScrollToZero"
        />
      </main>
    </template>
  </COverviewPageLayout>
</template>

<script>
import {
  inject,
} from 'vue';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import debounce from '@/services/debounce';
import improvedTrim from '@/helpers/improvedTrim';
import throttle from '@/services/throttle';
import {
  getIsMerchantModeAlertVisible,
  setMerchantModeAlertStatus,
} from '@/module/common/utils/merchantModeAlert';

import ButtonComponent from '@/components/Button/ButtonComponent.vue';
import ButtonGroup from '@/components/ButtonGroup/ButtonGroup.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import CPageRow from '@/module/common/components/PageParts/CPageRow.vue';
import CStatusWindowComponent from '@/module/common/components/UI/CStatusWindowComponent.vue';
import Dropdown from '@/components/Dropdown/DropdownComponent.vue';
import DropdownMin from '@/components/Dropdown/DropdownMin.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import ErrorComponent from '@/components/ErrorComponent/ErrorComponent.vue';
import ErrorPage from '@/components/ErrorPage/ErrorPage.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import ProductList from '@/components/ProductList/ProductList.vue';
import ProductTable from '@/components/ProductList/ProductTable.vue';
import ScrollTopButton from '@/components/ScrollTopButton.vue';
import SearchComponent from '@/components/Search/SearchComponent.vue';
import DSLink from '@/module/design-system/components/Text/DSLink.vue';
import DSInlineAlert from '@/module/design-system/components/UI/DSInlineAlert.vue';

export default {
  name: 'ProductsOverviewPage',
  components: {
    DSInlineAlert,
    DSLink,
    ButtonComponent,
    ButtonGroup,
    COverviewPageHeaderRow,
    COverviewPageLayout,
    CPageRow,
    CStatusWindowComponent,
    Dropdown,
    DropdownMin,
    DSButton,
    DSIcon,
    ErrorComponent,
    ErrorPage,
    InlineAlert,
    IntersectionObserver,
    PreloaderComponent,
    ProductList,
    ProductTable,
    ScrollTopButton,
    SearchComponent,
  },
  data() {
    return {
      isGetMoreProducts: false,
      multipleSelected: ['product.filter_asc', 'product.filter_desc'],
      isLoadingSearch: false,
      isNextProductsLoading: false,
      isError: false,
      windowWidth: null,
      fulfillmentList: null,
      merchantModeAlert: {
        visible: true,
        variant: 'accent',
        title: this.$t('pages.products.products.list.test_mode_alert.title'),
        subtitle: this.$t('pages.products.products.list.test_mode_alert.text'),
      },
    };
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const portalConfigService = serviceContainer.portalConfigService;

    const docsSiteUrl = portalConfigService.get().url.docs;

    const utils = serviceContainer.resolve('utils');

    const store = serviceContainer.resolve('store');

    const helpLink = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/merchant-portal/products-create`,
      store.getters.getLocale,
    );

    return {
      helpLink,
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.productListStore.products,
      totalCount: (state) => state.productListStore.totalCount,
      searchCount: (state) => state.productListStore.searchCount,
      filterOptions: (state) => state.productListStore.filterOptions,
      responseStatus: (state) => state.productListStore.responseStatus,
      listView: (state) => state.productListStore.listView,
      isLoading: (state) => state.productListStore.isLoading,
      isUpdating: (state) => state.productListStore.isUpdating,
      isRequestRunning: (state) => state.productListStore.isRequestRunning,
      isInitialRequest: (state) => state.productListStore.isInitialRequest,
      scrollPosition: (state) => state.productListStore.scrollPosition,
      // popup
      isPopUpOpened: (state) => state.popupStore.isOpened,
    }),
    ...mapGetters({
      selected: 'productListStore/selectedOrder',
      scrollHostRef: 'getScrollHostRef',
      merchantMode: 'authStore/getMerchantMode',
      statusFullfilmentSupport: 'authStore/getStatusFullfilmentSupport',
    }),
    fulfillmentAlertConfig() {
      if (!this.fulfillmentList) {
        return null;
      }

      const localisation = this.$t('pages.products.products.list.fulfillment.alert');

      const configMap = {
        connected: {
          title: localisation.connected.title,
          variant: 'primary',
          icon: {
            color: 'linked',
            icon: 'checkCircleFilled',
          },
          link: {
            title: localisation.connected.button,
            color: 'linked',
            variant: 'medium',
          },
        },
        notConnected: {
          text: localisation.not_connected.text,
          title: localisation.not_connected.title,
          variant: 'error',
          icon: {
            color: 'error',
            icon: 'exclamation',
          },
          link: {
            title: localisation.not_connected.button,
            url: '/fulfillment/new',
            color: 'error',
            variant: 'medium',
          },
        },
        messageSend: {
          title: localisation.email_sent.title,
          variant: 'warning',
          icon: {
            color: 'warning',
            icon: 'clock',
          },
          link: {
            title: localisation.email_sent.button,
            url: '/fulfillment/new',
            color: 'warning',
            variant: 'medium',
          },
        },
      };

      if (this.fulfillmentList.length > 0) {
        const config = configMap.connected;

        const id = Math.max(...this.fulfillmentList.map((item) => item.id));
        config.link.url = `/fulfillment/${id}`;

        return config;
      }

      if (this.statusFullfilmentSupport === 'send') {
        return configMap.messageSend;
      }

      return configMap.notConnected;
    },
    itemsAmount() {
      return this.products.length;
    },
    throttledGetProducts() {
      return throttle(this.getProducts, 1500);
    },
    debouncedGetProducts() {
      return debounce(async (isOverwrite = false) => {
        this.isLoadingSearch = false;
        await this.getProducts(isOverwrite);
      }, 1500);
    },
    createButtonText() {
      return this.$t(`pages.products.products.list.buttons.create.${this.merchantMode}`);
    },
    statusWindow() {
      return {
        title: this.$t('popup_messages.no_products'),
        subtitle: this.$t('popup_messages.create_to_sell'),
        buttons: {
          create: {
            config: {
              text: this.$t(`pages.products.products.list.buttons.create.${this.merchantMode}`),
            },
            handler: () => this.$router.push('/products/new').catch(() => null),
          },
        },
      };
    },
  },
  watch: {
    windowWidth(width) {
      if (width < 840) {
        this.changeFieldValue({
          fieldName: 'listView',
          value: 'list',
        });
      }
    },
  },
  async mounted() {
    if (!this.products.length) {
      this.getProducts(true);
    }

    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.resizeInit);

    this.merchantModeAlert.visible = getIsMerchantModeAlertVisible(
      'products',
      this.merchantMode,
    );

    const fulfillmentService = this.$container.resolve('moduleProduct').getFulfillmentService();
    this.fulfillmentList = await fulfillmentService.refreshFulfillmentList();
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeInit);
  },
  methods: {
    ...mapMutations({
      changeFilterOption: 'productListStore/changeFilterOption',
      changeFieldValue: 'productListStore/changeFieldValue',
    }),
    ...mapActions({
      getProductsAction: 'productListStore/getProducts',
    }),
    handleDropdownUpdate(value) {
      const newValue = value === 'product.filter_desc' ? 'desc' : 'asc';
      this.changeFilterOption({
        optionName: 'order',
        newValue,
      });
      this.getProducts(true);
    },
    handleClickToDoc() {
      window.open(this.helpLink, '_blank');
    },
    handleScroll(e) {
      this.scrollPosition = e.target.scrollTop;
      if (e.target.scrollTop > e.target.scrollHeight / 2 && !this.isRequestRunning) {
        this.isGetMoreProducts = true;
      }
    },
    handleScrollToZero() {
      this.scrollHostRef.$el.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    handleSwitchListView(value) {
      if (this.listView === value) return;
      this.changeFieldValue({
        fieldName: 'listView',
        value,
      });
    },
    handleSearchInput(newValue) {
      this.changeFilterOption({
        optionName: 'search',
        newValue,
      });

      if (improvedTrim(this.filterOptions.search).length > 2) {
        this.debouncedGetProducts(true);
        this.isLoadingSearch = true;
      }
    },
    handleClearSearch(newValue) {
      this.changeFilterOption({
        optionName: 'search',
        newValue,
      });
      this.getProducts(true);
    },
    async getProducts(isOverwrite = false) {
      const { isError } = await this.getProductsAction(isOverwrite);
      this.isError = isError;
      this.isLoadingSearch = false;
    },
    handleIntersection() {
      if (!this.isRequestRunning && this.products.length < this.searchCount) {
        this.getProducts();
      }
    },
    resizeInit(event) {
      this.windowWidth = event.target.innerWidth;
    },
    handleClickCloseMerchantModeAlert() {
      this.merchantModeAlert.visible = false;
      setMerchantModeAlertStatus('products', 'hidden');
    },
  },
};
</script>

<style
  lang="scss"
>
@import '@/module/common/components/variables.scss';

.product-page {
  &__alert-link {
    white-space: nowrap;
  }
}

.fulfillment-alert {
  margin: 24px 0 30px;
}

.filter-control {
  &__search {
    width: 491px;
  }

  &__recently-edited {
    white-space: nowrap;
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .filter-control {
    &__search {
      width: 280px;
    }

    &__recently-edited {
      max-width: 305px;
      white-space: normal;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .filter-control {
    &__search {
      width: 241px;
      flex: 1 1 241px;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .filter-control {
    &__search {
      width: 287px;
    }
  }
}

.product-page {
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__morePreloader {
    margin-top: 15px;
  }

  &__morePreloaderWrapper {
    display: flex;
    justify-content: center;
    height: 60px;
  }

  &__header {
    margin-bottom: 22px;
  }

  &__count {
    margin-bottom: 10px;
  }

  &__preloader {
    text-align: center;
  }

  &__error-component {
    height: 314px;
  }

  &__test-alert {
    margin: 0 0 32px 0;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .product-page {

    &__count {
      display: block;
      width: 100%;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .product-page {

    &__dropdown-min {
      margin-left: 10px;
    }

    &__block {
      column-gap: 8px;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .product-page {
    &__block {
      margin-left: 0;
    }
  }
}
</style>

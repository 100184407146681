import ProductImageUploadHandler from '@/module/product/view/ProductFormPage/handler/ProductImageUploadHandler';
import IProductImageUploadHandler
  from '@/module/product/view/ProductFormPage/handler/contract/IProductImageUploadHandler';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IHandlerService
  from '@/module/product/view/ProductFormPage/service/contract/IHandlerService';
import IFormDataHandler from '@/module/product/view/ProductFormPage/handler/contract/IFormDataHandler';
import FormDataHandler from '@/module/product/view/ProductFormPage/handler/FormDataHandler';
import ISaveHandler from '@/module/product/view/ProductFormPage/handler/contract/ISaveHandler';
import SaveHandler from '@/module/product/view/ProductFormPage/handler/SaveHandler';
import FormDataToModelMapper from '@/module/product/view/ProductFormPage/mapper/FormDataToModelMapper';
import ILoadHandler from '@/module/product/view/ProductFormPage/handler/contract/ILoadHandler';
import LoadHandler from '@/module/product/view/ProductFormPage/handler/LoadHandler';
import ModelToFormDataMapper from '@/module/product/view/ProductFormPage/mapper/ModelToFormDataMapper';
import IValidateHandler from '@/module/product/view/ProductFormPage/handler/contract/IValidateHandler';
import ValidateHandler from '@/module/product/view/ProductFormPage/handler/ValidateHandler';
import SimpleFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/SimpleFieldStatusHandler';
import EmptyFieldStatusHandler from '@/module/common/components/FormComponent/ValidatorHandler/EmptyFieldStatusHandler';
import IFormConfigHandler from '@/module/product/view/ProductFormPage/handler/contract/IFormConfigHandler';
import FormConfigHandler from '@/module/product/view/ProductFormPage/handler/FormConfigHandler';
import IFormBlurHandler from '@/module/product/view/ProductFormPage/handler/contract/IFormBlurHandler';
import FormBlurHandler from '@/module/product/view/ProductFormPage/handler/FormBlurHandler';

class HandlerService implements IHandlerService {
  private _productImageUploadHandler?: IProductImageUploadHandler;

  private _formDataHandler?: IFormDataHandler;

  private _saveHandler?: ISaveHandler;

  private _loadHandler?: ILoadHandler;

  private _validateHandler?: IValidateHandler;

  private _formConfigHandler?: IFormConfigHandler;

  private _formBlurHandler?: IFormBlurHandler;

  private readonly serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get productImageUploadHandler(): IProductImageUploadHandler {
    if (!this._productImageUploadHandler) {
      this._productImageUploadHandler = new ProductImageUploadHandler(this.serviceContainer);
    }

    return this._productImageUploadHandler;
  }

  get formDataHandler(): IFormDataHandler {
    if (!this._formDataHandler) {
      this._formDataHandler = new FormDataHandler(this.serviceContainer);
    }

    return this._formDataHandler;
  }

  get saveHandler(): ISaveHandler {
    if (!this._saveHandler) {
      this._saveHandler = new SaveHandler(this.serviceContainer, new FormDataToModelMapper(this.serviceContainer));
    }

    return this._saveHandler;
  }

  get loadHandler(): ILoadHandler {
    if (!this._loadHandler) {
      this._loadHandler = new LoadHandler(this.serviceContainer, new ModelToFormDataMapper(this.serviceContainer));
    }

    return this._loadHandler;
  }

  get validateHandler(): IValidateHandler {
    if (!this._validateHandler) {
      this._validateHandler = new ValidateHandler(
        this.serviceContainer,
        new SimpleFieldStatusHandler(),
        new EmptyFieldStatusHandler(),
      );
    }

    return this._validateHandler;
  }

  get formConfigHandler(): IFormConfigHandler {
    if (!this._formConfigHandler) {
      this._formConfigHandler = new FormConfigHandler(this.serviceContainer);
    }

    return this._formConfigHandler;
  }

  get formBlurHandler(): IFormBlurHandler {
    if (!this._formBlurHandler) {
      this._formBlurHandler = new FormBlurHandler(this.serviceContainer);
    }

    return this._formBlurHandler;
  }
}

export default HandlerService;
